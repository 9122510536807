// @flow
import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

import { appConfiguration } from '../AppConfiguration';
import ChatMessageStyles from './Styles/ChatMessageStyles';

const SplashScreen = () => (
    <div style={ChatMessageStyles.grayText}>
        <Row>
            <Col xs style={ChatMessageStyles.selectChatroom}>
                <span>Select a room to view and send messages</span>
            </Col>
        </Row>
        <Row>
            <Col xs style={ChatMessageStyles.whatsNewCol}>
                <h3 style={ChatMessageStyles.whatsNewHeader}>
                    {`What’s new in OmniLife Web v${appConfiguration().version}`}
                </h3>
                <h4>
                    Major Changes
                </h4>
                <h4>
                    Minor Changes
                </h4>
                <ul>
                    <li>
                        Support long case notes on Cases dashboard
                    </li>
                </ul>
            </Col>
        </Row>
    </div>
);

export default SplashScreen;
