// @flow
import type { UserProfile, MemberProfileData } from './types';

export function alphaSortUserProfiles(userProfiles: UserProfile[]): UserProfile[] {
    return [...userProfiles].sort((a, b) => {
        const lastComparison = (a.lastName ?? '').localeCompare(b.lastName ?? '');
        if (lastComparison !== 0) {
            return lastComparison;
        }
        return (a.firstName ?? '').localeCompare(b.firstName ?? '');
    });
}

export function alphaSortMemberData(memberData: MemberProfileData[]): MemberProfileData[] {
    return [...memberData].sort((a, b) => {
        const lastComparison = (a.profile.lastName ?? '').localeCompare(b.profile.lastName ?? '');
        if (lastComparison !== 0) {
            return lastComparison;
        }
        return (a.profile.firstName ?? '').localeCompare(b.profile.firstName ?? '');
    });
}
