// @flow
import React, { PureComponent } from 'react';
import Linkify from 'react-linkify';
import LoadingIndicator from 'react-loading-indicator';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import FontIcon from 'react-toolbox/lib/font_icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPhone,
    faProjectDiagram,
    faExclamationTriangle,
    faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import Highlighter from 'react-highlight-words';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';
import type {
    UserProfile,
} from 'txp-core';
import {
    selectProfileName, selectProfileEmail, selectProfilePhone, selectProfileOrganizationName, formatDateTime,
} from 'txp-core';

import MediaItem from './MediaItem';
import DownloadingMedia from './DownloadingMedia';
import OfferData from './OfferData';
import RecipientData from './RecipientData';
import CandidateData from './CandidateData';
import TabSelector from './TabSelector';
import ChatMessageStyles from './Styles/ChatMessageStyles';
import {
    loadMessageStatuses as _loadMessageStatuses,
    redactMessage as _redactMessage,
    unredactMessage as _unredactMessage,
    deleteFileMessage as _deleteFileMessage,
} from '../Redux/ChatListActions';
import {
    setMessageReply as _setMessageReply,
} from '../Redux/ChatMessageActions';
import type { LoadMessageStatuses } from '../Redux/ChatListActions';
import { selectMediaMessageApplication, selectMediaMessageImage, selectMediaMessageVideo } from '../Redux/ChatMediaActions';
import {
    resendNotification as _resendNotification,
} from '../Redux/ChatroomActions';
import {
    navigateToTask as _navigateToTask,
} from '../Redux/DonorActions';
import {
    selectDonorView as _selectDonorView,
    DONOR_TASK_VIEW,
} from '../Redux/ApplicationActions';
import Images from '../Themes/Images';
import { keys } from '../Utils/Object';
import isString from '../Utils/isString';
import type {
    AvatarMap,
    ChatroomMember,
    ChatroomMemberMap,
    ChatroomMessage,
    MediaType,
    MessageReadStatus,
    MessageId,
    ResendNotificationCache,
    ChatroomInfo,
} from '../Utils/types';
import {
    ApplicationMessageTypes,
    ImageMessageTypes,
    VideoMessageTypes,
    SystemMessageTypes,
    AskAlanMessageTypes,
    MessageTypes,
} from '../Utils/types';
import isEmojiMessage from '../Utils/emojiRegex';
import { formatFullDateTime } from '../Utils/time';
import truncate from '../Utils/text';
import MessageMenu from './MessageMenu';
import Colors from '../Themes/Colors';
import { getFieldDisplay } from '../Utils/form';
import { UI_TYPE_SWITCH } from '../Redux/Forms/FormTypes';

const linkify = require('linkifyjs');

type SeenBy = {
    memberId: number,
    name: string,
    timestamp: ?string,
    organizationName: string,
    phone: string,
    ack: ?string,
    avatar: string,
};

type Props = {
    accessToken: string,
    chatId: number,
    memberId: number,
    message: ChatroomMessage,
    isMine: boolean,
    sender: ChatroomMember,
    showSender: boolean,
    members: ChatroomMemberMap,
    memberIds: Array<number>,
    avatars: AvatarMap,
    avatar: string,
    user: UserProfile,
    messageMenu: boolean,
    maxWidth: number,
    deepSearchFilter: string,
    messageReadStatus: MessageReadStatus,
    messageSendStatus: string,
    resendNotificationCache: ResendNotificationCache,
    verifiedOrgMember: boolean,
    isLastMessage: boolean,
    isConnected: boolean,
    preLoad: boolean,
    currentChat: ChatroomInfo | null,

    loadMessageStatuses: (chatId: number, memberId: number, messageIds: Array<number>) => LoadMessageStatuses,
    redactMessage: (chatId: number, messageId: number) => *,
    unredactMessage: (chatId: number, messageId: number) => *,
    deleteFileMessage: (chatId: number, messageId: number) => *,
    setMessageReply: (chatId: number, replyMessageId: ?number) => *,
    openMessageDetails: () => *,
    closeMessageDetails: () => *,
    sendAck: (emoji: any, messageId: MessageId) => *,
    resendNotification: (chatId: number, userId: number, messageId: number) => *,
    showMemberProfile: (selectedChatroomMember: ChatroomMember) => *,
    showMessageMenu: (messageId: number) => *,
    hideMessageMenu: () => *,
    selectDonorView: (viewName: string) => *,
    navigateToTask: (taskId: number, donorId: number) => *,
    showDonorTask: () => *,
};

type State = {
    isMediaFullSize: boolean,
    isDialogOpen: boolean,

    seenBy: Array<SeenBy>,
    notSeenBy: Array<SeenBy>,
    showSeenBy: boolean, // true - show Seen by tab    false - show Not seen by tab

    showMenuButton: boolean,

    isVisible: boolean,
};

class ChatItem extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            isMediaFullSize: false,
            isDialogOpen: false,

            seenBy: [],
            notSeenBy: [],
            showSeenBy: true,

            showMenuButton: false,

            isVisible: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        const {
            messageReadStatus,
        } = this.props;

        if (prevProps.messageReadStatus !== messageReadStatus) {
            this.getMessageDetails();
        }
    }

    componentWillUnmount() {
        this.setState({
            showMenuButton: false,
        });
    }

    onHover = () => {
        const {
            message,
        } = this.props;

        const {
            showMenuButton,
        } = this.state;

        if (!showMenuButton && ((message.textContent !== null && message.textContent !== '') || message.fileName !== null)) {
            this.setState({
                showMenuButton: true,
            });
        }
    };

    onLeave = () => {
        this.setState({
            showMenuButton: false,
        });
    };

    onSendAck = (emoji: any) => {
        const {
            message,
            sendAck,
        } = this.props;

        this.onHideMessageMenu();

        sendAck(emoji.target.value, message.id);
    };

    onConfirmResendNotification = (user: SeenBy) => {
        const {
            resendNotification,
            message,
            chatId,
        } = this.props;

        const response = window.confirm(`Are you sure you want to resend the notification to ${user.name}?`);

        if (response) {
            resendNotification(chatId, user.memberId, parseInt(message.id, 10));
        }
    };

    onVisibilityChange = (isVisible: boolean) => {
        this.setState({ isVisible, });
    };

    onMessageDetailsClick = () => {
        this.onHideMessageMenu();
        this.dialogOpen();
    };

    onRedactClick = () => {
        const {
            chatId,
            message,
            redactMessage,
        } = this.props;

        this.onHideMessageMenu();

        redactMessage(chatId, parseInt(message.id, 10));
    };

    onUnredactClick = () => {
        const {
            chatId,
            message,
            unredactMessage,
        } = this.props;

        this.onHideMessageMenu();

        unredactMessage(chatId, parseInt(message.id, 10));
    };

    onDeleteFileClick = () => {
        const {
            chatId,
            message,
            deleteFileMessage,
        } = this.props;

        this.onHideMessageMenu();

        deleteFileMessage(chatId, parseInt(message.id, 10));
    };

    onReplyClick = () => {
        const {
            chatId,
            message,
            setMessageReply,
        } = this.props;

        this.onHideMessageMenu();

        setMessageReply(chatId, parseInt(message.id, 10));

        const chatInput = document.getElementById('chatInput');
        if (chatInput) {
            chatInput.focus();
        }
    };

    onHideMessageMenu = () => {
        const {
            hideMessageMenu,
        } = this.props;

        hideMessageMenu();
    };

    getMessageDetails = () => {
        const {
            messageReadStatus,
            message,
            members,
            memberIds,
            avatars,
        } = this.props;

        const seenByTemp = [];
        let notSeenByTemp = [];

        const readByIds = keys(messageReadStatus);

        if (readByIds.length > 0) {
            for (let i = 0; i < readByIds.length; i += 1) {
                if (readByIds[i] && members[`${readByIds[i]}`]) {
                    const memberData = members[`${readByIds[i]}`] || {};
                    const timestamp = formatDateTime(messageReadStatus[`${readByIds[i]}`] || message.sentTime, '||');
                    const memberId = parseInt(readByIds[i], 10);

                    seenByTemp.push({
                        memberId,
                        name: selectProfileName(memberData.profile, selectProfileEmail(memberData.profile)),
                        timestamp,
                        organizationName: selectProfileOrganizationName(memberData.profile),
                        phone: selectProfilePhone(memberData.profile, ''),
                        ack: message.ack[memberId.toString()] ? message.ack[memberId.toString()].symbol : null,
                        avatar: (avatars[memberId] || ''),
                    });
                }
            }
        }
        notSeenByTemp = memberIds.filter((memberId: number) => {
            if (memberId !== message.senderId && readByIds.indexOf(`${memberId}`) === -1) {
                const memberData = members[`${memberId}`] || {};

                if (memberData.membershipStatus === 'Present') {
                    return true;
                }
            }

            return false;
        }).map((memberId: number) => {
            const memberData = members[`${memberId}`] || {};

            return {
                memberId,
                name: selectProfileName(memberData.profile, selectProfileEmail(memberData.profile)),
                timestamp: null,
                organizationName: selectProfileOrganizationName(memberData.profile),
                phone: selectProfilePhone(memberData.profile, ''),
                ack: null,
                avatar: (avatars[memberId] || ''),
            };
        });

        this.setState({
            seenBy: seenByTemp,
            notSeenBy: notSeenByTemp,
        });
    };

    getFieldText = (field: any) => {
        const fieldLabel = (field.label.endsWith(':') || field.uiType === UI_TYPE_SWITCH) ? `${field.label} ` : `${field.label}:`;
        const fieldSection = field.sectionName;
        if (fieldSection && fieldSection !== '') {
            return (
                <span style={ChatMessageStyles.fieldText}>
                    {fieldSection}
                    {' '}
                    -
                    {' '}
                    {fieldLabel}
                </span>
            );
        }
        return <span>{fieldLabel}</span>;
    };

    handleMediaOpen = () => {
        this.setState({
            isMediaFullSize: true,
        });
    };

    firstClick = true;

    lastClick = null;

    timer = undefined;

    handleMediaClose = () => {
        this.setState({
            isMediaFullSize: false,
        });
    };

    dialogOpen = () => {
        const {
            chatId,
            memberId,
            message,
            openMessageDetails,

            loadMessageStatuses,
        } = this.props;

        if (!isString(message.id)) {
            loadMessageStatuses(chatId, memberId, [parseInt(message.id, 10)]);
        }

        this.setState({
            isDialogOpen: true,
            showSeenBy: true,
        });

        openMessageDetails();
    };

    dialogClose = () => {
        const {
            closeMessageDetails,
        } = this.props;

        this.setState({
            isDialogOpen: false,
        });

        closeMessageDetails();
    };

    seenByClick = () => {
        this.setState({
            showSeenBy: true,
        });
    };

    notSeenByClick = () => {
        this.setState({
            showSeenBy: false,
        });
    };

    showTaskDetails = (taskId: number) => {
        const {
            currentChat,
            showDonorTask,
            navigateToTask,
            selectDonorView,
        } = this.props;

        if (currentChat && currentChat.donorId) {
            navigateToTask(currentChat.donorId, taskId);
            selectDonorView(DONOR_TASK_VIEW);
            showDonorTask();
        }
    }

    // renders seenBy and notSeenBy in modal based on which 'tab' is selected
    renderSeenBy() {
        const {
            messageReadStatus,
            resendNotificationCache,
            message,
            memberId,
            isConnected,
        } = this.props;

        const {
            isDialogOpen,
            showSeenBy,
            seenBy,
            notSeenBy,
        } = this.state;

        const resentNotifications = resendNotificationCache[message.id] || [];

        if (messageReadStatus === null) {
            return (
                <div style={ChatMessageStyles.modalContentWrapper}>
                    <div style={ChatMessageStyles.loadingIndicator}>
                        <LoadingIndicator />
                    </div>
                </div>
            );
        }

        if (showSeenBy && seenBy.length === 0 && notSeenBy.length > 0) {
            return (
                <div style={ChatMessageStyles.modalContentWrapper}>
                    <span style={ChatMessageStyles.defaultMessage}>No one has seen this message.</span>
                </div>
            );
        } if (!showSeenBy && notSeenBy.length === 0 && seenBy.length > 0) {
            return (
                <div style={ChatMessageStyles.modalContentWrapper}>
                    <span style={ChatMessageStyles.defaultMessage}>Everyone has seen this message.</span>
                </div>
            );
        }

        let currentTabData = [];
        if (showSeenBy) {
            currentTabData = seenBy;
        } else {
            currentTabData = notSeenBy;
        }

        return (
            <div style={isDialogOpen ? ChatMessageStyles.modalDialogContentWrapper : ChatMessageStyles.modalContentWrapper}>
                {
                    currentTabData.map((user: SeenBy) => (
                        <div key={user.memberId} style={{ display: 'flex', alignItems: 'center', }}>
                            <div style={ChatMessageStyles.detailsProfileImageWrapper}>
                                <img
                                    style={ChatMessageStyles.detailsProfileImage}
                                    src={user.avatar !== '' ? user.avatar : Images.circledDefaultProfile}
                                    alt=""
                                />
                            </div>
                            <div style={ChatMessageStyles.chatMemberWrapper}>
                                <span style={ChatMessageStyles.chatMemberName}>{user.name}</span>
                                {
                                    user.ack
                                        ? (
                                            <span style={ChatMessageStyles.seenByAck}>{String.fromCodePoint(parseInt(user.ack, 16))}</span>
                                        ) : null
                                }
                                <div style={ChatMessageStyles.chatMemberWrapper}>
                                    <span style={ChatMessageStyles.chatMemberOrganization}>
                                        {user.organizationName}
                                    </span>
                                </div>
                            </div>
                            <span style={ChatMessageStyles.chatSeenTimestampWrapper}>
                                {
                                    user.timestamp ? user.timestamp.split('||').map((t) => (
                                        <span
                                            key={t}
                                            style={ChatMessageStyles.chatSeenTimestampText}
                                        >
                                            {t}
                                        </span>
                                    )) : null
                                }
                            </span>
                            {
                                !showSeenBy && message.senderId === memberId
                                    ? (
                                        <div>
                                            <ReactTooltip />
                                            <button
                                                style={ChatMessageStyles.resendNotificationButton}
                                                type="button"
                                                disabled={resentNotifications.includes(user.memberId) || !isConnected}
                                                onClick={() => this.onConfirmResendNotification(user)}
                                                data-tip="Resend Notification"
                                                data-delay-show="500"
                                                data-effect="solid"
                                            >
                                                <FontIcon value="send" alt="Resend Notification" />
                                            </button>
                                        </div>
                                    ) : null
                            }
                        </div>
                    ))
                }
            </div>
        );
    }

    renderMessageMenu() {
        const {
            memberId,
            message,
            messageMenu,
            isConnected,
        } = this.props;

        if (messageMenu) {
            return (
                <MessageMenu
                    memberId={memberId}
                    message={message}
                    disabled={!isConnected}
                    sendAck={this.onSendAck}
                    showMessageDetails={this.onMessageDetailsClick}
                    reply={this.onReplyClick}
                    redact={this.onRedactClick}
                    unredact={this.onUnredactClick}
                    deleteFile={this.onDeleteFileClick}
                    hideMessageMenu={this.onHideMessageMenu}
                />
            );
        }

        return null;
    }

    renderMenuButton() {
        const {
            isConnected,
            message,
            showMessageMenu,
        } = this.props;
        const { showMenuButton, } = this.state;

        return (
            <Fade when={showMenuButton} duration={250}>
                <div style={ChatMessageStyles.openMessageMenuButton}>
                    <button
                        style={ChatMessageStyles.addAckButton}
                        type="button"
                        disabled={!isConnected}
                        onClick={() => showMessageMenu(parseInt(message.id, 10))}
                    >
                        <FontIcon
                            className="messageMenu"
                            value="add"
                        />
                    </button>
                </div>
            </Fade>
        );
    }

    renderSeenByText() {
        const {
            message,
            isLastMessage,
        } = this.props;

        let seenByText = '';
        if (isLastMessage) {
            const seenByCount = message && message.seenByCount ? message.seenByCount : 0;
            if (seenByCount === 1) {
                seenByText = `Seen by ${seenByCount} person`;
            } else {
                seenByText = `Seen by ${seenByCount} people`;
            }
        }

        if (isLastMessage) {
            return (
                <span
                    style={ChatMessageStyles.seenByText}
                >
                    {seenByText}
                    <FontIcon
                        className="iconSeenByText"
                        value="check"
                        alt=""
                    />
                </span>
            );
        }

        return null;
    }

    renderDonorOffer() {
        const {
            message,
            memberId,
            isMine,
            user,
            maxWidth,
        } = this.props;

        const {
            messageType,
            dataContent,
            replyingMessageId,
            replyingSenderId,
            replyingMessageType,
            replyingDataContent,
        } = message;

        // Check if this is a reply to message or not, and set data content and style accordingly appropriately
        let style;
        let thisMessageType;
        let thisDataContent;
        if (replyingMessageId && replyingSenderId) {
            thisMessageType = replyingMessageType;
            thisDataContent = replyingDataContent;
            style = ChatMessageStyles.chatMessageWrapperReply;
        } else {
            thisMessageType = messageType;
            thisDataContent = dataContent;
            style = isMine
                ? { ...ChatMessageStyles.chatMessageWrapperTextSelf, ...{ maxWidth, }, }
                : { ...ChatMessageStyles.chatMessageWrapperText, ...{ maxWidth, }, };
        }

        if (thisMessageType === MessageTypes.askAlanDonor) {
            return (
                <span style={style}>
                    <OfferData
                        offerContent={thisDataContent}
                        memberId={memberId}
                        isMine={isMine}
                        user={user}
                        width={maxWidth}
                    />
                </span>

            );
        }
        if (thisMessageType === MessageTypes.askAlanRecipient) {
            return (
                <span style={style}>
                    <RecipientData
                        recipientContent={thisDataContent}
                        width={maxWidth}
                    />
                </span>
            );
        }
        if (thisMessageType === MessageTypes.askAlanCandidate) {
            return (
                <span style={style}>
                    <CandidateData
                        candidateContent={thisDataContent}
                        width={maxWidth}
                    />
                </span>
            );
        }
        if (thisMessageType === MessageTypes.askAlanVoid) {
            return (
                <span style={style}>
                    <div>
                        <span><i>Superseded</i></span>
                    </div>
                </span>
            );
        }

        return (
            <span style={style}>
                <div>
                    <span><i>Unable to display message from Snapshot</i></span>
                </div>
            </span>
        );
    }

    renderAck() {
        const {
            message,
            members,
        } = this.props;

        return (
            <div style={ChatMessageStyles.underMsgAck}>
                {keys(message.ack).map((key) => (
                    <span
                        key={key}
                        style={ChatMessageStyles.emoji}
                        role="img"
                        aria-label="emoji"
                        data-tip={
                            members[key.toString()]
                                ? selectProfileName(members[key.toString()].profile) : null
                        }
                        data-delay-show="100"
                        data-effect="solid"
                    >
                        {String.fromCodePoint(parseInt(message.ack[key].symbol, 16))}
                    </span>
                ))}
            </div>
        );
    }

    renderAvatar(showSender: boolean, messageDetails: boolean, renderType: string) {
        const {
            message,
            sender,
            avatar,
            verifiedOrgMember,
            showMemberProfile,
        } = this.props;

        let finalAvatar = '';
        if (sender) {
            finalAvatar = avatar || '';
        }

        const defaultImage = messageDetails ? Images.circledDefaultProfile : Images.defaultProfile;

        let avatarImage = (
            <span
                style={
                    messageDetails ? ChatMessageStyles.profileImageWrapperNoBorder : ChatMessageStyles.profileImageWrapper
                }
            >
                <img
                    style={ChatMessageStyles.profileImage}
                    src={(finalAvatar !== '') ? finalAvatar : defaultImage}
                    alt=""
                />
            </span>
        );

        if (renderType === 'donorTaskExpiration') {
            avatarImage = (
                <span style={ChatMessageStyles.profileImageWrapperNoBorder}>
                    <FontAwesomeIcon
                        color={Colors.red}
                        icon={faExclamationTriangle}
                        size="2x"
                    />
                </span>
            );
            // if this is a donorTaskExpiration, we won't add any of the other stuff
            return (
                <div style={ChatMessageStyles.senderInfoWrapper}>
                    <div // eslint-disable-line
                        style={ChatMessageStyles.avatarClickWrapper}
                        role="button"
                        onClick={() => {}}
                    >
                        {avatarImage}
                    </div>
                </div>
            );
        }

        if (renderType === 'donorTask' || renderType === 'openCloseDonor') {
            avatarImage = (
                <span style={ChatMessageStyles.profileImageWrapperNoBorder}>
                    <FontAwesomeIcon
                        color={Colors.brandPrimary}
                        icon={faProjectDiagram}
                        size="2x"
                    />
                </span>
            );
        }

        if (renderType === 'call') {
            avatarImage = (
                <span style={ChatMessageStyles.profileImageWrapperNoBorder}>
                    <FontAwesomeIcon
                        color={Colors.brandPrimary}
                        icon={faPhone}
                        size="2x"
                    />
                </span>
            );
        }

        // NOTE: We could eventually get rid of using selectProfileName to get the senderName below
        // The server essentially uses the same logic to set message.senderName currently (11/30/22)
        if (showSender) {
            return (
                <div style={ChatMessageStyles.senderInfoWrapper}>
                    <div // eslint-disable-line
                        style={ChatMessageStyles.avatarClickWrapper}
                        role="button"
                        onClick={verifiedOrgMember && sender ? () => showMemberProfile(sender) : () => {}}
                    >
                        {avatarImage}
                    </div>
                    <span style={ChatMessageStyles.senderName}>
                        {(sender) ? selectProfileName(sender.profile, selectProfileEmail(sender.profile)) : message.senderName}
                    </span>
                    <span style={ChatMessageStyles.sentTime}>
                        {formatDateTime(message.sentTime)}
                    </span>
                </div>
            );
        }

        return null;
    }

    renderMedia() {
        const {
            accessToken,
            chatId,
            message,
            preLoad,
            maxWidth,
            currentChat,
        } = this.props;

        const {
            isVisible,
            isMediaFullSize,
        } = this.state;

        const {
            messageType,
            localPath,
            replyingMessageId,
        } = message;

        const messages = currentChat ? currentChat.messages : null;
        const parentMessage = (messages && replyingMessageId) ? messages[replyingMessageId.toString(10)] : null;
        const isReplyingMessage = replyingMessageId && message.replyingSenderId;
        const thisLocalPath = (isReplyingMessage && parentMessage) ? parentMessage.localPath : localPath;
        const thisMessageType = isReplyingMessage ? message.replyingMessageType : messageType;
        const thisMediaFilePath = isReplyingMessage ? (message.replyingFilePath || null) : message.fileName;

        const isImageMessage = ImageMessageTypes.includes(thisMessageType);
        const isVideoMessage = VideoMessageTypes.includes(thisMessageType);
        const isApplicationMessage = ApplicationMessageTypes.includes(thisMessageType);

        let mediaSource;
        const mediaType: MediaType = (isImageMessage) ? 'image'
            : (isVideoMessage) ? 'video'
                : (isApplicationMessage) ? 'application' : 'unknown';

        if (thisLocalPath) {
            mediaSource = thisLocalPath;
        } else {
            if (isImageMessage) {
                mediaSource = message.status !== 'pending' && message.status !== 'failed' && thisMediaFilePath && thisMessageType
                    ? selectMediaMessageImage(chatId, { fileName: thisMediaFilePath, messageType: thisMessageType, }, accessToken)
                    : undefined;
            }
            if (isVideoMessage) {
                mediaSource = message.status !== 'pending' && message.status !== 'failed' && thisMediaFilePath && thisMessageType
                    ? selectMediaMessageVideo(chatId, { fileName: thisMediaFilePath, messageType: thisMessageType, }, accessToken)
                    : undefined;
            }
            if (isApplicationMessage) {
                mediaSource = message.status !== 'pending' && message.status !== 'failed' && thisMediaFilePath && thisMessageType
                    ? selectMediaMessageApplication(chatId, { fileName: thisMediaFilePath, messageType: thisMessageType, }, accessToken)
                    : undefined;
            }
        }

        return (
            <span>
                <DownloadingMedia
                    source={mediaSource}
                    type={mediaType}
                    display="chatItem"
                    isLocalSource={!!localPath}
                    chatId={chatId}
                    messageId={message.id}
                    textContent={message.textContent}
                    isVisible={isVisible}
                    preLoad={preLoad}
                    maxWidth={maxWidth}
                    onMediaClick={this.handleMediaOpen}
                />
                {isMediaFullSize
                    ? (
                        <MediaItem
                            source={mediaSource}
                            isLocalSource={!!localPath}
                            type={mediaType}
                            active={isMediaFullSize}
                            maxWidth={maxWidth}
                            onMediaClose={this.handleMediaClose}
                        />
                    ) : null}
            </span>
        );
    }

    renderEmojiOnly() {
        const {
            message,
            deepSearchFilter,
        } = this.props;

        return (
            <span
                style={{ fontSize: 32, }}
            >
                <Highlighter
                    highlightClassName="highlightClass"
                    searchWords={[deepSearchFilter]}
                    autoEscape
                    textToHighlight={message && message.textContent ? message.textContent : ''}
                />
            </span>
        );
    }

    renderTextMessage(shouldTruncate: boolean) {
        const {
            isMine,
            message,
            maxWidth,
            deepSearchFilter,
        } = this.props;

        const urls = message.textContent ? linkify.find(message.textContent) : [];

        for (let idx = urls.length - 1; idx >= 0; idx -= 1) {
            const thisURL = urls[idx];
            try {
                // eslint-disable-next-line no-unused-vars
                const verifyURL = new URL(thisURL.href);
            } catch (e) {
                urls.splice(idx, 1);
            }
        }

        const linkProps = (href, text, key) => (
            <a
                href={href}
                key={key}
                target="_blank"
                rel="noopener noreferrer"
                style={isMine ? { color: '#FFFFFF', } : { color: '#000000', }}
            >
                {text}
            </a>
        );

        const textContent = message && message.textContent ? message.textContent : '';
        const finalTextContent = shouldTruncate ? truncate(textContent, 300) : textContent;

        if (message && message.textContent) {
            return (
                <span
                    style={
                        isMine
                            ? { ...ChatMessageStyles.chatMessageWrapperTextSelf, ...{ maxWidth, }, }
                            : { ...ChatMessageStyles.chatMessageWrapperText, ...{ maxWidth, }, }
                    }
                >
                    {deepSearchFilter
                        ? (
                            <Highlighter
                                highlightClassName="highlightClass"
                                searchWords={[deepSearchFilter]}
                                autoEscape
                                textToHighlight={finalTextContent}
                            />
                        ) : (
                            <Linkify
                                componentDecorator={linkProps}
                            >
                                {finalTextContent}
                            </Linkify>
                        )}
                </span>
            );
        }

        return null;
    }

    renderRedactedMessage() {
        const {
            isMine,
            maxWidth,
        } = this.props;

        return (
            <span
                style={
                    isMine
                        ? { ...ChatMessageStyles.chatMessageWrapperTextSelf, ...{ maxWidth, }, }
                        : { ...ChatMessageStyles.chatMessageWrapperText, ...{ maxWidth, }, }
                }
            >
                Message Redacted
            </span>
        );
    }

    renderDonorTaskFieldCleared(field: any) {
        // ensure field has a label, could be an empty string with a colon ':'
        const hasLabel: boolean = ((field.label) && (field.label !== ':') && (field.label.length > 0));

        return (
            <div key={field.id}>
                <strong style={ChatMessageStyles.chatDonorStatusUpdateClearedLabel}>
                    {(hasLabel && (field.label.endsWith(':') || field.uiType === UI_TYPE_SWITCH)) ? `${field.label} ` : `${field.label}: `}
                </strong>
                <span style={ChatMessageStyles.chatDonorStatusUpdateFieldClearedText}>
                    Cleared
                </span>
            </div>
        );
    }

    renderReplyMessage(shouldTruncate: boolean) {
        const {
            isMine,
            message,
            members,
            maxWidth,
            deepSearchFilter,
        } = this.props;

        const urls = message.textContent ? linkify.find(message.textContent) : [];

        for (let idx = urls.length - 1; idx >= 0; idx -= 1) {
            const thisURL = urls[idx];
            try {
                // eslint-disable-next-line no-unused-vars
                const verifyURL = new URL(thisURL.href);
            } catch (e) {
                urls.splice(idx, 1);
            }
        }

        const linkProps = (href, text, key) => (
            <a
                href={href}
                key={key}
                target="_blank"
                rel="noopener noreferrer"
                style={isMine ? { color: '#FFFFFF', } : { color: '#000000', }}
            >
                {text}
            </a>
        );

        let isDonorOffer = false;
        let isDonorTask = false;
        let isMediaMessage = false;
        let isTextMessage = false;
        if (message && message.replyingDataContent && AskAlanMessageTypes.includes(message.replyingMessageType)) {
            isDonorOffer = true;
        } else if (message && message.replyingDataContent && message.replyingMessageType === MessageTypes.donorTask) {
            isDonorTask = true;
        } else if (message && (ImageMessageTypes.includes(message.replyingMessageType)
            || VideoMessageTypes.includes(message.replyingMessageType)
            || ApplicationMessageTypes.includes(message.replyingMessageType))) {
            isMediaMessage = true;
        } else {
            // Defalt to text message type if no other message type
            isTextMessage = true;
        }

        const textContent = message && message.textContent ? message.textContent : '';
        const finalTextContent = shouldTruncate ? truncate(textContent, 150) : textContent;

        const replyTextContent = shouldTruncate ? truncate(message.replyingTextContent, 150) : message.replyingTextContent;
        let replySenderName = '';
        if (message && message.replyingSenderId) {
            if (members && members[`${message.replyingSenderId}`]) {
                replySenderName = selectProfileName(members[`${message.replyingSenderId}`].profile);
            }
        }

        if (message && message.textContent) {
            return (
                <span
                    style={
                        isMine
                            ? { ...ChatMessageStyles.chatMessageWrapperTextSelf, ...{ maxWidth, }, }
                            : { ...ChatMessageStyles.chatMessageWrapperText, ...{ maxWidth, }, }
                    }
                >
                    <div style={ChatMessageStyles.replyMessageWrapper}>
                        <span style={ChatMessageStyles.replySenderName}>
                            {replySenderName}
                        </span>
                        <div>
                            <span style={ChatMessageStyles.chatMessageWrapperReply}>
                                {isDonorOffer ? this.renderDonorOffer() : null}
                                {isDonorTask ? this.renderDonorTaskMessage(false) : null}
                                {isMediaMessage ? this.renderMedia() : null}
                                {isTextMessage ? replyTextContent : null}
                            </span>
                        </div>
                    </div>
                    <div style={ChatMessageStyles.replyMargin} />
                    {deepSearchFilter
                        ? (
                            <Highlighter
                                highlightClassName="highlightClass"
                                searchWords={[deepSearchFilter]}
                                autoEscape
                                textToHighlight={finalTextContent}
                            />
                        ) : (
                            <Linkify
                                componentDecorator={linkProps}
                            >
                                {finalTextContent}
                            </Linkify>
                        )}
                </span>
            );
        }

        return null;
    }

    renderSystemMessage() {
        const {
            message,
            maxWidth,
            deepSearchFilter,
        } = this.props;

        const {
            isVisible,
        } = this.state;

        return (
            <div
                style={{ ...ChatMessageStyles.systemMessageWrapper, ...{ maxWidth, }, }}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onLeave}
            >
                <span style={ChatMessageStyles.systemMessageSentTime}>
                    {formatDateTime(message.sentTime)}
                    <br />
                </span>
                <span style={ChatMessageStyles.systemMessage}>
                    <Highlighter
                        highlightClassName="highlightClass"
                        searchWords={[deepSearchFilter]}
                        autoEscape
                        textToHighlight={message && message.textContent ? message.textContent : ''}
                    />
                </span>
                {this.renderMenuButton()}
                {this.renderMessageMenu()}
                {(isVisible) ? (
                    <ReactTooltip
                        multiline
                    />
                ) : null}
                <div>{this.renderSeenByText()}</div>
            </div>
        );
    }

    renderDonorTaskMessage(displayAsChat: boolean = true) {
        const {
            message,
            maxWidth,
        } = this.props;

        // Set the dataContent based on if this is replying to a donor task message or not
        const dataContent = (message.replyingMessageId && message.replyingSenderId) ? message.replyingDataContent : message.dataContent;

        const action = (dataContent && dataContent.action) ? dataContent.action : '';
        const description = (dataContent && dataContent.task_description) ? dataContent.task_description : '';
        const clinicalEventTime = (dataContent && dataContent.clinical_event_time)
            ? dataContent.clinical_event_time : null;

        const displayAs = (dataContent && dataContent.displayAs)
            ? dataContent.displayAs : [];
        const fields = (dataContent && dataContent.fields)
            ? dataContent.fields : [];
        const hasFields = fields && fields.length > 0;

        const actionLine = `Marked as ${action}`;
        const taskId = (dataContent && dataContent.task_id) ? dataContent.task_id : -1;
        const isReply = !!(message.replyingMessageId && message.replyingSenderId);

        const headerText = description ? `${description}: ${action}` : `Donor Task - ${action}`;
        const senderName = dataContent && dataContent.sender_name ? dataContent.sender_name : '';

        const linkProps = (href, text, key) => (
            <a
                href={href}
                key={key}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#0069FF', }}
            >
                {text}
            </a>
        );

        return hasFields ? (
            <div style={
                displayAsChat
                    ? { ...ChatMessageStyles.chatMessageWrapperText, ...{ maxWidth, }, }
                    : { ...ChatMessageStyles.chatWrapper, ...{ maxWidth, }, }
            }
            >
                <span style={ChatMessageStyles.chatDonorStatusUpdateHeaderText}>
                    {headerText}
                    {taskId && taskId !== -1 && !isReply
                        && (
                            <FontAwesomeIcon
                                color={Colors.brandPrimary}
                                icon={faPencilAlt}
                                onClick={() => this.showTaskDetails(taskId)}
                                style={{ cursor: 'pointer', marginLeft: '10px', marginBottom: '3px', }}
                                data-tip="Navigate to Task Details"
                            />
                        )}
                    <ReactTooltip />
                </span>
                <div>
                    {clinicalEventTime
                    && (
                        <span style={ChatMessageStyles.chatDonorStatusUpdateText}>
                            {clinicalEventTime}
                        </span>
                    )}
                </div>
                <div>
                    {fields.map((field) => (
                        <div key={field.id}>
                            {this.getFieldText(field)}
                            <Linkify
                                componentDecorator={linkProps}
                            >
                                <span style={ChatMessageStyles.chatDonorStatusUpdateFieldText}>
                                    {getFieldDisplay(field)}
                                </span>
                            </Linkify>
                        </div>
                    ))}
                </div>
                <div style={ChatMessageStyles.chatDonorStatusActionLine}>
                    <span>
                        {'Marked by: '}
                        {senderName}
                    </span>
                </div>
            </div>
        ) : (
            <div style={
                displayAsChat
                    ? { ...ChatMessageStyles.chatMessageWrapperText, ...{ maxWidth, }, }
                    : { ...ChatMessageStyles.chatWrapper, ...{ maxWidth, }, }
            }
            >
                <span style={ChatMessageStyles.chatDonorStatusUpdateHeaderText}>
                    {description}
                    {taskId && taskId !== -1 && !isReply
                        && (
                            <FontAwesomeIcon
                                color={Colors.brandPrimary}
                                icon={faPencilAlt}
                                onClick={() => this.showTaskDetails(taskId)}
                                style={{ cursor: 'pointer', marginLeft: '10px', marginBottom: '3px', }}
                                data-tip="Navigate to Task Details"
                            />
                        )}
                    <ReactTooltip />
                </span>
                <div>
                    {clinicalEventTime
                        && (
                            <span style={ChatMessageStyles.chatDonorStatusUpdateText}>
                                {clinicalEventTime}
                            </span>
                        )}
                </div>
                <div>
                    {displayAs.map((name, index) => (
                        // use name.toString() as a defensive measure. If name is an object this will crash otherwise
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                            <span style={ChatMessageStyles.chatDonorStatusUpdateText}>{name.toString()}</span>
                        </div>
                    ))}
                </div>
                <div>
                    <span style={ChatMessageStyles.chatDonorStatusUpdateText}>
                        {actionLine}
                    </span>
                </div>
            </div>
        );
    }

    renderDonorTaskCompleteIncompleteMessage() {
        const {
            message,
            maxWidth,
            deepSearchFilter,
        } = this.props;

        const {
            isVisible,
            showMenuButton,
        } = this.state;

        // Set the dataContent based on if this is replying to a donor task message or not
        const dataContent = (message.replyingMessageId && message.replyingSenderId) ? message.replyingDataContent : message.dataContent;

        const taskId = (dataContent && dataContent.task_id) ? dataContent.task_id : -1;
        const isReply = !!(message.replyingMessageId && message.replyingSenderId);

        return (
            <div
                style={{ ...ChatMessageStyles.systemMessageWrapper, ...{ maxWidth, }, paddingTop: '5px', }}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onLeave}
            >
                <div style={{ ...ChatMessageStyles.systemMessageSentTime, display: 'flex', }}>
                    {formatDateTime(message.sentTime)}
                    <Fade when={showMenuButton} duration={250}>
                        {taskId && taskId !== -1 && !isReply
                            && (
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onClick={() => this.showTaskDetails(taskId)}
                                    style={{ cursor: 'pointer', marginLeft: '10px', }}
                                    data-tip="Navigate to Task Details"
                                />
                            )}
                        <ReactTooltip />
                    </Fade>
                </div>
                <span style={ChatMessageStyles.systemMessage}>
                    <Highlighter
                        highlightClassName="highlightClass"
                        searchWords={[deepSearchFilter]}
                        autoEscape
                        textToHighlight={message && message.textContent ? message.textContent : ''}
                    />
                </span>
                {this.renderMenuButton()}
                {this.renderMessageMenu()}
                {(isVisible) ? (
                    <ReactTooltip
                        multiline
                    />
                ) : null}
                <div>{this.renderSeenByText()}</div>
            </div>
        );
    }

    renderDonorTaskClearedMessage() {
        const {
            message,
            maxWidth,
            deepSearchFilter,
        } = this.props;

        const {
            isVisible,
            showMenuButton,
        } = this.state;

        // Set the dataContent based on if this is replying to a donor task message or not
        const dataContent = (message.replyingMessageId && message.replyingSenderId) ? message.replyingDataContent : message.dataContent;

        const taskId = (dataContent && dataContent.task_id) ? dataContent.task_id : -1;
        const isReply = !!(message.replyingMessageId && message.replyingSenderId);

        return (
            <div
                style={{ ...ChatMessageStyles.systemMessageWrapper, ...{ maxWidth, }, paddingTop: '5px', }}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onLeave}
            >
                <div style={{ ...ChatMessageStyles.systemMessageSentTime, display: 'flex', }}>
                    {formatDateTime(message.sentTime)}
                    <Fade when={showMenuButton} duration={250}>
                        {taskId && taskId !== -1 && !isReply
                            && (
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    onClick={() => this.showTaskDetails(taskId)}
                                    style={{ cursor: 'pointer', marginLeft: '10px', }}
                                    data-tip="Navigate to Task Details"
                                />
                            )}
                        <ReactTooltip />
                    </Fade>
                </div>
                <span style={ChatMessageStyles.systemMessage}>
                    <Highlighter
                        highlightClassName="highlightClass"
                        searchWords={[deepSearchFilter]}
                        autoEscape
                        textToHighlight={message && message.textContent ? message.textContent : ''}
                    />
                </span>
                {this.renderMenuButton()}
                {this.renderMessageMenu()}
                {(isVisible) ? (
                    <ReactTooltip
                        multiline
                    />
                ) : null}
                <div>{this.renderSeenByText()}</div>
            </div>
        );
    }

    renderDonorStatusMessage() {
        const {
            message,
            maxWidth,
        } = this.props;

        const closed = (message.dataContent && message.dataContent.closed) ? message.dataContent.closed : '';

        const actionLine = `Marked case as ${closed}`;
        return (
            <span style={{ ...ChatMessageStyles.chatWrapper, ...{ maxWidth, }, }}>
                <span style={ChatMessageStyles.chatDonorStatusUpdateHeaderText}>
                    {actionLine}
                </span>
            </span>
        );
    }

    renderCallMessage() {
        const {
            message,
            maxWidth,
        } = this.props;

        if (!message || !message.textContent) {
            return null;
        }

        return (
            <div style={{ ...ChatMessageStyles.chatWrapper, ...{ maxWidth, }, }}>
                <span style={ChatMessageStyles.chatDonorStatusUpdateHeaderText}>
                    {message.textContent}
                </span>
            </div>
        );
    }

    renderDonorTaskExpirationMessage() {
        const {
            message,
            maxWidth,
        } = this.props;

        const { isVisible, } = this.state;

        const { dataContent, } = message;
        const incompleteFields = dataContent.incomplete_fields;
        const donorIdText = dataContent.opo_donor_id ? `${dataContent.opo_donor_id}: ` : '';
        const displayText = dataContent.description ? `${donorIdText}The task "${dataContent.description}" has passed its due date.`
            : `${donorIdText}A task has passed its due date.`;

        return (
            <div style={ChatMessageStyles.donorTaskExpirationMessageRow}>
                {this.renderAvatar(true, false, 'donorTaskExpiration')}
                <div
                    style={{ ...ChatMessageStyles.donorTaskExpirationMessageWrapper, ...{ maxWidth, }, }}
                    onMouseEnter={this.onHover}
                    onMouseLeave={this.onLeave}
                >
                    <span style={ChatMessageStyles.systemMessageSentTime}>
                        {formatDateTime(message.sentTime)}
                        <br />
                    </span>
                    <span
                        style={{ ...ChatMessageStyles.chatDonorStatusUpdateHeaderText, ...{ maxWidth, }, }}
                    >
                        {displayText}
                    </span>
                    {this.renderMenuButton()}
                    {this.renderMessageMenu()}
                    {(isVisible) ? (
                        <ReactTooltip
                            multiline
                        />
                    ) : null}
                    {incompleteFields && incompleteFields.length > 0
                        ? (
                            <>
                                <br />
                                <span style={{ ...ChatMessageStyles.chatDonorStatusUpdateHeaderText, ...{ maxWidth, }, }}>
                                    Incomplete fields (*required fields):
                                </span>
                                <ul style={ChatMessageStyles.donorTaskExpiraitonList}>
                                    {incompleteFields.map((field, idx) => (
                                        <li
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`${field.title}-${idx}`}
                                            style={ChatMessageStyles.chatDonorStatusUpdateText}
                                        >
                                            <span>
                                                {field.title}
                                                {field.mandatory ? '*' : ''}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : null}
                </div>
            </div>
        );
    }

    renderMessage(renderType: string, shouldTruncate: boolean) {
        if (renderType === 'image' || renderType === 'video' || renderType === 'pdf') return this.renderMedia();
        if (renderType === 'emoji') return this.renderEmojiOnly();
        if (renderType === 'donor') return this.renderDonorOffer();
        if (renderType === 'system') return this.renderSystemMessage();
        if (renderType === 'text') return this.renderTextMessage(shouldTruncate);
        if (renderType === 'redacted') return this.renderRedactedMessage();
        if (renderType === 'reply') return this.renderReplyMessage(shouldTruncate);

        if (renderType === 'donorTask') return this.renderDonorTaskMessage();
        if (renderType === 'openCloseDonor') return this.renderDonorStatusMessage();
        if (renderType === 'call') return this.renderCallMessage();
        if (renderType === 'donorTaskExpiration') return this.renderDonorTaskExpirationMessage();
        if (renderType === 'donorTaskCleared') return this.renderDonorTaskClearedMessage();

        return null;
    }

    render() {
        const {
            message,
            isMine,
            maxWidth,
            showSender,
            messageMenu,
            messageSendStatus,
        } = this.props;

        const {
            isDialogOpen,
            showSeenBy,
            isVisible,
        } = this.state;

        const {
            replyingMessageId,
            replyingSenderId,
            messageType,
            textContent,
            redactTime,
            id,
        } = message;

        let renderType = 'text'; // default to text - which therefore is a catch-all ensuring everything is rendered
        if (ImageMessageTypes.indexOf(messageType) !== -1) renderType = 'image';
        if (messageType === MessageTypes.mp4 || messageType === MessageTypes.mov) renderType = 'video';
        if (messageType === MessageTypes.pdf) renderType = 'pdf';
        if (message.dataContent && AskAlanMessageTypes.includes(message.messageType)) renderType = 'donor';
        if (SystemMessageTypes.indexOf(messageType) !== -1) renderType = 'system';
        if (messageType === MessageTypes.donorTask) renderType = 'donorTask';
        if (messageType === MessageTypes.donorTaskExpiration) renderType = 'donorTaskExpiration';
        if (messageType === MessageTypes.openCloseDonor) renderType = 'openCloseDonor';
        if (isEmojiMessage(message.textContent)) renderType = 'emoji';
        if (messageType === MessageTypes.callUser) renderType = 'call';
        if (replyingMessageId && replyingSenderId) renderType = 'reply';
        if (redactTime) renderType = 'redacted';
        if (messageType === MessageTypes.multipartMedia && !textContent) renderType = 'multipart';

        let msgDtlsrenderType = 'text'; // default to text - which therefore is a catch-all ensuring everything is rendered
        if (ImageMessageTypes.indexOf(messageType) !== -1) msgDtlsrenderType = 'image';
        if (messageType === MessageTypes.mp4 || messageType === MessageTypes.mov) msgDtlsrenderType = 'video';
        if (messageType === MessageTypes.pdf) msgDtlsrenderType = 'pdf';
        if (message.dataContent && AskAlanMessageTypes.includes(message.messageType)) msgDtlsrenderType = 'donor';
        if (SystemMessageTypes.indexOf(messageType) !== -1) msgDtlsrenderType = 'system';
        if (messageType === MessageTypes.donorTask) msgDtlsrenderType = 'donorTask';
        if (messageType === MessageTypes.donorTaskExpiration) msgDtlsrenderType = 'donorTaskExpiration';
        if (messageType === MessageTypes.openCloseDonor) msgDtlsrenderType = 'openCloseDonor';
        if (isEmojiMessage(message.textContent)) msgDtlsrenderType = 'emoji';
        if (messageType === MessageTypes.callUser) msgDtlsrenderType = 'call';
        if (replyingMessageId && replyingSenderId) msgDtlsrenderType = 'reply';
        if (redactTime && !isDialogOpen) msgDtlsrenderType = 'redacted';

        const isTaskCompleteIncompleteMessage = (renderType === 'donorTask'
            && (message.dataContent?.action === 'complete' || message.dataContent?.action === 'incomplete'));
        const isTaskClearedMessage = (renderType === 'donorTask' && message.dataContent?.action === 'cleared');
        const isSystemMessage = renderType === 'system';
        const isDonorTaskExpirationMessage = renderType === 'donorTaskExpiration';
        const isNormalMessage = !isSystemMessage && !isDonorTaskExpirationMessage && !isTaskCompleteIncompleteMessage && !isTaskClearedMessage;

        const messageRedactedAt = `Message redacted at ${formatFullDateTime(redactTime || '')}`;
        let messageDetailsChatItem = (
            <div>
                {isNormalMessage ? this.renderAvatar(true, true, renderType) : null}
                {isTaskCompleteIncompleteMessage ? this.renderAvatar(true, true, renderType) : null}
                <div style={ChatMessageStyles.chatItemWrapper}>
                    <div style={isDialogOpen ? ChatMessageStyles.modalDialogContentWrapper : ChatMessageStyles.modalContentWrapper}>
                        {isSystemMessage ? this.renderSystemMessage() : null}
                        {isDonorTaskExpirationMessage ? this.renderDonorTaskExpirationMessage() : null}
                        {isNormalMessage ? this.renderMessage(msgDtlsrenderType, true) : null}
                        {isTaskCompleteIncompleteMessage ? this.renderMessage(msgDtlsrenderType, true) : null}
                        {isTaskClearedMessage ? this.renderMessage(msgDtlsrenderType, true) : null}
                    </div>
                    {redactTime ? (
                        <span style={ChatMessageStyles.redactedText}>
                            {messageRedactedAt}
                        </span>
                    ) : null}
                </div>
            </div>
        );

        // users can't redact ask alan messages so we don't necessarily need to render them on the message details modal
        if (msgDtlsrenderType === 'donor') {
            messageDetailsChatItem = null;
        }

        if (msgDtlsrenderType === 'image' || msgDtlsrenderType === 'video' || msgDtlsrenderType === 'pdf') {
            messageDetailsChatItem = (
                <div>
                    {this.renderAvatar(true, true, renderType)}
                    <div style={ChatMessageStyles.chatItemWrapper}>
                        <div style={ChatMessageStyles.chatMessageWrapperSender}>
                            <span
                                style={
                                    isMine
                                        ? { ...ChatMessageStyles.chatMessageWrapperTextSelf, ...{ maxWidth, }, }
                                        : { ...ChatMessageStyles.chatMessageWrapperText, ...{ maxWidth, }, }
                                }
                            >
                                Media Message
                            </span>
                        </div>
                        {redactTime ? (
                            <span style={ChatMessageStyles.redactedText}>
                                {messageRedactedAt}
                            </span>
                        ) : null}
                    </div>
                </div>
            );
        }

        const displaySender = renderType === 'multipart' ? false : showSender;

        return (
            <VisibilitySensor onChange={this.onVisibilityChange}>
                <div id={`message${id}`}>
                    {isSystemMessage ? this.renderSystemMessage() : null}
                    {isDonorTaskExpirationMessage ? this.renderDonorTaskExpirationMessage() : null}
                    {isTaskCompleteIncompleteMessage ? this.renderDonorTaskCompleteIncompleteMessage() : null}
                    {isTaskClearedMessage ? this.renderDonorTaskClearedMessage() : null}
                    {isNormalMessage ? (
                        <div>
                            {this.renderAvatar(displaySender, false, renderType)}
                            <div style={ChatMessageStyles.chatItemWrapper}>
                                <div // eslint-disable-line
                                    style={
                                        showSender
                                            ? ChatMessageStyles.chatMessageWrapperSender : ChatMessageStyles.chatMessageWrapperNoSender
                                    }
                                    onMouseEnter={this.onHover}
                                    onMouseLeave={this.onLeave}
                                    onClick={messageMenu ? this.onHideMessageMenu : null}
                                >
                                    <div style={ChatMessageStyles.relativePosition}>
                                        {this.renderMessage(renderType, false)}
                                        {this.renderMenuButton()}
                                        {this.renderMessageMenu()}
                                        {(isVisible) ? (
                                            <ReactTooltip
                                                multiline
                                            />
                                        ) : null}
                                        {(messageSendStatus === 'failed') ? (
                                            <span
                                                style={ChatMessageStyles.sendError}
                                                // eslint-disable-next-line max-len
                                                data-tip="Your message could not be sent.<br>Check your connection, refresh the page, and try again."
                                                data-delay-show="250"
                                                data-effect="solid"
                                            >
                                                Failed to send
                                            </span>
                                        ) : (
                                            /* Note: The above tooltip does not show if there is not one here! */
                                            /*       Makes no sense but I'll leave this here pending a better solution  */
                                            <span
                                                style={ChatMessageStyles.sendError}
                                                data-tip=""
                                                data-delay-show="250"
                                                data-effect="solid"
                                            />
                                        )}
                                    </div>
                                    {message.ack ? this.renderAck() : null}
                                    {this.renderSeenByText()}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <>
                        {isDialogOpen
                            ? (
                                <Dialog
                                    active={isDialogOpen}
                                    type="small"
                                    onEscKeyDown={this.dialogClose}
                                    onOverlayClick={this.dialogClose}
                                >
                                    <button
                                        type="button"
                                        style={ChatMessageStyles.modalCloseButton}
                                        className="closeButton"
                                        onClick={this.dialogClose}
                                    >
                                        <FontIcon
                                            value="close"
                                            alt=""
                                        />
                                    </button>
                                    {messageDetailsChatItem}
                                    <TabSelector
                                        labels={['Seen by', 'Not seen by']}
                                        selected={[showSeenBy, !showSeenBy]}
                                        width={100}
                                        borderRadius
                                        clickEvents={[this.seenByClick, this.notSeenByClick]}
                                    />
                                    {this.renderSeenBy()}
                                </Dialog>
                            ) : <div />}
                    </>
                </div>
            </VisibilitySensor>
        );
    }
}

const mapStateToProps = (state) => {
    const chatId = state.chatList.activeChatId || 0;
    const currentChat = chatId ? state.chatList.chats[chatId] : null;

    return {
        resendNotificationCache: state.chatroom.resendNotificationCache,
        verifiedOrgMember: state.auth.profile.verifiedOrgMember,
        isConnected: state.chatList.socketStatus === 'connected',
        currentChat,
        donors: state.donor.donors,
    };
};

export default connect(mapStateToProps, {
    loadMessageStatuses: _loadMessageStatuses,
    redactMessage: _redactMessage,
    unredactMessage: _unredactMessage,
    deleteFileMessage: _deleteFileMessage,
    setMessageReply: _setMessageReply,
    resendNotification: _resendNotification,
    selectDonorView: _selectDonorView,
    navigateToTask: _navigateToTask,
})(ChatItem);
