// @flow

type AppConfiguration = {
    version: string,
};

export const appConfiguration = (): AppConfiguration => ({
    version: '2.65.0-work',
});

export default appConfiguration;
