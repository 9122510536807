// @flow
import React from 'react';
import Chip from 'react-toolbox/lib/chip';
import Avatar from 'react-toolbox/lib/avatar';
import type { Tag } from '../Utils/types';
import { getOrganIcon, getTagIcon } from '../Utils/getTagIcon';
import { getTextColorBasedOnBgColor } from '../Utils/tags';
import ChatListStyles from './Styles/ChatListStyles';

type Props = {
    tag: Tag,
};

const CaseTag = ({ tag, }: Props) => {
    const {
        category,
        label,
        displayColors,
    } = tag;

    // use black text for lighter backgrounds
    const textLabelColor = getTextColorBasedOnBgColor(displayColors[0], '#FFFFFF', '#000000');

    const tagData = category === 'Organs' ? getOrganIcon(label) : getTagIcon(label);
    const { icon, keepStroke = false, } = tagData;

    return (
        <Chip
            key={`tag-${label}`}
            style={ChatListStyles.caseTagItem}
        >
            <Avatar
                style={{
                    ...(keepStroke ? ChatListStyles.organTagAvatarNoStroke : ChatListStyles.organTagAvatar),
                    ...{ backgroundColor: displayColors[0], color: displayColors[1] ? displayColors[1] : textLabelColor, },
                }}
            >
                {icon}
            </Avatar>
            <span>{label}</span>
        </Chip>
    );
};

export default CaseTag;
