// @flow
import * as Sentry from '@sentry/browser';
import type { Logout } from 'txp-core';
import { selectProfileName } from 'txp-core';

import { keys, values } from '../Utils/Object';
import type {
    ChatroomInfo,
    ChatroomInfoMap,
    ChatroomMessage,
    ChatroomMessageMap,
    ChatroomMemberMap,
    ChatroomType,
    MessageId,
    LastMessage,
    PersistRehydrate,
    ChatSocketStatus,
    ChatSocketError,
    ReadStatusMap,
    MessageReadStatus,
    MessageSendStatus,
    MessageType,
    SearchResultMap,
    SearchResult,
    ChatroomFile,
    ChatroomFileMap,
    AvatarMap,
} from '../Utils/types';
import { getBadgeCount } from '../Utils/sortChatrooms';
import type { ResetData, ResetError } from './ApplicationActions';
import { resetErrorState, getReducerError } from './ApplicationActions';
import type { SaveChatSuccess } from './ChatEditActions';
import hasValue from '../Utils/hasValue';
import { NotificationSoundMap } from '../Themes/Sounds';
import createUuid from '../Utils/createUuid';

export const CHAT_PAGE_SIZE = 50;

type ChatListState = {
    activeChatId: ?number,

    order: Array<number>,
    chats: ChatroomInfoMap,
    isTyping: boolean,

    filter: string,
    offerFilter: string,
    organizationFilter: string,
    peopleFilter: string,

    avatars: AvatarMap,

    socketStatus: ChatSocketStatus,
    socketError: ?ChatSocketError,

    searchResults: ?SearchResultMap, // null -> no search results found, [] -> search has not occurred
    selectedResult: SearchResult, // search result that user clicks on
    scrolledToSelectedResult: boolean,
    deepSearchFilter: string, // This is used so that the highlighted text that matches a search result only changes when the user
                                // performs a deep search and doesn't change as the user types in the search bar.
    reducerErrors: Array<any>,
};

const initialState: ChatListState = {
    activeChatId: null,
    order: [],
    chats: {},
    isTyping: false,

    filter: '',
    offerFilter: '',
    organizationFilter: '',
    peopleFilter: '',

    avatars: {},

    socketStatus: 'unknown',
    socketError: null,

    searchResults: {},
    selectedResult: {},
    scrolledToSelectedResult: false,
    deepSearchFilter: '',
    reducerErrors: [],
};

export type ResetChatList = { type: 'ChatList/RESET_DATA' };
export type LoadChatrooms = { type: 'ChatList/LOAD' };
export type Search = { type: 'ChatList/SEARCH', searchText: string };
export type SetSearchResults = { type: 'ChatList/SEARCH_RESULTS', searchResults: ?SearchResultMap };
export type SetSelectedResult = { type: 'ChatList/SET_SELECTED_RESULT', selectedResult: SearchResult };
export type AddedToChatroom = {
    type: 'ChatList/ADDED_TO_CHATROOM',
    roomId: number,
    isPushNotification: boolean,
    name: string,
    description: string,
    chatroomType: ChatroomType,
    managed: boolean,
    creatorId: number,
    memberCount: number,
    totalMessages?: number,
    lastMessage?: LastMessage,
    lastUpdateTime?: string,
    lastActiveTime?: string,
    donorId?: number,
    organId?: number,
    followerId?: number,
    createDate: string,
    caseName?: string,
    followerName?: string,
};
export type RemovedFromChatroom = { type: 'ChatList/REMOVED_FROM_CHATROOM', roomId: number };
export type DeletedChatroom = { type: 'ChatList/DELETED_CHATROOM', roomId: number};
export type MemberRemoved = { type: 'ChatList/MEMBER_REMOVED', chatId: number, memberId: number, memberCount: number, leftChat: boolean, };
export type RemoveMemberFromRoom = {
    type: 'ChatList/REMOVE_MEMBER_FROM_ROOM',
    chatId: number,
    memberId: number,
};
export type ReceiveChatroomMembers = {
    type: 'ChatList/RECEIVE_ROOM_MEMBERS',
    id: number,
    memberId: number,
    members: ChatroomMemberMap,
    memberOrder: Array<number>,
    hint: 'replace' | 'extend',
};

export type ReceiveBulkChatroomMembers = {
    type: 'ChatList/BULK_RECEIVE_MEMBERS',
    memberId: number,
    rooms: Array<{
        id: number,
        members: ChatroomMemberMap,
        memberOrder: Array<number>,
    }>,
};

export type LoadChatMembers = {
    type: 'ChatList/LOAD_MEMBERS',
    chatId: number,
};

export type RemoveMemberAvatar = {
    type: 'ChatList/REMOVE_MEMBER_AVATAR',
    member: number,
};

export type LoadMemberAvatars = {
    type: 'ChatList/LOAD_MEMBER_AVATARS',
    members: ChatroomMemberMap,
    memberOrder: Array<number>,
};

export type ReceiveMemberAvatars = {
    type: 'ChatList/RECEIVE_MEMBER_AVATARS',
    avatars: AvatarMap,
};

export type LoadMessages = {
    type: 'ChatList/LOAD_MESSAGES',
    chatId: number,
    memberId: number,
    resetMessages: boolean,
    numberMessages: number, // number of messages to load
};

export type PartialChatroomInfo = {
    id: number,
    name: string,
    description: string,
    chatroomType: ChatroomType,
    managed: boolean,
    doNotDisturb: boolean,
    createDate: string,
    creatorId: ?number,
    memberCount: number,
    pinNumber: ?number,
    totalMessages: number,
    sentCount: number,
    readCount: number,
    clearedCount: number,
    lastMessage: ?LastMessage,
    lastUpdateTime: ?string,
    lastActiveTime: ?string,
    donorId: ?number,
    organId: ?number,
    organType: ?string,
    targetOrgId: ?number,
    donorClosed: boolean,
    followerId: ?number,
    caseName: ?string,
    followerName: ?string,
};

export type ReceiveChatroomInfo = {
    type: 'ChatList/RECEIVE_ROOM_INFO',
    id: number,
    name: string,
    description: string,
    chatroomType: ChatroomType,
    managed: boolean,
    creatorId: number,
    memberCount: number,
    pinNumber: ?number,
    totalMessages: number,
    sentCount: number,
    readCount: number,
    clearedCount: number,
    lastMessage: ?LastMessage,
    lastUpdateTime: ?string,
    lastActiveTime: ?string,
    donorId: ?number,
    organId: ?number,
    targetOrgId: ?number,
    followerId: ?number,
    createDate: string,
    caseName: ?string,
    followerName: ?string,
};

export type UpdateChatroomInfo = {
    type: 'ChatList/UPDATE_CHATROOM_INFO',
    id: number,
    name: ?string,
    description: string,
    managed: boolean,
    lastUpdateTime: ?string,
};

export type ReceiveChatroomMessages = {
    type: 'ChatList/RECEIVE_ROOM_MESSAGES',
    id: number,
    memberId: number,
    messages: ChatroomMessageMap,
    messageOrder: Array<MessageId>,
    hint: 'end' | 'start' | 'replace',
    lastMessage: ?LastMessage,
    lastActiveTime: ?string,
    isOldPage: boolean,
};

export type ReceiveNewMessage = {
    type: 'ChatList/RECEIVE_NEW_MESSAGE',
    id: number,
    memberId: number,
    message: ChatroomMessage,
    messageId: MessageId,
    hint: 'end' | 'start' | 'replace',
    lastMessage: ?LastMessage,
    lastActiveTime: ?string,
    sentCountModifier: number,
};

export type OpenChatroom = {
    type: 'ChatList/OPEN_CHATROOM',
    chatId: number,
};

export type CloseChatroom = {
    type: 'ChatList/CLOSE_CHATROOM',
    chatId: number,
};

export type RemoveChatroom = {
    type: 'ChatList/REMOVE',
    chatId: number,
};

export type ClearChatroomNotifications = {
    type: 'ChatList/CLEAR_NOTIFICATIONS',
    chatId: number,
};

export type SetClearedNotificationCount = {
    type: 'ChatList/SET_CLEARED_COUNT',
    chatId: number,
    newCount: ?number,
};

export type ReceiveBulkChatroomInfo = {
    type: 'ChatList/BULK_RECEIVE_ROOMS',
    chatrooms: Array<PartialChatroomInfo>,
};

export type MessageSendSuccess = {
    type: 'ChatList/MESSAGE_SUCCESS',
    chatId: number,
    memberId: number,
    tmpId: string,
    newId: number,
    updateData: {
        status: ?MessageSendStatus,
        sentTime: string,
        messageType: MessageType,
        textContent: string,
        fileName: ?string,
        displayName: ?string,
    },
};

export type LoadChatFiles = {
    type: 'ChatList/LOAD_FILES',
    chatId: number,
    memberId: number,
};

export type ReceiveChatroomFiles = {
    type: 'ChatList/RECEIVE_ROOM_FILES',
    id: number,
    fileMap: ChatroomFileMap,
    allFiles: Array<MessageId>,
    myFiles: Array<MessageId>,
};

export type SetSelectedFile = {
    type: 'ChatList/SET_SELECTED_FILE',
    id: number,
    selectedFile: ChatroomFile,
};

export type SetLocalPath = {
    type: 'ChatList/SET_LOCAL_PATH',
    chatId: number,
    messageId: number,
    localPath: string,
};

export type SetMessageSendStatus = {
    type: 'ChatList/MESSAGE_SEND_STATUS',
    chatId: number,
    messageId: MessageId,
    status: ?MessageSendStatus,
};

export type LoadMessageStatuses = {
    type: 'ChatList/LOAD_MSG_STATUSES',
    chatId: number,
    memberId: number,
    messageIds: Array<number>,
};

export type BulkUpdateMessageStatus = {
    type: 'ChatList/BULK_UPDATE_MESSAGE_STATUS',

    memberId: number, // id of the current user
    chatId: number,
    statuses: {
        [string]: ReadStatusMap,
    },
    mode: 'extend' | 'replace',
    newStatusCount: number,
    updatedStatusCount: number,
};

export type MarkMessagesRead = {
    type: 'ChatList/MARK_MESSAGES_READ',
    chatId: number,
    memberId: number,
    untilId: MessageId,
};

export type TriggerChatSocket = {
    type: 'ChatList/SOCKET',
};

export type SetChatSocketStatus = {
    type: 'ChatList/SOCKET_STATUS',
    status: ChatSocketStatus,
    error: ?ChatSocketError,
};

export type SetOffset = {
    type: 'ChatList/SET_PAGE_OFFSET',
    chatId: number,
    memberId: number,
    offset: number,
    numberMessages: ?number,
};

export type SetScrolledToResult = {
    type: 'ChatList/SET_SCROLLED_TO_SEARCH_RESULT',
    scrolledToSelectedResult: boolean,
};

export type UpdateDonorId = {
    type: 'ChatList/UPDATE_DONOR_ID',
    chatId: number,
    donorId: number,
};

export type UpdateOrganId = {
    type: 'ChatList/UPDATE_ORGAN_ID',
    chatId: number,
    organId: number,
};

export type FilterChatrooms = { type: 'ChatList/FILTER', filter: string };
export type FilterOffers = { type: 'ChatList/FILTER_OFFER', offerFilter: string };
export type FilterOrganizations = { type: 'ChatList/FILTER_ORGANIZATION', organizationFilter: string };
export type FilterPeople = { type: 'ChatList/FILTER_PEOPLE', peopleFilter: string };
export type ResetFilters = { type: 'ChatList/RESET_FILTERS' };

export type AcknowledgeMessage = { type: 'ChatList/ACK_MESSAGE', chatId: number, messageId: number, symbol: string };
export type ReceiveAcknowledge = { type: 'ChatList/RECEIVE_ACK', chatId: number, messageId: number, memberId: number, symbol: string };

export type StartTyping = { type: 'ChatList/START_TYPING', chatId: number };
export type StopTyping = { type: 'ChatList/STOP_TYPING', chatId: number };
export type MemberStartedTyping = { type: 'ChatList/MEMBER_STARTED_TYPING', chatId: number, memberId: number };
export type MemberStoppedTyping = { type: 'ChatList/MEMBER_STOPPED_TYPING', chatId: number, memberId: number };

export type PinChatroom = { type: 'ChatList/PIN_CHATROOM', chatId: number };
export type PinnedChatroom = { type: 'ChatList/PINNED_CHATROOM', chatId: number, pinNumber: number };
export type UnpinChatroom = { type: 'ChatList/UNPIN_CHATROOM', chatId: number };
export type UnpinnedChatroom = { type: 'ChatList/UNPINNED_CHATROOM', chatId: number, };
export type UpdatePinnedChatrooms = {
    type: 'ChatList/UPDATE_PINNED_CHATROOMS',
    pinnedChatrooms: { [string]: number },
};

export type RedactMessage = {
    type: 'ChatList/REDACT_MESSAGE',
    chatId: number,
    messageId: number,
};
export type RedactMessageSuccess = {
    type: 'ChatList/REDACT_SUCCESS',
    chatId: number,
    messageId: number,
    redactTime: string,
};
export type UnredactMessage = {
    type: 'ChatList/UNREDACT_MESSAGE',
    chatId: number,
    messageId: number,
};
export type UnredactMessageSuccess = {
    type: 'ChatList/UNREDACT_SUCCESS',
    chatId: number,
    messageId: number,
};

export type DeleteFileMessage = {
    type: 'ChatList/DELETE_FILE_MESSAGE',
    chatId: number,
    messageId: number,
};

export type DeleteFileMessageSuccess = {
    type: 'ChatList/DELETE_FILE_MESSAGE_SUCCESS',
    chatId: number,
    messageId: number,
};

type Action =
    | LoadChatrooms
    | ResetChatList
    | Search
    | SetSearchResults
    | SetSelectedResult
    | SetScrolledToResult
    | AddedToChatroom
    | RemovedFromChatroom
    | MemberRemoved
    | RemoveMemberFromRoom
    | SetOffset
    | ReceiveChatroomInfo
    | ReceiveBulkChatroomInfo
    | UpdateChatroomInfo
    | OpenChatroom
    | CloseChatroom
    | RemoveChatroom
    | ClearChatroomNotifications
    | ReceiveChatroomMembers
    | ReceiveBulkChatroomMembers
    | LoadChatMembers
    | LoadMemberAvatars
    | RemoveMemberAvatar
    | ReceiveMemberAvatars
    | LoadMessages
    | ReceiveChatroomMessages
    | ReceiveNewMessage
    | MessageSendSuccess
    | SetMessageSendStatus
    | LoadChatFiles
    | ReceiveChatroomFiles
    | SetSelectedFile
    | SetLocalPath
    | SetClearedNotificationCount
    | BulkUpdateMessageStatus
    | LoadMessageStatuses
    | MarkMessagesRead
    | UpdateDonorId
    | UpdateOrganId
    | FilterChatrooms
    | FilterOffers
    | FilterOrganizations
    | FilterPeople
    | ResetFilters
    | AcknowledgeMessage
    | ReceiveAcknowledge
    | StartTyping
    | StopTyping
    | MemberStartedTyping
    | MemberStoppedTyping
    | PinChatroom
    | PinnedChatroom
    | UnpinChatroom
    | UnpinnedChatroom
    | UpdatePinnedChatrooms
    | RedactMessage
    | RedactMessageSuccess
    | UnredactMessage
    | UnredactMessageSuccess
    | DeleteFileMessage
    | DeleteFileMessageSuccess
    | TriggerChatSocket
    | SetChatSocketStatus
    | SaveChatSuccess
    | Logout
    | ResetData
    | ResetError
    | PersistRehydrate;

export const resetChatList = (): ResetChatList => ({
    type: 'ChatList/RESET_DATA',
});

export const loadChatrooms = (): LoadChatrooms => ({
    type: 'ChatList/LOAD',
});

export const search = (searchText: string): Search => ({
    type: 'ChatList/SEARCH',
    searchText,
});

export const setSearchResults = (searchResults: ?SearchResultMap): SetSearchResults => ({
    type: 'ChatList/SEARCH_RESULTS',
    searchResults,
});

export const setSelectedResult = (selectedResult: SearchResult): SetSelectedResult => ({
    type: 'ChatList/SET_SELECTED_RESULT',
    selectedResult,
});

export const setScrolledToResult = (scrolledToSelectedResult: boolean): SetScrolledToResult => ({
    type: 'ChatList/SET_SCROLLED_TO_SEARCH_RESULT',
    scrolledToSelectedResult,
});

export const addedToChatroom = (
    roomId: number,
    isPushNotification: boolean,
    name: string,
    description: string,
    chatroomType: ChatroomType,
    managed: boolean,
    creatorId: number,
    memberCount: number,
    totalMessages?: number,
    lastMessage?: LastMessage,
    lastUpdateTime?: string,
    lastActiveTime?: string,
    donorId?: number,
    organId?: number,
    followerId?: number,
    createDate: string,
    caseName?: string,
    followerName?: string
): AddedToChatroom => ({
    type: 'ChatList/ADDED_TO_CHATROOM',
    roomId,
    isPushNotification,
    name,
    description,
    chatroomType,
    managed,
    creatorId,
    memberCount,
    totalMessages,
    lastMessage,
    lastUpdateTime,
    lastActiveTime,
    donorId,
    organId,
    followerId,
    createDate,
    caseName,
    followerName,
});

export const removedFromChatroom = (roomId: number): RemovedFromChatroom => ({
    type: 'ChatList/REMOVED_FROM_CHATROOM',
    roomId,
});

export const deletedChatroom = (roomId: number): DeletedChatroom => ({
    type: 'ChatList/DELETED_CHATROOM',
    roomId,
});

export const memberRemoved = (chatId: number, memberId: number, memberCount: number, leftChat: boolean): MemberRemoved => ({
    type: 'ChatList/MEMBER_REMOVED',
    chatId,
    memberId,
    memberCount,
    leftChat,
});

// This removes the member locally
export const removeMemberFromRoom = (chatId: number, memberId: number): RemoveMemberFromRoom => ({
    type: 'ChatList/REMOVE_MEMBER_FROM_ROOM',
    chatId,
    memberId,
});

export const clearChatroomNotifications = (chatId: number): ClearChatroomNotifications => ({
    type: 'ChatList/CLEAR_NOTIFICATIONS',
    chatId,
});

export const receiveChatroomInfo = (
    id: number,
    name: string,
    description: string,
    chatroomType: ChatroomType,
    managed: boolean,
    creatorId: number,
    memberCount: number,
    pinNumber: ?number,
    totalMessages: number,
    sentCount: number,
    readCount: number,
    clearedCount: number,
    lastMessage: ?LastMessage,
    lastUpdateTime: ?string,
    lastActiveTime: ?string,
    donorId: ?number,
    organId: ?number,
    targetOrgId: ?number,
    followerId: ?number,
    createDate: string,
    caseName?: string,
    followerName?: string
): ReceiveChatroomInfo => ({
    type: 'ChatList/RECEIVE_ROOM_INFO',
    id,
    name,
    description,
    chatroomType,
    managed,
    creatorId,
    memberCount,
    pinNumber,
    totalMessages,
    sentCount,
    readCount,
    clearedCount,
    lastMessage,
    lastUpdateTime,
    lastActiveTime,
    donorId,
    organId,
    targetOrgId,
    followerId,
    createDate,
    caseName,
    followerName,
});

export const receiveBulkChatroomInfo = (chatrooms: Array<PartialChatroomInfo>): ReceiveBulkChatroomInfo => ({
    type: 'ChatList/BULK_RECEIVE_ROOMS',
    chatrooms,
});

export const updateChatroomInfo = (
    id: number,
    name: ?string,
    description: string,
    managed: boolean,
    lastUpdateTime: ?string
): UpdateChatroomInfo => ({
    type: 'ChatList/UPDATE_CHATROOM_INFO',
    id,
    name,
    description,
    managed,
    lastUpdateTime,
});

export const openChatroom = (chatId: number): OpenChatroom => ({
    type: 'ChatList/OPEN_CHATROOM',
    chatId,
});

export const closeChatroom = (chatId: number): CloseChatroom => ({
    type: 'ChatList/CLOSE_CHATROOM',
    chatId,
});

export const removeChatroom = (chatId: number): RemoveChatroom => ({
    type: 'ChatList/REMOVE',
    chatId,
});

export const receiveChatroomMembers = (
    id: number,
    memberId: number,
    members: ChatroomMemberMap,
    memberOrder: Array<number>,
    hint: 'replace' | 'extend'
): ReceiveChatroomMembers => ({
    type: 'ChatList/RECEIVE_ROOM_MEMBERS',
    id,
    memberId,
    members,
    memberOrder,
    hint,
});

export const receiveBulkChatroomMembers = (memberId: number, rooms: Array<{
    id: number,
    members: ChatroomMemberMap,
    memberOrder: Array<number>,
}>): ReceiveBulkChatroomMembers => ({
    type: 'ChatList/BULK_RECEIVE_MEMBERS',
    memberId,
    rooms,
});

export const loadChatMembers = (chatId: number): LoadChatMembers => ({
    type: 'ChatList/LOAD_MEMBERS',
    chatId,
});

export const removeMemberAvatar = (
    member: number
): RemoveMemberAvatar => ({
    type: 'ChatList/REMOVE_MEMBER_AVATAR',
    member,
});

export const loadMemberAvatars = (
    members: ChatroomMemberMap,
    memberOrder: Array<number>
): LoadMemberAvatars => ({
    type: 'ChatList/LOAD_MEMBER_AVATARS',
    members,
    memberOrder,
});

export const receiveMemberAvatars = (avatars: AvatarMap): ReceiveMemberAvatars => ({
    type: 'ChatList/RECEIVE_MEMBER_AVATARS',
    avatars,
});

export const loadMessages = (
    chatId: number,
    memberId: number,
    resetMessages: boolean,
    numberMessages: number
): LoadMessages => ({
    type: 'ChatList/LOAD_MESSAGES',
    chatId,
    memberId,
    resetMessages,
    numberMessages,
});

export const receiveChatroomMessages = (
    id: number,
    memberId: number,
    messages: ChatroomMessageMap,
    messageOrder: Array<MessageId>,
    hint: 'end' | 'start' | 'replace',
    lastMessage: ?LastMessage,
    lastActiveTime: ?string,
    isOldPage: boolean
): ReceiveChatroomMessages => ({
    type: 'ChatList/RECEIVE_ROOM_MESSAGES',
    id,
    memberId,
    messages,
    messageOrder,
    hint,
    lastMessage,
    lastActiveTime,
    isOldPage,
});

export const receiveNewMessage = (
    id: number,
    memberId: number,
    message: ChatroomMessage,
    messageId: MessageId,
    hint: 'end' | 'start' | 'replace',
    lastMessage: ?LastMessage,
    lastActiveTime: ?string,
    sentCountModifier: number
): ReceiveNewMessage => ({
    type: 'ChatList/RECEIVE_NEW_MESSAGE',
    id,
    memberId,
    message,
    messageId,
    hint,
    lastMessage,
    lastActiveTime,
    sentCountModifier,
});

export const messageSendSuccess = (
    chatId: number,
    memberId: number,
    tmpId: string,
    newId: number,
    updateData: {
        status: ?MessageSendStatus,
        sentTime: string,
        messageType: MessageType,
        textContent: string,
        fileName: ?string,
        displayName: ?string,
    }
): MessageSendSuccess => ({
    type: 'ChatList/MESSAGE_SUCCESS',
    chatId,
    memberId,
    tmpId,
    newId,
    updateData,
});

export const loadChatFiles = (chatId: number, memberId: number): LoadChatFiles => ({
    type: 'ChatList/LOAD_FILES',
    chatId,
    memberId,
});

export const receiveChatroomFiles = (
    id: number,
    fileMap: ChatroomFileMap,
    allFiles: Array<MessageId>,
    myFiles: Array<MessageId>
): ReceiveChatroomFiles => ({
    type: 'ChatList/RECEIVE_ROOM_FILES',
    id,
    fileMap,
    allFiles,
    myFiles,
});

export const setSelectedFile = (id: number, selectedFile: ChatroomFile): SetSelectedFile => ({
    type: 'ChatList/SET_SELECTED_FILE',
    id,
    selectedFile,
});

export const setLocalPath = (chatId: number, messageId: number, localPath: string): SetLocalPath => ({
    type: 'ChatList/SET_LOCAL_PATH',
    chatId,
    messageId,
    localPath,
});

export const setMessageStatus = (chatId: number, messageId: MessageId, status: ?MessageSendStatus): SetMessageSendStatus => ({
    type: 'ChatList/MESSAGE_SEND_STATUS',
    chatId,
    messageId,
    status,
});

export const bulkUpdateMessageStatus = (
    chatId: number,
    memberId: number,
    statuses: {
        [string]: ReadStatusMap,
    },
    mode: 'extend' | 'replace',
    newStatusCount: number,
    updatedStatusCount: number
): BulkUpdateMessageStatus => ({
    type: 'ChatList/BULK_UPDATE_MESSAGE_STATUS',
    chatId,
    memberId,
    statuses,
    mode,
    newStatusCount,
    updatedStatusCount,
});

export const setClearedNotificationCount = (chatId: number, newCount: ?number): SetClearedNotificationCount => ({
    type: 'ChatList/SET_CLEARED_COUNT',
    chatId,
    newCount,
});

export const loadMessageStatuses = (chatId: number, memberId: number, messageIds: Array<number>): LoadMessageStatuses => ({
    type: 'ChatList/LOAD_MSG_STATUSES',
    chatId,
    memberId,
    messageIds,
});

export const markMessagesRead = (chatId: number, memberId: number, untilId: MessageId): MarkMessagesRead => ({
    type: 'ChatList/MARK_MESSAGES_READ',
    chatId,
    memberId,
    untilId,
});

export const triggerChatSocket = (): TriggerChatSocket => ({
    type: 'ChatList/SOCKET',
});

export const setSocketState = (
    status: ChatSocketStatus,
    error: ?ChatSocketError = null
): SetChatSocketStatus => ({
    type: 'ChatList/SOCKET_STATUS',
    status,
    error,
});

export const setOffset = (chatId: number, memberId: number, offset: number, numberMessages: ?number = CHAT_PAGE_SIZE): SetOffset => ({
    type: 'ChatList/SET_PAGE_OFFSET',
    chatId,
    memberId,
    offset,
    numberMessages,
});

export const updateDonorId = (chatId: number, donorId: number): UpdateDonorId => ({
    type: 'ChatList/UPDATE_DONOR_ID',
    chatId,
    donorId,
});

export const updateOrganId = (chatId: number, organId: number): UpdateOrganId => ({
    type: 'ChatList/UPDATE_ORGAN_ID',
    chatId,
    organId,
});

export const filterChatrooms = (filter: string): FilterChatrooms => ({
    type: 'ChatList/FILTER',
    filter,
});

export const filterOffers = (offerFilter: string): FilterOffers => ({
    type: 'ChatList/FILTER_OFFER',
    offerFilter,
});

export const filterOrganizations = (organizationFilter: string): FilterOrganizations => ({
    type: 'ChatList/FILTER_ORGANIZATION',
    organizationFilter,
});

export const filterPeople = (peopleFilter: string): FilterPeople => ({
    type: 'ChatList/FILTER_PEOPLE',
    peopleFilter,
});

export const resetFilters = (): ResetFilters => ({
    type: 'ChatList/RESET_FILTERS',
});

export const acknowledgeMessage = (chatId: number, messageId: number, symbol: string): AcknowledgeMessage => ({
    type: 'ChatList/ACK_MESSAGE',
    chatId,
    messageId,
    symbol,
});

export const receiveAcknowledge = (chatId: number, messageId: number, memberId: number, symbol: string): ReceiveAcknowledge => ({
    type: 'ChatList/RECEIVE_ACK',
    chatId,
    messageId,
    memberId,
    symbol,
});

export const startTyping = (chatId: number): StartTyping => ({
    type: 'ChatList/START_TYPING',
    chatId,
});

export const stopTyping = (chatId: number): StopTyping => ({
    type: 'ChatList/STOP_TYPING',
    chatId,
});

export const memberStartedTyping = (chatId: number, memberId: number): MemberStartedTyping => ({
    type: 'ChatList/MEMBER_STARTED_TYPING',
    chatId,
    memberId,
});

export const memberStoppedTyping = (chatId: number, memberId: number): MemberStoppedTyping => ({
    type: 'ChatList/MEMBER_STOPPED_TYPING',
    chatId,
    memberId,
});

export const pinChatroom = (chatId: number): PinChatroom => ({
    type: 'ChatList/PIN_CHATROOM',
    chatId,
});

export const pinnedChatroom = (chatId: number, pinNumber: number): PinnedChatroom => ({
    type: 'ChatList/PINNED_CHATROOM',
    chatId,
    pinNumber,
});

export const unpinChatroom = (chatId: number): UnpinChatroom => ({
    type: 'ChatList/UNPIN_CHATROOM',
    chatId,
});

export const unpinnedChatroom = (chatId: number): UnpinnedChatroom => ({
    type: 'ChatList/UNPINNED_CHATROOM',
    chatId,
});

export const updatePinnedChatrooms = (pinnedChatrooms: { [string]: number }): UpdatePinnedChatrooms => ({
    type: 'ChatList/UPDATE_PINNED_CHATROOMS',
    pinnedChatrooms,
});

export const redactMessage = (chatId: number, messageId: number): RedactMessage => ({
    type: 'ChatList/REDACT_MESSAGE',
    chatId,
    messageId,
});

export const redactMessageSuccess = (chatId: number, messageId: number, redactTime: string): RedactMessageSuccess => ({
    type: 'ChatList/REDACT_SUCCESS',
    chatId,
    messageId,
    redactTime,
});

export const unredactMessage = (chatId: number, messageId: number): UnredactMessage => ({
    type: 'ChatList/UNREDACT_MESSAGE',
    chatId,
    messageId,
});

export const unredactMessageSuccess = (chatId: number, messageId: number): UnredactMessageSuccess => ({
    type: 'ChatList/UNREDACT_SUCCESS',
    chatId,
    messageId,
});

export const deleteFileMessage = (chatId: number, messageId: number): DeleteFileMessage => ({
    type: 'ChatList/DELETE_FILE_MESSAGE',
    chatId,
    messageId,
});

export const deleteFileMessageSuccess = (chatId: number, messageId: number): DeleteFileMessageSuccess => ({
    type: 'ChatList/DELETE_FILE_MESSAGE_SUCCESS',
    chatId,
    messageId,
});

const mergeMessages = (chatInfo: ChatroomInfo, action: ReceiveChatroomMessages) => {
    const {
        memberId,
        messages,
        messageOrder,
        hint,
        lastMessage,
        lastActiveTime,
        isOldPage,
    } = action;

    // In some cases the message in the room might come before the room information is available
    //  using defaults for that case ensures we do not crash in this method
    const fullChatInfo = {
        status: 'unknown',
        memberOrder: [],
        members: {},
        messageOrder: [],
        messages: {},
        readStatus: {},
        lastFailedId: undefined,
        sentCount: 0,
        readCount: 0,
        clearedCount: 0,
        lastMessage: undefined,
        offset: 0,
        totalMessages: 0,

        // $FlowFixMe
        ...chatInfo,
    };

    let newMessageOrder;

    const originalCount = fullChatInfo.readCount;
    let newCount = originalCount || 0;

    switch (hint) {
        //  Note: This clears the messages that have been `deleted` on the server. However we do not have the same logic
        //  for readStatus so statuses will stay in the store until the user logs out or is removed
        //  from the chatroom.
        case 'replace':
            newMessageOrder = messageOrder.slice();
            newCount = 0;

            newMessageOrder.forEach((msgId) => {
                if (!messages[`${msgId}`].seenByCurrentUser && messages[`${msgId}`].senderId !== memberId) {
                    newCount += 1;
                }
            });

            break;

        case 'start': {
            const addToStart = [];
            newMessageOrder = fullChatInfo.messageOrder.slice();

            messageOrder.forEach((msgId) => {
                if (newMessageOrder.indexOf(msgId) === -1) {
                    addToStart.push(msgId);
                }

                // We do not care about older pages...
                if (!isOldPage) {
                    // Already seen messages and messages from myself should not matter in unread count
                    if (!messages[`${msgId}`].seenByCurrentUser && messages[`${msgId}`].senderId !== memberId) {
                        newCount += 1;
                    }
                }
            });

            newMessageOrder = addToStart.concat(newMessageOrder);

            break;
        }

        case 'end':
        default:
            newMessageOrder = fullChatInfo.messageOrder.slice();

            messageOrder.forEach((msgId) => {
                if (newMessageOrder.indexOf(msgId) === -1) {
                    newMessageOrder.push(msgId);
                }

                // We do not care about older pages...
                if (!isOldPage) {
                    // Already seen messages and messages from myself should not matter in unread count
                    if (!messages[`${msgId}`].seenByCurrentUser && messages[`${msgId}`].senderId !== memberId) {
                        newCount += 1;
                    }
                }
            });
    }

    // Update lastMessage if provided
    const newLastMessage = lastMessage || fullChatInfo.lastMessage;

    // Determine least recent unread message by the current user
    const updatedMessages = {
        ...(hint !== 'replace' ? fullChatInfo.messages : {}),
        ...messages,
    };

    let offset = 0;
    if (isOldPage) {
        // eslint-disable-next-line prefer-destructuring
        offset = fullChatInfo.offset;
    } else {
        // Start where the user left off
        offset = newCount > CHAT_PAGE_SIZE
            ? Math.floor(newCount / CHAT_PAGE_SIZE) * CHAT_PAGE_SIZE
            : fullChatInfo.offset;
    }
    // Make sure we clamp offset if messages were replaced and offset is too big now
    if (hint === 'replace' && offset >= newMessageOrder.length) {
        offset = Math.floor(newMessageOrder.length / CHAT_PAGE_SIZE) * CHAT_PAGE_SIZE;
    }

    return {
        messages: updatedMessages,
        messageOrder: newMessageOrder,
        lastMessage: newLastMessage,
        lastActiveTime,
        offset,
    };
};

const receivedNewMessageReducer = (state: ChatListState, action: ReceiveNewMessage): ChatListState => {
    const chatInfo = state.chats[`${action.id}`];

    if (chatInfo === undefined) {
        return {
            ...state,
        };
    }

    const newLastActiveTime = action.lastActiveTime ? action.lastActiveTime : chatInfo.lastActiveTime;

    return {
        ...state,

        chats: {
            ...state.chats,
            [action.id]: {
                ...chatInfo,
                ...mergeMessages(
                    chatInfo,
                    receiveChatroomMessages(
                        action.id,
                        action.memberId,
                        {
                            // $FlowFixMe
                            [action.messageId]: action.message,
                        },
                        [action.messageId],
                        action.hint,
                        action.lastMessage,
                        newLastActiveTime,
                        false
                    )
                ),

                // Increment sent count if needed
                sentCount: ((chatInfo ? chatInfo.sentCount : 0) || 0) + (action.sentCountModifier || 0),

                // Increment total message count of this room
                totalMessages: ((chatInfo ? chatInfo.totalMessages : 0) || 0) + 1,
            },
        },
    };
};

const unreadMessageCountReducer = (chatInfo: ?ChatroomInfo, action: ReceiveChatroomInfo | PartialChatroomInfo) => {
    const unreadCount = getBadgeCount(action);

    if (unreadCount !== null) {
        if (unreadCount >= 0) {
            return unreadCount || 0;
        }

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn(`Got unexpected unread message count for room ${action.id}: ${unreadCount}`);
        } else {
            Sentry.captureException(`Invalid unread message count for room ${action.id}: ${unreadCount}`);
        }
    }

    if (chatInfo) {
        return getBadgeCount(chatInfo) || 0;
    }

    return 0;
};

/**
 * Returns seenByCount for the message and an updated read status map
 */
const messageSuccessReadReducer = (state: ReadStatusMap, action: MessageSendSuccess): [number, ReadStatusMap] => {
    const {
        newId,
        tmpId,
    } = action;

    if (!state[tmpId]) {
        return [
            0,
            state
        ];
    }

    // Also make sure we do not lose read statuses attached to temporary id
    const oldData = state[tmpId];

    // This ensures we do not lose any statuses if we receive the status before the message info
    const possibleIdData = state[`${newId}`] || {};

    const newState = { ...state, };
    delete newState[tmpId];
    newState[newId.toString()] = {
        ...oldData,
        ...possibleIdData,
    };

    const seenByCount = values(newState[newId.toString()]).filter((readTime: ?string) => !!readTime).length;
    return [seenByCount, newState];
};

const messageSuccessReducer = (chatInfo: ChatroomInfo, action: MessageSendSuccess): ChatroomInfo => {
    const { newId, tmpId, updateData, } = action;

    const [seenByCount, readStatus] = messageSuccessReadReducer(chatInfo.readStatus, action);

    // Create an updated version of the message data
    const msgInfo = {
        ...chatInfo.messages[tmpId],

        id: newId,

        // $FlowFixMe
        ...updateData,
        seenByCount,
        status: 'delivered',
    };

    // Delete the original from map
    const newMessages = { ...chatInfo.messages, };
    delete newMessages[tmpId];
    newMessages[newId.toString()] = msgInfo;

    // Update ordering map
    const newOrder = chatInfo.messageOrder.slice();
    newOrder.splice(newOrder.indexOf(tmpId), 1, newId);

    return {
        ...chatInfo,
        readStatus,

        messages: newMessages,
        messageOrder: newOrder,

        // Increment total message count of this room
        totalMessages: ((chatInfo ? chatInfo.totalMessages : 0) || 0) + 1,
    };
};

const setSingleMessageStatusReducer = (
    state: ChatroomInfo,
    memberId: number,
    messageId: number,
    statuses: MessageReadStatus,
    mode: 'replace' | 'extend'
): ChatroomInfo => {
    if (!state.messages[`${messageId}`]) {
        return {
            ...state,
        };
    }

    let { seenByCount, } = state.messages[`${messageId}`];
    let newStatuses;
    let lastMessageStatusReceived = state.lastMessageStatusReceived || null;

    if (mode === 'replace') {
        newStatuses = { ...statuses, };
        const statusKeys = keys(newStatuses);
        const statusCount = statusKeys.length;
        seenByCount = statusCount;

        if (statusCount > 0) {
            lastMessageStatusReceived = newStatuses[statusKeys[statusCount - 1]];
        }
    } else {
        newStatuses = { ...(state.readStatus[`${messageId}`] || {}), };

        const chatMemberIds = keys(statuses);

        chatMemberIds.forEach((member: string) => {
            // If message status does not exist locally, increment local seenByCount
            if (!newStatuses[member]) {
                seenByCount += 1;
            }

            newStatuses[member] = statuses[member];
        });

        if (chatMemberIds.length > 0) {
            lastMessageStatusReceived = newStatuses[chatMemberIds[chatMemberIds.length - 1]];
        }
    }

    // TXP-1999 - seenByCurrentUser was only being set if the new status was for the current user
    //            but own messages should also be set as seenByCurrentUser
    const { senderId, } = state.messages[messageId.toString()];
    const seenByCurrent = (memberId === senderId) || !!newStatuses[`${memberId}`];

    return (
        {
            ...state,

            lastMessageStatusReceived,
            messages: {
                ...state.messages,

                [`${messageId}`]: {
                    ...(state.messages[`${messageId}`] || {}),

                    seenByCount,
                    seenByCurrentUser: seenByCurrent,
                },
            },

            readStatus: {
                ...state.readStatus,

                [`${messageId}`]: newStatuses,
            },
        }
    );
};

const setMessageStatusesReducer = (state: ChatListState, action: BulkUpdateMessageStatus): ChatListState => {
    const { memberId, statuses, } = action;

    const resState = {
        ...state,

        chats: {
            ...state.chats,

            [action.chatId]: {
                ...state.chats[`${action.chatId}`],

                readCount: state.chats[`${action.chatId}`].readCount + action.newStatusCount + action.updatedStatusCount,
                clearedCount: state.chats[`${action.chatId}`].clearedCount - action.updatedStatusCount,
            },
        },
    };

    keys(statuses).forEach((chatId: string) => {
        keys(statuses[chatId]).forEach((msgId: string) => {
            const messageIdInt = parseInt(msgId, 10);
            const messageStatuses = statuses[chatId][msgId];
            resState.chats[chatId] = setSingleMessageStatusReducer(
                resState.chats[chatId], memberId, messageIdInt, messageStatuses, action.mode
            );
        });
    });

    return resState;
};

const updatePinnedChatroomsReducer = (state: ChatListState, action: UpdatePinnedChatrooms): ChatListState => {
    const { pinnedChatrooms, } = action;

    const resState = {
        ...state,

        chats: {
            ...state.chats,
        },
    };

    if (pinnedChatrooms) {
        keys(pinnedChatrooms).forEach((chatId: string) => {
            resState.chats[chatId] = {
                ...(resState.chats[chatId]),
                pinNumber: pinnedChatrooms[chatId],
            };
        });
    }

    return resState;
};

const removeChatroomReducer = (state: ChatListState, action: RemoveChatroom): ChatListState => {
    const chats = { ...state.chats, };
    delete chats[`${action.chatId}`];
    const activeChatId = action.chatId === state.activeChatId ? null : state.activeChatId;
    const derivedOrder = Object.keys(chats).map(Number);

    return {
        ...state,
        order: derivedOrder,
        chats,
        activeChatId,
    };
};

const setLocalPathReducer = (state: ChatListState, action: SetLocalPath): ChatListState => {
    const {
        chatId,
        messageId,
        localPath,
    } = action;

    if (chatId !== state.activeChatId) {
        return {
            ...state,
        };
    }

    let resState = {
        ...state,

        chats: {
            ...state.chats,
        },
    };

    const chat = { ...state.chats[`${chatId}`], };

    if (chat.messages && chat.messages[`${messageId}`] && !chat.messages[`${messageId}`].localPath) {
        resState = {
            ...state,

            chats: {
                ...state.chats,

                [`${chatId}`]: {
                    ...state.chats[`${chatId}`],

                    messages: {
                        ...state.chats[`${chatId}`].messages,

                        [`${messageId}`]: {
                            ...state.chats[`${chatId}`].messages[`${messageId}`],

                            localPath,
                        },
                    },
                },
            },
        };
    }

    if (chat.files && chat.files[`${messageId}`] && !chat.files[`${messageId}`].localPath) {
        resState = {
            ...state,

            chats: {
                ...state.chats,

                [`${chatId}`]: {
                    ...state.chats[`${chatId}`],

                    files: {
                        ...state.chats[`${chatId}`].files,

                        [`${messageId}`]: {
                            ...state.chats[`${chatId}`].files[`${messageId}`],

                            localPath,
                        },
                    },
                },
            },
        };
    }

    return resState;
};

const reducer = (state: ChatListState = initialState, action: Action): ChatListState => {
    // wrap the whole reducer to catch any unexpected exceptions and record them
    // Another component will need to act upon these and potentially send them to Sentry
    try {
        return innerReducer(state, action);
    } catch (err) {
        const reducerErrors = state.reducerErrors.slice();
        const uuid = createUuid(0);
        reducerErrors.push(getReducerError(uuid, 'chatList', action, err));
        return {
            ...state,

            reducerErrors,
        };
    }
};

const innerReducer = (state: ChatListState = initialState, action: Action): ChatListState => {
    switch (action.type) {
        case 'Application/RESET_ERROR':
            return resetErrorState(state, action);

        // Ensure we reset connection state when app resumes
        case 'persist/REHYDRATE':
            return {
                ...state,
                ...(action.payload || {}).chatList,

                socketStatus: 'unknown',
                socketError: null,
            };

        // On refresh/reconnect reset most things
        case 'ChatList/RESET_DATA':
            return {
                ...state,

                order: [],
                chats: {},
                isTyping: false,
                filter: '',
                offerFilter: '',
                organizationFilter: '',
                peopleFilter: '',
                avatars: {},
                searchResults: {},
                selectedResult: {},
                scrolledToSelectedResult: false,
                deepSearchFilter: '',
            };

        case 'Application/RESET_DATA':
        case 'Auth/LOGOUT':
            // Reset the store when logging out
            return {
                ...initialState,
            };

        case 'ChatList/REMOVE_MEMBER_AVATAR': {
            const newAvatars = { ...state.avatars, };
            delete newAvatars[action.member];
            return {
                ...state,

                avatars: newAvatars,
            };
        }

        case 'ChatList/RECEIVE_ROOM_INFO': {
            const unreadCount = unreadMessageCountReducer(state.chats[`${action.id}`], action);
            const currentCreateDate = state.chats[`${action.id}`] ? state.chats[`${action.id}`].createDate : null;

            // Start where the user left off
            const offset = unreadCount > CHAT_PAGE_SIZE
                ? Math.floor(unreadCount / CHAT_PAGE_SIZE) * CHAT_PAGE_SIZE
                : 0;

            const newOrder = state.order.slice(0);
            // add to the order if not already there
            if (!newOrder.includes(action.id)) newOrder.push(action.id);

            return {
                ...state,
                order: newOrder,
                chats: {
                    ...state.chats,

                    [action.id]: {
                        status: 'unknown',
                        doNotDisturb: false,

                        memberOrder: [],
                        members: {},
                        messageOrder: [],
                        messages: {},
                        readStatus: {},
                        lastFailedId: undefined,
                        notificationSound: 'default',

                        pinNumber: action.pinNumber || null,
                        membersTyping: [],

                        // $FlowFixMe
                        ...state.chats[`${action.id}`],

                        id: action.id,
                        name: action.name,
                        description: action.description,
                        chatroomType: action.chatroomType,
                        managed: action.managed,
                        creatorId: action.creatorId,
                        lastMessage: action.lastMessage,
                        lastUpdateTime: action.lastUpdateTime || null,
                        lastActiveTime: action.lastActiveTime || null,
                        memberCount: action.memberCount || 0,

                        totalMessages: action.totalMessages || 0,

                        offset,
                        sentCount: hasValue(action.sentCount)
                            ? action.sentCount
                            : ((state.chats[`${action.id}`] || {}).sentCount || 0),
                        readCount: hasValue(action.readCount)
                            ? action.readCount
                            : ((state.chats[`${action.id}`] || {}).readCount || 0),
                        clearedCount: hasValue(action.clearedCount)
                            ? action.clearedCount
                            : ((state.chats[`${action.id}`] || {}).clearedCount || 0),
                        donorId: action.donorId,
                        organId: action.organId,
                        targetOrgId: action.targetOrgId,
                        followerId: action.followerId || null,
                        // Sometimes we're only updating some info, so default to current create date if one isn't provided
                        createDate: action.createDate || currentCreateDate,
                        caseName: action.caseName,
                        followerName: action.followerName,
                    },
                },
            };
        }

        case 'ChatList/BULK_RECEIVE_ROOMS': {
            const newRooms = {};

            action.chatrooms.forEach((roomInfo) => {
                const unreadCount = unreadMessageCountReducer(state.chats[`${roomInfo.id}`], roomInfo);

                // Start where the user left off
                const offset = unreadCount > CHAT_PAGE_SIZE
                    ? Math.floor(unreadCount / CHAT_PAGE_SIZE) * CHAT_PAGE_SIZE
                    : 0;

                newRooms[`${roomInfo.id}`] = {
                    status: 'unknown',
                    memberOrder: [],
                    members: {},
                    messageOrder: [],
                    messages: {},
                    readStatus: {},

                    // $FlowFixMe
                    ...state.chats[`${roomInfo.id}`],

                    id: roomInfo.id,
                    name: roomInfo.name,
                    description: roomInfo.description,
                    chatroomType: roomInfo.chatroomType,
                    managed: roomInfo.managed,
                    creatorId: roomInfo.creatorId ? roomInfo.creatorId : (state.chats[`${roomInfo.id}`] || {}).creatorId,
                    lastMessage: roomInfo.lastMessage,
                    lastUpdateTime: roomInfo.lastUpdateTime || null,
                    lastActiveTime: roomInfo.lastActiveTime || null,
                    doNotDisturb: roomInfo.doNotDisturb,
                    pinNumber: roomInfo.pinNumber,
                    membersTyping: [],
                    memberCount: roomInfo.memberCount || 0,
                    totalMessages: roomInfo.totalMessages || 0,
                    sentCount: hasValue(roomInfo.sentCount)
                        ? roomInfo.sentCount
                        : ((state.chats[`${roomInfo.id}`] || {}).sentCount || 0),
                    clearedCount: hasValue(roomInfo.clearedCount)
                        ? roomInfo.clearedCount
                        : ((state.chats[`${roomInfo.id}`] || {}).clearedCount || 0),
                    readCount: hasValue(roomInfo.readCount)
                        ? roomInfo.readCount
                        : ((state.chats[`${roomInfo.id}`] || {}).readCount || 0),

                    offset,
                    donorId: roomInfo.donorId,
                    organId: roomInfo.organId,
                    organType: roomInfo.organType,
                    targetOrgId: roomInfo.targetOrgId,
                    createDate: roomInfo.createDate,
                    donorClosed: roomInfo.donorClosed,
                    followerId: roomInfo.followerId,
                    caseName: roomInfo.caseName,
                    followerName: roomInfo.followerName,
                };
            });

            const fullChatList = {
                ...state.chats,
                ...newRooms,
            };
            const newOrder = Object.keys(fullChatList).map(Number);

            return {
                ...state,

                chats: fullChatList,
                order: newOrder,
            };
        }

        case 'ChatList/UPDATE_CHATROOM_INFO': {
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [action.id]: {
                        ...state.chats[`${action.id}`],

                        name: action.name || (state.chats[`${action.id}`] || {}).name || '$no-name$',
                        // eslint-disable-next-line max-len
                        description: action.description || (state.chats[`${action.id}`] || {}).description || 'No description for chatroom.',
                        managed: (action.managed === undefined ? (state.chats[`${action.id}`] || {}).managed : action.managed) || false,
                        lastUpdateTime: action.lastUpdateTime || (state.chats[`${action.id}`] || {}).lastUpdateTime || null,
                    },
                },
            };
        }

        case 'ChatList/REMOVE': {
            return removeChatroomReducer(state, action);
        }

        case 'ChatList/REMOVE_MEMBER_FROM_ROOM':
        case 'ChatList/MEMBER_REMOVED': {
            const newMembers = {
                ...state.chats[`${action.chatId}`].members,

                [`${action.memberId}`]: {
                    ...state.chats[`${action.chatId}`].members[`${action.memberId}`],

                    membershipStatus: action.leftChat ? 'Left' : 'Removed',
                },
            };

            const newMemberCount = action.memberCount
                || keys(newMembers).filter((memberId) => newMembers[memberId].membershipStatus === 'Present').length;

            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        members: newMembers,
                        memberCount: newMemberCount,
                    },
                },
            };
        }

        case 'ChatList/RECEIVE_ROOM_MEMBERS': {
            const memberOrder = action.hint === 'replace' ? action.memberOrder : state.chats[`${action.id}`].memberOrder.slice();

            if (action.hint === 'extend') {
                action.memberOrder.forEach((memberId) => {
                    if (memberOrder.indexOf(memberId) === -1) {
                        memberOrder.push(memberId);
                    }
                });
            }

            const actionMembers = action.members;
            const myId = action.memberId;
            const members = action.hint === 'replace'
                ? actionMembers
                : { ...state.chats[`${action.id}`].members, };
            const memberNames = [];

            let { notificationSound, } = state.chats[`${action.id}`];

            keys(actionMembers).forEach((memberId) => {
                if (`${memberId}` === `${myId}`) {
                    notificationSound = (hasValue(actionMembers[`${memberId}`].notificationSound)
                        ? actionMembers[`${memberId}`].notificationSound
                        : (members[`${memberId}`] || {}).notificationSound) || NotificationSoundMap.default;
                }

                members[`${memberId}`] = {
                    ...members[`${memberId}`],
                    ...actionMembers[`${memberId}`],

                    notificationSound,
                };
                if (members[`${memberId}`].membershipStatus === 'Present') {
                    memberNames.push(selectProfileName(members[`${memberId}`].profile));
                }
            });

            const newMemberCount = keys(members)
                .filter((memberId) => members[memberId]
                    && (members[memberId].membershipStatus === 'Present')).length;

            return {
                ...state,
                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[`${action.id}`],

                        notificationSound,

                        memberOrder,
                        members,
                        memberCount: newMemberCount,
                        memberNames,
                    },
                },
            };
        }

        case 'ChatList/BULK_RECEIVE_MEMBERS': {
            const updatedRooms = {};

            const memberNames = [];

            action.rooms.forEach((roomMembers) => {
                const theMembers = roomMembers.members;

                for (let i = 0; i < keys(theMembers).length; i += 1) {
                    const memberId = keys(theMembers)[i];

                    if (theMembers[`${memberId}`].membershipStatus === 'Present') {
                        memberNames.push(selectProfileName(theMembers[`${memberId}`].profile));
                    }
                }

                updatedRooms[`${roomMembers.id}`] = {
                    ...state.chats[`${roomMembers.id}`],

                    memberOrder: roomMembers.memberOrder,
                    members: theMembers,
                    memberNames,
                };
            });

            return {
                ...state,

                chats: {
                    ...state.chats,

                    ...updatedRooms,
                },
            };
        }

        case 'ChatList/RECEIVE_MEMBER_AVATARS':
            return {
                ...state,

                avatars: action.avatars,
            };

        case 'ChatList/RECEIVE_ROOM_MESSAGES':
            return {
                ...state,

                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[`${action.id}`],
                        ...mergeMessages(state.chats[`${action.id}`], action),
                    },
                },
            };

        case 'ChatList/RECEIVE_NEW_MESSAGE':
            return receivedNewMessageReducer(state, action);

        case 'ChatList/MESSAGE_SUCCESS':
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: messageSuccessReducer(state.chats[`${action.chatId}`], action),
                },
            };

        case 'ChatList/MESSAGE_SEND_STATUS':
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        lastFailedId: (action.status === 'failed' ? `${action.messageId}` : undefined)
                        || (state.chats[`${action.chatId}`] || {}).lastFailedId,

                        messages: {
                            ...state.chats[`${action.chatId}`].messages,

                            [`${action.messageId}`]: {
                                ...state.chats[`${action.chatId}`].messages[`${action.messageId}`],

                                status: action.status,
                            },
                        },
                    },
                },
            };

        // Note: Reducer always replaces local files
        case 'ChatList/RECEIVE_ROOM_FILES':
            return {
                ...state,

                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[`${action.id}`],

                        files: action.fileMap,
                        allFiles: action.allFiles,
                        myFiles: action.myFiles,
                    },
                },
            };

        case 'ChatList/SET_SELECTED_FILE':
            return {
                ...state,

                chats: {
                    ...state.chats,
                    [action.id]: {
                        ...state.chats[`${action.id}`],

                        selectedFile: action.selectedFile,
                    },
                },
            };

        case 'ChatList/SET_LOCAL_PATH':
            return setLocalPathReducer(state, action);

        case 'ChatList/BULK_UPDATE_MESSAGE_STATUS':
            return setMessageStatusesReducer(state, action);

        case 'ChatList/SOCKET_STATUS': {
            return {
                ...state,

                socketStatus: action.status,
                socketError: action.error,
            };
        }

        case 'ChatEdit/SUCCESS':
        case 'ChatList/OPEN_CHATROOM': {
            return {
                ...state,

                activeChatId: action.chatId,
            };
        }

        case 'ChatList/CLOSE_CHATROOM': {
            const activeChat = state.activeChatId;

            return {
                ...state,

                activeChatId: activeChat === action.chatId ? null : activeChat,
            };
        }

        case 'ChatList/SET_PAGE_OFFSET': {
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        offset: action.offset,
                    },
                },
            };
        }

        case 'ChatList/UPDATE_DONOR_ID': {
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        donorId: action.donorId,
                    },
                },
            };
        }

        case 'ChatList/UPDATE_ORGAN_ID': {
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        organId: action.organId,
                    },
                },
            };
        }

        case 'ChatList/FILTER': {
            const currentSearchResults = state.searchResults;
            const currentSelectedResult = state.selectedResult;
            const currentDeepSearchFilter = state.deepSearchFilter;
            return {
                ...state,

                filter: action.filter,
                searchResults: action.filter === '' ? {} : currentSearchResults,
                selectedResult: action.filter === '' ? {} : currentSelectedResult,
                deepSearchFilter: action.filter === '' ? '' : currentDeepSearchFilter,
            };
        }

        case 'ChatList/FILTER_OFFER': {
            return {
                ...state,

                offerFilter: action.offerFilter,
            };
        }

        case 'ChatList/FILTER_ORGANIZATION': {
            return {
                ...state,

                organizationFilter: action.organizationFilter,
            };
        }

        case 'ChatList/FILTER_PEOPLE': {
            return {
                ...state,

                peopleFilter: action.peopleFilter,
            };
        }

        case 'ChatList/RESET_FILTERS': {
            return {
                ...state,

                offerFilter: '',
                organizationFilter: '',
                peopleFilter: '',
            };
        }

        case 'ChatList/RECEIVE_ACK': {
            // Defensively update the ack if it is in state (meaning the room has been opened or received messages)
            if (!state.chats
                || !state.chats[`${action.chatId}`]
                || !state.chats[`${action.chatId}`].messages
                || !state.chats[`${action.chatId}`].messages[`${action.messageId}`]) {
                return state;
            }

            const newAck = {
                chatId: action.chatId,
                messageId: action.messageId,
                memberId: action.memberId,
                symbol: action.symbol,
            };
            return {
                ...state,
                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        messages: {
                            ...state.chats[`${action.chatId}`].messages,

                            [`${action.messageId}`]: {
                                ...state.chats[`${action.chatId}`].messages[`${action.messageId}`],

                                ack: {
                                    ...state.chats[`${action.chatId}`].messages[`${action.messageId}`].ack,

                                    [`${action.memberId}`]: newAck,

                                },
                            },
                        },
                    },
                },
            };
        }

        case 'ChatList/START_TYPING': {
            return {
                ...state,

                isTyping: true,
            };
        }

        case 'ChatList/STOP_TYPING': {
            return {
                ...state,

                isTyping: false,
            };
        }

        case 'ChatList/MEMBER_STARTED_TYPING': {
            // Use slice to create a new array - or else React may not 'react' to the change in the array content
            const currentMembersTyping = state.chats[`${action.chatId}`].membersTyping.slice() || [];

            // if action.memberId is not already in membersTyping array
            if (!currentMembersTyping.includes(action.memberId)) {
                currentMembersTyping.push(action.memberId);
                return {
                    ...state,
                    chats: {
                        ...state.chats,

                        [`${action.chatId}`]: {
                            ...state.chats[`${action.chatId}`],

                            membersTyping: currentMembersTyping,
                        },
                    },
                };
            }
            return {
                ...state,
            };
        }

        case 'ChatList/MEMBER_STOPPED_TYPING': {
            // Use slice to create a new array - or else React may not 'react' to the change in the array content
            const currentMembersTyping = state.chats[`${action.chatId}`].membersTyping.slice() || [];
            const spliceIndex = currentMembersTyping.indexOf(action.memberId);

            if (spliceIndex !== -1) {
                currentMembersTyping.splice(spliceIndex, 1);
                return {
                    ...state,
                    chats: {
                        ...state.chats,

                        [`${action.chatId}`]: {
                            ...state.chats[`${action.chatId}`],

                            membersTyping: currentMembersTyping,
                        },
                    },
                };
            }
            return {
                ...state,
            };
        }

        case 'ChatList/PINNED_CHATROOM': {
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        pinNumber: action.pinNumber,
                    },
                },
            };
        }

        case 'ChatList/UNPINNED_CHATROOM': {
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        pinNumber: null,
                    },
                },
            };
        }

        case 'ChatList/UPDATE_PINNED_CHATROOMS':
            return updatePinnedChatroomsReducer(state, action);

        case 'ChatList/REDACT_SUCCESS':
            if (state.activeChatId !== action.chatId) {
                return state;
            }

            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        messages: {
                            ...state.chats[`${action.chatId}`].messages,

                            [`${action.messageId}`]: {
                                ...state.chats[`${action.chatId}`].messages[`${action.messageId}`],

                                redactTime: action.redactTime,
                            },
                        },
                    },
                },
            };

        case 'ChatList/UNREDACT_SUCCESS':
            if (state.activeChatId !== action.chatId) {
                return state;
            }

            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        messages: {
                            ...state.chats[`${action.chatId}`].messages,

                            [`${action.messageId}`]: {
                                ...state.chats[`${action.chatId}`].messages[`${action.messageId}`],

                                redactTime: null,
                            },
                        },
                    },
                },
            };

        case 'ChatList/DELETE_FILE_MESSAGE_SUCCESS': {
            const chat = state.chats[`${action.chatId}`];

            const { [`${action.messageId}`]: deletedMessage, ...messages } = chat.messages;

            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        messages: {
                            ...messages,
                        },

                        messageOrder: chat.messageOrder.filter((msgId) => msgId !== action.messageId),
                        totalMessages: chat.totalMessages - 1,
                    },
                },
            };
        }

        case 'ChatList/SET_CLEARED_COUNT': {
            return {
                ...state,

                chats: {
                    ...state.chats,

                    [`${action.chatId}`]: {
                        ...state.chats[`${action.chatId}`],

                        clearedCount: hasValue(action.newCount)
                            ? (state.chats[`${action.chatId}`] || {}).clearedCount + action.newCount
                            : (state.chats[`${action.chatId}`] || {}).clearedCount,
                    },
                },
            };
        }

        case 'ChatList/SEARCH_RESULTS': {
            return {
                ...state,

                searchResults: action.searchResults,
                deepSearchFilter: state.filter,
            };
        }

        case 'ChatList/SET_SELECTED_RESULT': {
            return {
                ...state,

                selectedResult: action.selectedResult,
                scrolledToSelectedResult: false,
            };
        }

        case 'ChatList/SET_SCROLLED_TO_SEARCH_RESULT': {
            return {
                ...state,
                scrolledToSelectedResult: action.scrolledToSelectedResult,
            };
        }

        default:
            return state;
    }
};

export default reducer;
