// @flow
import {
    BOOLEAN,
    KB_TYPE_EMAIL, KB_TYPE_NUMBER, KB_TYPE_PHONE,
    STRING,
    UI_TYPE_CHECK,
    UI_TYPE_SWITCH,
    UI_TYPE_TELEPHONE,
    UI_TYPE_TEXT,
    UI_TYPE_TIME,
    UI_TYPE_TXC_LIST,
} from '../Redux/Forms/FormTypes';

export type AppError = {
    message: string,
    label: string,
};

export const defaultOrgPermissions = {
    ALLOW_LOCAL_UPLOAD: true,
    ALLOW_DONOR_VIEW: false,
};

export type PersistRehydrate = { type: 'persist/REHYDRATE', payload: * };

export type ChatSocketStatus = 'unknown' | 'connecting' | 'connected' | 'error';
export type ChatSocketError = 'generic' | 'auth_failed';

export type ChatroomSort = 'DEFAULT' | 'ALPHABETICAL' | 'BADGE_COUNT' | 'MY_ACTIVE' | 'CREATE_DATE';

export type ChatroomFilter = {
    status: string,
    chatType: string
}
export type ProfileDonorSort = 'DEFAULT' | 'MOST_RECENT' | 'BY_STATUS_UPDATE' | 'BY_REF_ID' | 'BY_UNOS_ID';
export type DonorSortBy = 'DEFAULT' | 'NAME' | 'WORKFLOW' | 'CREATE_DATE' | 'LAST_ACTIVITY' | 'NEXT_DUE_DATE' | 'PROGRESS' | 'NOTES';
export type SortDirection = 'asc' | 'desc';

export type DonorFilter = {
    status: string,
    donorType: string,
};

export type ChatroomType = 'Standard' | 'Case' | 'Templated Case' | 'Donor' | 'CDS Offer' | 'Research Offer' | 'Donor Only' | 'Follower Group';

// NOTE: reflects database enum E_ACCESS_LEVEL
export const AccessLevels = Object.freeze({
    guest: 'Guest',
    basicUser: 'BasicUser',
    orgMember: 'OrgMember',
    orgManager: 'OrgManager',
    sales: 'Sales',
    sysAdmin: 'SysAdmin',
    archived: 'Archived',
});

export type UserProfile = {
    userId: number,
    phone: string,
    firstName: ?string,
    lastName: ?string,
    email: string,
    emailUpdate: ?string,
    isEmailVerified: boolean,
    profilePicture: ?string,
    organizationalRole: ?string,
    department: ?string,
    organizationId: ?number,
    organizationName: string,
    verifiedOrgMember: boolean,
    templatePreferences: any,
    clientPermissions: any,
    communicationPreferences: ?string,
    chatroomSort: ChatroomSort,
    chatroomFilter: ChatroomFilter,
    donorSort: ProfileDonorSort,
    donorFilter: DonorFilter,
    globalDoNotDisturbEnd: string,
    emailNotification: boolean,
    adminNotification: boolean,
    accessLevel: ?string,
    license: ?string;
    sentCount?: number,
    readCount?: number,
};

export type MemberProfile = {
    profile: UserProfile,
};

export type MemberProfileV2 = {
    firstName: ?string,
    lastName: ?string,
    email: string,
    phone: string,
    profilePicture: ?string,
    userId: number,
    lastInteractionTime: string,
    organizationName: string,
}

export type MemberProfileData = {
    profile: MemberProfileV2,
    membershipStatus: ?string,
}

export type RemoteMemberProfile = {
    user_id: number,
    phone: string,
    first_name: ?string,
    last_name: ?string,
    email: string,
    profile_picture: ?string,
    profile_picture_url: ?string,
    organizational_role: ?string,
    department: ?string,
    template_preferences: any,

    organization_id: ?number,
    organization_name: string,
    is_email_verified: boolean,

    client_permissions: any,
    communication_preferences: ?string,
    chatroom_sort: ChatroomSort,
    donor_sort: ?ProfileDonorSort,
    donor_filter: ?DonorFilter,
    global_do_not_disturb_end: string,
    email_notification: boolean,
    admin_notification: boolean,

    access_level: ?string,
    license: ?string,

    sent_count: 0,
    read_count: 0,
};

export type RemoteUserProfile = {
    user_id: number,
    access_level: ?string,
    license: ?string,
    phone: string,
    first_name: ?string,
    last_name: ?string,
    email: string,
    email_update: ?string,
    profile_picture: ?string,
    organizational_role: ?string,
    department: ?string,
    template_preferences: any,

    organization_id: ?number,
    organization_name: string,
    verified_organization_member: boolean,
    is_email_verified: boolean,

    client_permissions: any,
    communication_preferences: ?string,
    chatroom_sort: ChatroomSort,
    donor_sort: ?ProfileDonorSort,
    donor_filter: ?DonorFilter,
    global_do_not_disturb_end: string,
    email_notification: boolean,
    admin_notification: boolean,
    sent_count: number,
    read_count: number,
};

export type RemoteExternalUser = {
    external_user_id: number,
    full_name: string,
    phone: ?string,
    email: ?string,
    details: ?string,
    acin_data: ?any,
};

export type ExternalUser = {
    userId: number,
    fullName: string,
    phone: ?string,
    email: ?string,
    details: ?string,
    acinData: ?any,
};

export type PendingRemovedUser = {
    displayName: string,
    memberId: number,
    isExternalUser: boolean,
};

export type Team = {
    teamId: number,
    creatorId: number,
    teamName: string,
    shared: boolean,
    teamMembers: Array<UserProfile>,
};

export type LastMessage = {
    textContent: string,
    sentTime: string,
};

// When messageId is a number, it refers to a saved message
// When messageId is a string, it refers to the uuid of an unsaved message
export type MessageId = number | string;

export type MessageSendStatus = 'pending' | 'failed';

export const MessageTypes = Object.freeze({
    unknown: 0,
    text: 1,
    jpeg: 2,
    jpg: 3,
    png: 4,
    gif: 5,
    mp4: 6,
    pdf: 7,
    mov: 8,
    /* 9 - 16: reserved */
    addMember: 17,
    removeMember: 18,
    inviteUser: 19,
    uninviteUser: 20,
    joinByInvitation: 21,
    askAlanInvoke: 22,
    askAlanDonor: 23,
    inviteGuest: 24,
    removeGuest: 25,
    guestJoin: 26,
    guestLeave: 27,
    chatroomCreated: 28,
    askAlanRecipient: 29,
    askAlanCandidate: 30,
    askAlanVoid: 31,
    multipartMedia: 32,
    donorTask: 33,
    openCloseDonor: 34,
    callUser: 35,
    donorTaskExpiration: 36,
});

export type MediaDisplayType = 'chatItem' | 'avatar' | 'profilePicture' | 'fileList' | 'fileListFullSize';

export type MediaType = 'unknown' | 'video' | 'image' | 'application';

export type MessageType = $Values<typeof MessageTypes>;

export type MimeTypeString = 'image/png' | 'image/jpeg' | 'image/jpg' | 'image/gif' | 'video/mp4' | 'video/quicktime' | 'application/pdf';

export const ImageMessageTypes = [MessageTypes.jpeg, MessageTypes.jpg, MessageTypes.png, MessageTypes.gif];
export const VideoMessageTypes = [MessageTypes.mp4, MessageTypes.mov];
export const ApplicationMessageTypes = [MessageTypes.pdf];
export const SystemMessageTypes = [
    MessageTypes.addMember,
    MessageTypes.removeMember,
    MessageTypes.inviteUser,
    MessageTypes.uninviteUser,
    MessageTypes.joinByInvitation,
    MessageTypes.inviteGuest,
    MessageTypes.removeGuest,
    MessageTypes.guestJoin,
    MessageTypes.guestLeave,
    MessageTypes.chatroomCreated
];
export const AskAlanMessageTypes = [
    MessageTypes.askAlanInvoke,
    MessageTypes.askAlanDonor,
    MessageTypes.askAlanRecipient,
    MessageTypes.askAlanCandidate,
    MessageTypes.askAlanVoid
];

export const ImageMessageStrings = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
export const VideoMessageStrings = ['video/mp4', 'video/quicktime'];
export const ApplicationMessageStrings = ['application/pdf', 'text/xml'];

export type PostAttachmentData = {
    file_name: string,
    display_name: ?string;
    file_size: number,
    chat_type_id: number,
};

export type AttachmentData = {
    mime: MimeTypeString,
    size: number,
    path: string,
    // This was originally referring to the built in type 'File', but now would point to the local type 'File'.
    // If you leave it as File, yarn complains in three spots about the usage of a potentially undefined property name.
    file: *,
    fileName: string,
    displayName: ?string;
};

export type ChatTemporaryMessage = {
    text: string,
    attachments: Array<AttachmentData>,
    replyMessageId: ?number,
    mentionedUsers: Array<number>,
    mentionedNames: Array<string>,
    filteredNames: Array<string>,
};

export type ChatTemporaryMessageMap = {
    [string]: ChatTemporaryMessage,
    reducerErrors: Array<any>,
};

export type ChatroomFile = {
    id: MessageId,
    fileName: string,
    displayName: ?string,
    localPath: ?string,
    messageType: MessageType,
    size: number, // in bytes
    sentTime: string,
    memberId: number,
};

export type ChatroomFileMap = {
    [string]: ChatroomFile,
};

export type MessageReadStatus = {
    // memberId -> readTime
    [string]: string,
};

export type ReadStatusMap = {
    // chatId -> MessageReadStatus
    [string]: MessageReadStatus,
};

export type MessageAcknowledge = {
    chatId: number,
    messageId: number,
    memberId: number,
    symbol: string,
};

export type MessageAcknowledgeMap = {
    // memberId => ack obj
    [string]: MessageAcknowledge,
};

export type ChatroomMessage = {
    id: MessageId,
    senderId: number,
    senderName: string,
    sentTime: string,

    messageType: MessageType,

    textContent: string,
    dataContent: any,
    displayName: ?string,
    fileName: ?string,
    localPath: ?string,
    seenByCount: number,
    seenByCurrentUser: boolean,
    status: ?MessageSendStatus, // only set for unsent messages
    ack: MessageAcknowledgeMap,
    redactTime: ?string,
    replyingMessageId: ?number,
    replyingTextContent: ?string,
    replyingMessageType: ?MessageType,
    replyingDataContent: ?any,
    replyingFilePath: ?string,
    replyingSenderId: ?number,
    parentMessageId: ?number,
};

export type ChatroomMessageMap = {
    [string]: ChatroomMessage,
};

export type ChatMembershipStatus = 'Expired' | 'Invited' | 'Present' | 'Left' | 'Removed' | 'Archived' | 'Pending' | 'NonMember';

export type ChatroomMember = {
    profile: UserProfile,
    membershipStatus: ChatMembershipStatus,
    startDate: string,
    endDate: string,
    doNotDisturb: boolean,
    notificationSound: string,
};

export type ChatroomMemberMap = {
    [string]: ChatroomMember,
};

export type AvatarMap = {
    // member id -> path
    [number]: string,
};

export type DonorRestrictions = {
    DONOR_DETAILS: boolean,
    DONOR_TIMELINE: boolean,
    DONOR_FILES: boolean,
};

export type ChatroomInfo = {
    id: number,
    name: string,
    description: string,
    chatroomType: ChatroomType,
    creatorId: number,

    members: ChatroomMemberMap,
    memberOrder: Array<number>,
    memberCount: number,
    membersTyping: Array<number>,

    managed: boolean,

    pinNumber: ?number,

    readStatus: ReadStatusMap,

    // Timestamp of the last message status we received
    //  This helps us determine quite quickly whether we should re-render the UI or not
    lastMessageStatusReceived: ?string,
    messages: ChatroomMessageMap,
    messageOrder: Array<MessageId>,

    sentCount: number,
    readCount: number,
    clearedCount: number,

    lastMessage: ?LastMessage,
    lastUpdateTime: ?string,
    lastActiveTime: ?string,
    totalMessages: number,
    offset: number,

    files: ChatroomFileMap,
    allFiles: Array<MessageId>,
    myFiles: Array<MessageId>,
    selectedFile: ChatroomFile,

    // Id of the last failed message
    // used to detect if we need to re-render the chat screen
    lastFailedId: ?string,

    donorId: ?number,
    donorClosed: boolean,
    organId: ?number,
    organType: ?string,
    followerId: ?number,

    targetOrgId: ?number,

    // File name of the notification sound to use for this room
    notificationSound: string,

    doNotDisturb: boolean,
    createDate: string,
    caseName: ?string,
    followerName: ?string,
};

export type ChatroomInfoMap = {
    [string]: ChatroomInfo,
};

export type RemoteMessageStatus = {
    instant_message_status_id: number,
    instant_message_id: number,
    chatroom_id: number,
    member_id: number,
    read_time: string,
};

export type Offer = {
    donorId: number,
    unosId: string,
    organId: number,
    matchId: string,
    organType: string,
};

export type Organization = {
    id: number,
    code: string,
    name: string,
    templatePreferences: any,
    availableTags: Array<Tag>,
};

export type SearchResult = {
    instantMessage: ChatroomMessage,
    messagePosition: number,
};

export type SearchResultMap = {
    // chat id -> SearchResult
    [string]: Array<SearchResult>,
};

export type ResendNotificationCache = {
    [MessageId]: Array<number>,
};

export type EventName =
    | 'open-create-chatroom-click'
    | 'close-create-chatroom-click'
    | 'menu-click'
    | 'open-profile-click'
    | 'open-settings-click'
    | 'open-help-click'
    | 'logout-click'
    | 'arrow-click'
    | 'bell-click'
    | 'refresh-click'
    | 'send-message-click'
    | 'search-focus'
    | 'files-click'
    | 'notification-sounds-click'
    | 'toggle-mute-click'
    | 'leave-group-click'
    | 'emoji-picker-click'
    | 'ask-alan-click'
    | 'chatroom-details-click'
    | 'sort-menu-click'
    | 'filter-menu-click'
    | 'add-people-click'
    | 'upload-media-click'
    | 'upload-offer-click';

export type OrganType = 'Kidneys Enbloc' | 'Right Kidney' | 'Left Kidney'
    | 'Liver' | 'Liver Split Left' | 'Liver Split Right'
    | 'Left Lung' | 'Right Lung' | 'Lungs Enbloc'
    | 'Pancreas' | 'Heart' | 'Intestine';

export type Organ = {
    organId: number,
    organType: OrganType,
};

export type File = {
    id: MessageId,
    fileName: string,
    displayName: ?string,
    messageType: MessageType,
    size: number,
    sentTime: string,
    memberId: number,
    localPath: ?string,
};

export type FileMap = {
    [string | number]: File,
};

export type FormValueMap = {
    // fieldId -> value
    [number]: {
        value: any,
    },
};

export type FormData = {
    // formId -> FormValueMap
    [string]: FormValueMap,
};

export type FormErrorMap = {
    [number]: string,
};

export type FormErrors = {
    [string]: FormErrorMap,
};

export type FormDefField = {
    id: number,
    title: string,
    type: typeof BOOLEAN | typeof STRING,
    uiType: typeof UI_TYPE_TEXT | typeof UI_TYPE_SWITCH | typeof UI_TYPE_CHECK | typeof UI_TYPE_TXC_LIST | typeof UI_TYPE_TIME | typeof UI_TYPE_TELEPHONE,
    kbType?: typeof KB_TYPE_EMAIL | typeof KB_TYPE_NUMBER | typeof KB_TYPE_PHONE,
    behavior?: string,
    responseOptions?: Array<string>,
    tags: Array<string>,
    maxLines?: number,
    maxLength?: number,
    mandatory?: boolean,
    fieldName?: string,
    displayAs?: string,
    displayOnItem?: boolean,
    isTime?: boolean,
    isDate?: Boolean,
    defaultValue?: string,
    pattern?: string,
    helperText?: string,
    flightTracker?: boolean,
    emailDetails?: boolean,
    referToTaskId?: number,
    referToFieldId?: number,
};

export type FormDefSection = {
    id: number,
    title: string,
    open?: boolean,
    fields: Array<FormDefField>,
};

export type FormDefBehaviors = {
    alwaysApplicable?: boolean,
    lockWhenFinalized?: boolean,
    saveOnComplete?: boolean,
    clearOnIncomplete?: boolean,
    clearOnNotApplicable?: boolean,
};

export type FormDefData = {
    formId: string,
    behaviors?: FormDefBehaviors,
    disableBehavior?: any,
    toolbar?: any,
    sections: Array<FormDefSection>,
};

export type FormDefDataMap = {
    // formId -> FormDefData
    [string]: FormDefData,
};

export type Task = {
    taskId: number,
    completed: boolean,
    notApplicable: boolean,
    hasNoteContent: boolean,
    lastModified: string,
    hasTaskData: boolean,
    dueDateInterval: number,
    previousTaskId: number,
    dueDate: string,
    userCanRead: boolean,
};

export type ApiResponseTask = {
    section: string;
    section_position: number;
    description: string;
    form_id: string;
    high_risk: boolean;
};

export type SubscriberDetails = {
    phone: string,
    name: string,
    profilePicture: ?string,
    organizationId: number,
};

export type Subscriber = {
    subscriptionId: number,
    notifiableGroupId: number,
    targetId: number,
    unsubscribed: boolean,
    unsubscriberId: number,
    subscriberId: number,
    subscriberType: string,
    subscriberInformation: SubscriberDetails,
};

export type FollowerGroup = {
    followerGroupId: number,
    name: string,
    canUpdate: boolean,
    externalUsers: Array<ExternalUser>,
    memberUsers: Array<UserProfile>,
    tasks: Array<number>,
};

export type FollowerGroupMap = {
    // followerId -> follower_data
    [number]: FollowerGroup,
};

export type FollowerPreferences = {
    name: string,
    members: Array<number>,
    teams: Array<number>,
    tasks: Array<number>,
    canEdit: boolean,
};

export type CasePreferences = {
    followers: Array<FollowerPreferences>,
};

export type DonorType = 'Local' | 'Import';

export type TaskData = {
    // fieldId -> task_data
    [number]: any,
};

export type DonorDataEntry = {
    key: string,
    value: string,
    label: string,
};

export type DonorImportField = {
    id: string,
    label: string,
    matcher?: string, // used to be RegExp, should be defined if not 'computed'
    computed?: true, // this signifies that this field isn't directly parsed from the PDF, but rather a conditional aggregate of parsed data,
    // each computed field will be handled a little differently when parsed
    mappingOnly?: boolean, // indicates this field should only be available for mapping and not parsed in import
    matchIndex?: number,
    multipleLines?: true, // true if the 'matcher' can exist on multiple lines, and we want to join those lines together into one field
    joiner?: string, // the joiner to combine the multiple lines, if 'multipleLines' is true
    lab?: true, // labs are calculated to get admit, current, peak and count of measurements
    vital?: true, // vitals are calculated to get current and count of measurements, can also take an additional regex to match pattern of measurement
    processRegex?: string, // is used as a RegExp
    workflowField: boolean, // indicates if this is a field we will allow to map into workflow task data
    subMatch?: {
        linesAway: number,
        matcher: string, // used to be RegExp
        untilMatch?: string, // used to be RegExp
        matchIndex: number,
    },
};

export type TaskDataMap = {
    // taskId -> TaskData
    [number]: ?TaskData,
};

export type History = {
    userId: number,
    updateTime: string,
    completed: ?boolean,
    notApplicable: ?boolean,
    noteContent: string,
};

export type DonorTask = {
    donorId: number,
    taskId: number,
    completed: boolean,
    notApplicable: boolean,
    noteContent: string,
    history: Array<History>,
    previousTaskId: ?number,
    dueDateInterval: ?number,
    dueDate: ?string,
    lastModified: string,
    lastModifiedBy: number,
};

export type Donor = {
    donorId: number,
    donorType: DonorType,
    opoDonorId: string,
    highRisk: boolean,
    unosId: string,
    userId: number, // user who created the donor
    orgId: number, // organization the donor belongs to
    createDate: string,
    organs: Array<Organ>,
    closed: boolean,
    files: FileMap,
    fileIds: Array<MessageId>,
    currentLocation?: string,
    dob?: string,
    race?: string,
    sex?: string,
    typeOfDeath?: string,
    causeOfDeath?: string,
    weight?: string,
    height?: string,
    unetLink?: string,
    tasks?: Array<Task>,
    taskData?: TaskDataMap, // stores any relevant task data that needs to be displayed on the task list
    pendingFiles: FileMap,
    lastModified: string,
    lastModifiedBy: number,
    lastModifiedTaskDate: ?string,
    lastModifiedTaskId: ?number,
    workflow: ?string,
    subscribers?: Array<Subscriber>,
    followerGroups?: FollowerGroupMap,
    tags: Array<Tag>,
    notes: Array<CaseNote>,
    isAdmin: boolean,
};

export type DonorMap = {
    // donorId -> Donor
    [number]: Donor,
};

export type StaticTask = {
    taskId: number,
    formId: ?string,
    details: {
        sectionPosition: ?number,
        section: string,
        description: string,
        highRisk: boolean,
    },
};

export type WorkflowTasks = {
    tasks: Array<StaticTask>,
    sectionOrder: Array<string>,
};

export type WorkflowTaskMap = {
    [string]: WorkflowTasks,
};

export type WorkflowAndForms = {
    workflow: Workflow;
    forms: FormReferenceData[];
};

export type CaseNote = {
    noteId: number,
    caseId: number,
    userId: number,
    createDate: string,
    note: string,
};

export type Target = {
    entityType: string,
    entityId: number,
    subEntityType: ?string,
    subEntityId: ?number,
    cascade: boolean,
};

export type Rights = {
    read: boolean,
    update: boolean,
    delete: boolean,
};

export type UserPermission = {
    target: Target,
    rights: Rights,
};

export type AuthorizedEntity = {
    authorizedType: string,
    authorizedId: number,
    displayName: string,
};

export type ResourcePermission = {
    permissionId: number,
    target: Target,
    rights: Rights,
    authorized: AuthorizedEntity,
};

export type ResourcePermissionMap = {
    // donorId or chatId -> ResourcePermissions
    [number]: Array<ResourcePermission>,
};

export type AllResourcePermissionMap = {
    // entityType ('Donor' or 'Chatroom') -> ResourcePermissionMap
    [string]: ResourcePermissionMap,
};

export type NotifiableEvent = {
    notifiableEventId: number,
    eventDescription: string,
    sequence: number,
    eventType: string,
    targetType: string,
    subtargetType: string,
    subtargetId: number,
};

export type Tracker = {
    id: number,
    title: string,
};

export type Workflow = {
    key: string,
    name: string,
    type: string,
    ownerId: number,
    ownerType: string,
    organizationType: string,
    flat: boolean,
    progressBar: boolean,
    tasks: Array<StaticTask>,
    sectionOrder: Array<string>,
    trackers: Array<Tracker>,
    lastModifiedDate: string,
    useTaskNavigation?: boolean,
    casePreferences?: CasePreferences,
};

export type FormReferenceData = {
    formId: string;
    name: string;
    sections: {
        id: number;
        title: string;
        fields: any[];
    }[];
    behaviors?: any;
    disableBehavior?: any;
};

export type ReferenceForm = {
    domain: "FORM";
    key: string;
    reference_data: FormReferenceData;
    comment?: string;
};

export type WorkflowTask = {
    id: number;
    description: string;
    formId: string;
    section?: string;
    highRisk?: boolean;
}

export type ReferenceWorkflow = {
    domain: "WORKFLOW";
    key: string;
    reference_data: {
        id: number;
        name: string;
        type: "Donor";
        ownerType: "Organization";
        organizationType: string;
        ownerId: number;
        tasks: WorkflowTask[];
        trackers: {
            id: number;
            title: string;
            events: {
                id: number;
                eventType: string;
                targetType: string;
            };
        }[];
        parentRefId?: number;
        version?: number;
        flat: boolean;
        progressBar: boolean;
    };
    comment?: string;
    last_modified_date: string;
};

export type WorkflowOption = {
    key: string,
    name: string,
};

export type RefDataSurvey = {
    key: string,
    name: string,
    type: string,
    ownerId: number,
    ownerType: string,
    organizationType: string,
    label: ?string,
    secondaryLabel?: string,
    passMark: number,
    questions: any,
    lastModifiedDate: string,
};

export type Tag = {
    tagId: number,
    category: string,
    label: string,
    displayColors: Array<string>,
    organizationId: number | null,
};

type ComparableItem = {
    value: string;
    comp: number;
};

export type CaseRow = {
    donorId: number;
    unosId: string;
    name: string;
    sex: 'M' | 'F' | 'U';
    age?: number;
    location: string;
    workflow: string;
    createdOn: ComparableItem;
    tags: Tag[];
    lastActivity: ComparableItem;
    nextDueDate: ComparableItem;
    tasksCompleted: number;
    tasksTotal: number;
    closed: boolean;
    highRisk: boolean;
    donor: Donor;
    lastNote: CaseNote | null;
    isAdmin: boolean;
};

export type LoadCasesParams = {
    status?: string,
    tags?: number[],
    workflows?: string[],
    riskCriteria?: boolean | null,
    search?: string,
    sortBy?: string,
    sortDirection?: string,
    page?: number,
    resultsPerPage?: number,
};
