// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import $ from 'jquery';
import LoadingIndicator from 'react-loading-indicator';
import { isEqualMinute } from 'txp-core';

import FontIcon from 'react-toolbox/lib/font_icon';
import ChatItem from './ChatItem';
import SplashScreen from './SplashScreen';
import ChatMessageStyles, { replyAlertHeight, replyAlertMarginTop, replyAlertVerticalPadding } from './Styles/ChatMessageStyles';
import type {
    AvatarMap,
    ChatroomMessage,
    ChatroomMemberMap,
    ChatroomInfo,
    ReadStatusMap,
    UserProfile,
    MessageId,
    SearchResult,
    ChatroomMember,
} from '../Utils/types';

import {
    SystemMessageTypes,
    ImageMessageTypes,
    VideoMessageTypes,
    ApplicationMessageTypes, MessageTypes,
} from '../Utils/types';
import CreateChatroom from './CreateChatroom';
import {
    openDonor as _openDonor,
} from '../Redux/DonorActions';
import {
    setScrolledToResult as _setScrolledToResult,
} from '../Redux/ChatListActions';
import type { ChatEditState } from '../Redux/ChatEditActions';
import type { ChatroomState } from '../Redux/ChatroomActions';
import UploadCDSOffer from './UploadCDS';

type Props = {
    accessToken: string,
    chatId: number,
    avatars: AvatarMap,
    selectedResult: SearchResult,
    scrolledToSelectedResult: boolean,
    messages: Array<ChatroomMessage>,
    totalMessages: number,
    memberId: number,
    members: ChatroomMemberMap,
    memberIds: Array<number>,
    selected: boolean,
    loadMoreMessages: boolean,
    isLoadingMessages: boolean,
    maxWidth: number,
    user: UserProfile,
    chatInputHeight: number,
    mediaHeight: number,
    replyHeight: number,
    readStatus: ReadStatusMap,
    createChatroomOpen: boolean,
    showCDSUpload: boolean,
    editData: ChatEditState,
    chatroomState: ChatroomState,
    deepSearchFilter: string,
    peopleFilter: string,
    isLeftSidebarOpen: boolean,
    room: ChatroomInfo,

    onTriggerAskAlan: () => *,
    paginate: () => *,
    onScroll: (event: any) => *,
    openMessageDetails: () => *,
    closeMessageDetails: () => *,
    sendAck: (emoji: string, messageId: MessageId) => *,
    filterPeople: (filter: string) => *,
    onCreateChatroom: () => *,
    setName: (name: string) => *,
    setDescription: (description: string) => *,
    addUsersToChatroom: () => *,
    onCancelButtonClick: () => *,
    selectV2Option: (option: string) => *,
    showMemberProfile: (selectedChatroomMember: ChatroomMember) => *,
    setScrolledToResult: (setScrolledToResult: boolean) => *,
    closeCDSUpload: () => *,
    onShowDonor: () => *,
    openDonor: (donorId: number) => *,
};

type State = {
    savedChatHistoryHeight: number,
    loadMoreClicked: boolean,
    // Value will be the messageId that the menu is opened for. If the menu is closed then the value is null
    showMessageMenu: ?number,
};

const REPLY_ALERT_HEIGHT = replyAlertHeight + replyAlertMarginTop + (replyAlertVerticalPadding * 2);

class ChatHistory extends Component<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            savedChatHistoryHeight: 0,
            loadMoreClicked: false,
            showMessageMenu: null,
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    // Don't re-render if the cause is simply clearing out the deepSearchFilter
    // This can causes a noticeable lag because it rerenders a potentially large chatroom
    shouldComponentUpdate(nextProps) {
        if (this.props.deepSearchFilter && !nextProps.deepSearchFilter) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps: Props) {
        const {
            selected,
            chatId,
            chatInputHeight,
            totalMessages,
            isLoadingMessages,
            selectedResult,
            scrolledToSelectedResult,
            setScrolledToResult,
            messages,
        } = this.props;

        const {
            loadMoreClicked,
        } = this.state;

        const STICKY_HEADER_OFFSET = 48;
        const chatHistory = document.getElementById('chatHistory');
        let distanceToBottom = 0;
        if (chatHistory) {
            distanceToBottom = (chatHistory.scrollHeight - chatHistory.scrollTop) - chatHistory.clientHeight;
        }

        let shouldScroll = false;
        const selectedSearchResult = (selectedResult && selectedResult.instantMessage);

        if (prevProps.selected !== selected) {
            // When a chat is initially selected.
            shouldScroll = true;
        } else if (prevProps.chatId !== chatId) {
            // When opening a different chatroom.
            shouldScroll = true;
        } else if (prevProps.chatInputHeight !== chatInputHeight && distanceToBottom < 100) {
            // When the chat input grows and the user is at (or almost at) the bottom of the screen.
            // This way if a user is already at the bottom of the chat history and the chat input grows the
            // messages will get pushed up. Otherwise the chat input will grow and the chat history won't adjust/scroll.
            shouldScroll = true;
        } else if (prevProps.totalMessages !== totalMessages && !loadMoreClicked) {
            // When the total number of messages changes.
            shouldScroll = true;
        } else if (prevProps.isLoadingMessages !== isLoadingMessages && !loadMoreClicked && !selectedSearchResult) {
            // When it's done loading and load more was not clicked and an item from search was not selected.
            shouldScroll = true;
        } else if (prevProps.isLoadingMessages && !isLoadingMessages && loadMoreClicked) {
            this.scrollToPreviousTopMessage();
        }

        if (selectedSearchResult && !scrolledToSelectedResult && !isLoadingMessages) {
            // If the selectedResult.instantMessage is the last message in the messages array
            // then skip the logic below and just scroll to the bottom
            if (messages.length > 0 && (selectedResult.instantMessage.id !== messages[messages.length - 1].id)) {
                shouldScroll = false;

                const message = document.getElementById(`message${selectedResult.instantMessage.id}`);
                if (message && chatHistory) {
                    message.scrollIntoView();
                    // Subtract both the chat input height AND the sticky header height
                    chatHistory.scrollTop -= (chatInputHeight + STICKY_HEADER_OFFSET);
                    setScrolledToResult(true);
                }
            } else {
                shouldScroll = true;
            }
        }

        if (shouldScroll) {
            this.scrollToBottom();
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    onLoadMoreMessagesClicked = () => {
        const {
            paginate,
        } = this.props;
        const chatHistory = document.getElementById('chatHistory');
        const savedChatHistoryHeight = (chatHistory) ? chatHistory.scrollHeight : 0;

        this.setState({
            savedChatHistoryHeight,
            loadMoreClicked: true,
        });

        paginate();
    };

    onShowMessageMenu = (messageId: number) => {
        this.setState({
            showMessageMenu: messageId,
        });
    };

    onHideMessageMenu = () => {
        this.setState({
            showMessageMenu: null,
        });
    };

    onShowCaseDetailsClick = () => {
        const {
            room,
            openDonor,
            onShowDonor,
        } = this.props;

        // eslint-disable-next-line no-console
        console.log('room', room);
        const donorId = room.donorId ? room.donorId : -1;
        openDonor(donorId);
        onShowDonor();
    };

    getShowSender = (item: ChatroomMessage, index: number) => {
        const {
            messages,
        } = this.props;

        let showSender = true;

        // failed messages always show sender
        if (item.status !== 'failed') {
            const leading = index > 0 ? messages[index - 1] : null;

            if (leading) {
                const isSystemMessage = SystemMessageTypes.includes(leading.messageType);
                const isMultipartMessage = leading.messageType === MessageTypes.multipartMedia && !leading.textContent;

                if (!isMultipartMessage && !isSystemMessage) {
                    // Hide sender info If previous message is by same author and at the same minute
                    if (leading && leading.senderId === item.senderId) {
                        if (isEqualMinute(leading.sentTime, item.sentTime)) {
                            showSender = false;
                        }
                    }
                }
            }
        }

        return showSender;
    };

    // eslint-disable-next-line react/sort-comp
    scrollToPreviousTopMessage = () => {
        const {
            savedChatHistoryHeight,
        } = this.state;

        const chatHistory = document.getElementById('chatHistory');
        this.setState({
            loadMoreClicked: false,
        });

        if (chatHistory) {
            const scrollToHeight = chatHistory.scrollHeight - savedChatHistoryHeight;
            chatHistory.scrollTop = scrollToHeight;
        }
    };

    handleKeyPress = (event: any) => {
        const {
            createChatroomOpen,
        } = this.props;

        if (!$('#chatInput').is(':focus') && !createChatroomOpen) {
            if (event.key === 'PageUp') {
                event.preventDefault();
                this.scrollHistoryByPage(-1);
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                this.scrollHistoryBy(-150);
            }
            if (event.key === 'PageDown') {
                event.preventDefault();
                this.scrollHistoryByPage(+1);
            }
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                this.scrollHistoryBy(+150);
            }
        }
    };

    scrollHistoryByPage = (pages: number) => {
        const element = document.getElementById('chatHistory');
        if (element) {
            const scrollAmount = (element.clientHeight - 150) * pages;
            element.scrollTop += scrollAmount;
        }
    };

    scrollHistoryBy = (scrollAmount: number) => {
        const element = document.getElementById('chatHistory');
        if (element) {
            element.scrollTop += scrollAmount;
        }
    };

    chatHistoryBottom: {|current: null | HTMLDivElement|} = React.createRef();

    scrollToBottom = () => {
        if (this.chatHistoryBottom && this.chatHistoryBottom.current) {
            this.chatHistoryBottom.current.scrollIntoView({ behavior: 'instant', });
        }
    };

    renderCreateChatroom() {
        const {
            memberId,
            avatars,
            chatInputHeight,
            onTriggerAskAlan,
            editData,
            chatroomState,
            peopleFilter,
            filterPeople,
            onCreateChatroom,
            setName,
            setDescription,
            addUsersToChatroom,
            onCancelButtonClick,
            selectV2Option,
        } = this.props;

        return (
            <CreateChatroom
                chatInputHeight={chatInputHeight}
                memberId={memberId}
                editData={editData}
                avatars={avatars}
                chatroomState={chatroomState}
                peopleFilter={peopleFilter}
                filterPeople={filterPeople}
                onCreateChatroom={onCreateChatroom}
                setName={setName}
                setDescription={setDescription}
                addUsersToChatroom={addUsersToChatroom}
                onCancelButtonClick={onCancelButtonClick}
                onTriggerAskAlan={onTriggerAskAlan}
                selectV2Option={selectV2Option}
            />
        );
    }

    renderCDSUpload() {
        const {
            chatInputHeight,
            closeCDSUpload,
        } = this.props;

        return (
            <UploadCDSOffer
                chatInputHeight={chatInputHeight}
                closeCDSUpload={closeCDSUpload}
            />
        );
    }

    renderChatHistory() {
        const {
            accessToken,
            memberId,
            avatars,
            chatId,
            messages,
            members,
            memberIds,
            maxWidth,
            loadMoreMessages,
            readStatus,
            user,
            deepSearchFilter,
            openMessageDetails,
            closeMessageDetails,
            sendAck,
            showMemberProfile,
            onShowDonor,
            room,
        } = this.props;

        const {
            showMessageMenu,
        } = this.state;

        // SMART IMAGE LOAD - As a trade-off between UX and performance we allow some non-visible images to be loaded
        // These parameters may need to be adjusted but initially we will allow up to 5 media messages
        // to be loaded provided they are within the 20 most recent messages
        const { paddingLeft, paddingRight, paddingTop, } = ChatMessageStyles.chatHistoryPadding;
        // Define a style object for the sticky header using the padding to adjust margins
        const stickyHeaderStyle = {
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 10,
            padding: '5px 10px',
            display: 'flex',
            alignItems: 'center',
            marginLeft: -paddingLeft,
            marginRight: -paddingRight,
            marginTop: -paddingTop,
            paddingLeft,
            paddingRight,
            borderBottom: '2px solid #EBEBEB',
            cursor: 'pointer',
            flexdirection: 'row',
            height: '48px',
            color: '#0069FF',
        };
        const headerIconStyle = {
            color: '#0069FF',
            fontSize: '20px',
            paddingBottom: '4px',
            // top: '-1px',
            // position: 'absolute',
        };
        const MAX_MEDIA_COUNT = 5;
        const MAX_MESSAGE_COUNT = 20;
        let preLoadFrom = '';
        let mediaCount = 0;
        let messageCount = 0;
        for (let index = messages.length - 1; (index > -1) && (mediaCount < MAX_MEDIA_COUNT) && (messageCount < MAX_MESSAGE_COUNT); index -= 1) {
            if (ImageMessageTypes.includes(messages[index].messageType)
                || VideoMessageTypes.includes(messages[index].messageType)
                || ApplicationMessageTypes.includes(messages[index].messageType)) {
                mediaCount += 1;
                messageCount += 1;
                preLoadFrom = messages[index].id.toString();
            }
        }
        const arrowIcon = 'arrow_forward';

        return (
            <div style={ChatMessageStyles.chatHistoryPadding}>
                {/* Sticky header */}
                {room && room.donorId && (
                    <div style={stickyHeaderStyle} onClick={this.onShowCaseDetailsClick} onKeyDown={this.onShowCaseDetailsClick} role="button" tabIndex={0}>
                        View Case Details
                        <button
                            className="arrowButton"
                            type="button"
                            tabIndex="0"
                            data-effect="solid"
                            data-place="bottom"
                        >
                            <FontIcon
                                style={headerIconStyle}
                                value={arrowIcon}
                                alt="Arrow"
                            />
                        </button>
                    </div>
                )}
                {loadMoreMessages && messages.length > 0 ? (
                    <Button
                        style={ChatMessageStyles.loadMoreMessagesButton}
                        type="button"
                        onClick={this.onLoadMoreMessagesClicked}
                        ripple={false}
                    >
                        Load more
                    </Button>
                ) : null}
                <div id="message">
                    {messages.map((message: ChatroomMessage) => (
                        <ChatItem
                            key={message.id}
                            message={message}
                            accessToken={accessToken}
                            memberId={memberId}
                            memberIds={memberIds}
                            chatId={chatId}
                            isMine={message.senderId === memberId}
                            sender={members[`${message.senderId}`]}
                            showSender={this.getShowSender(message, messages.indexOf(message))}
                            members={members}
                            avatar={avatars[message.senderId]}
                            avatars={avatars}
                            maxWidth={maxWidth}
                            user={user}
                            messageMenu={showMessageMenu === parseInt(message.id, 10)}
                            isLastMessage={messages.indexOf(message) === messages.length - 1}
                            deepSearchFilter={deepSearchFilter}
                            messageReadStatus={readStatus[(message.id).toString()] || null}
                            messageSendStatus={message.status}
                            preLoad={(message.id).toString() >= preLoadFrom}
                            openMessageDetails={openMessageDetails}
                            closeMessageDetails={closeMessageDetails}
                            sendAck={sendAck}
                            showMemberProfile={showMemberProfile}
                            showMessageMenu={this.onShowMessageMenu}
                            hideMessageMenu={this.onHideMessageMenu}
                            showDonorTask={onShowDonor}
                        />
                    ))}
                    <div ref={this.chatHistoryBottom} />
                </div>
            </div>
        );
    }

    renderSplashScreen = () => (
        <SplashScreen />
    );

    render() {
        const {
            selected,
            chatInputHeight,
            mediaHeight,
            replyHeight,
            createChatroomOpen,
            showCDSUpload,
            isLeftSidebarOpen,
            onScroll,
            isLoadingMessages,
        } = this.props;

        const totalChatInputHeight = chatInputHeight + mediaHeight + replyHeight;
        // If messages are being loaded there is no need to render the page yet
        if (isLoadingMessages) {
            return (
                <div
                    style={
                        {
                            ...ChatMessageStyles.chatHistoryWrapper,
                            ...ChatMessageStyles.loadingIndicator,
                            ...{ height: `calc(100vh - ${totalChatInputHeight}px)`, width: isLeftSidebarOpen ? '75vw' : '100vw', },
                        }
                    }
                >
                    <LoadingIndicator />
                </div>
            );
        }

        let renderComponent = this.renderSplashScreen();
        if (createChatroomOpen) {
            renderComponent = this.renderCreateChatroom();
        } else if (showCDSUpload) {
            renderComponent = this.renderCDSUpload();
        } else if (selected) {
            renderComponent = this.renderChatHistory();
        }

        return (
            <div
                style={
                    {
                        ...ChatMessageStyles.chatHistoryWrapper,
                        ...{ height: `calc(100vh - ${totalChatInputHeight}px)`, width: isLeftSidebarOpen ? '75vw' : '100vw', },
                    }
                }
                onScroll={(event) => onScroll(event)}
                id="chatHistory"
            >
                {renderComponent}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const chatId = state.chatList.activeChatId || 0;
    const chatMessage = chatId ? state.chatMessage[chatId] : null;
    const isReplyMessage = chatMessage && chatMessage.replyMessageId;

    return {
        chatId,
        avatars: state.chatList.avatars || {},
        scrolledToSelectedResult: state.chatList.scrolledToSelectedResult,
        chatInputHeight: state.chatMessage[chatId] && state.chatMessage[chatId].chatInputHeight
            ? state.chatMessage[chatId].chatInputHeight : 0,
        mediaHeight: state.chatMessage[chatId] && state.chatMessage[chatId].mediaHeight
            ? state.chatMessage[chatId].mediaHeight : 0,
        replyHeight: isReplyMessage ? REPLY_ALERT_HEIGHT : 0,
    };
};

export default connect(mapStateToProps, {
    setScrolledToResult: _setScrolledToResult,
    openDonor: _openDonor,
})(ChatHistory);
