// @flow
import type { Saga } from 'redux-saga';
import {
    call, put, select, take, cancel, fork, delay,
} from 'redux-saga/effects';
import { selectUserId, isoNow, selectProfileOrganizationId } from 'txp-core';
import type { SyncProfileInChatrooms } from 'txp-core';

import { parseMemberProfile } from '../Utils/profile';
import {
    MessageTypes,
} from '../Utils/types';
import type {
    AttachmentData,
    ChatroomInfo,
    ChatroomMemberMap,
    RemoteMemberProfile,
    MessageId,
    PostAttachmentData,
    ChatroomMessage,
    ChatroomFile,
    ChatroomFileMap,
    Organization,
} from '../Utils/types';
import { downloadAvatar } from '../Utils/downloadFile';
import { NotificationSoundMap } from '../Themes/Sounds';
import { pushError, setSagaMessage } from '../Redux/ApplicationActions';
import {
    finishLoading,
    startLoading,
    finishLoadingEntity,
    startLoadingEntity,
} from '../Redux/LoadingActions';
import type {
    AddedToChatroom,
    ClearChatroomNotifications,
    RemovedFromChatroom,
    MarkMessagesRead,
    LoadChatMembers,
    LoadMessageStatuses,
    AcknowledgeMessage,
    StartTyping,
    StopTyping,
    PinChatroom,
    UnpinChatroom,
    Search,
    OpenChatroom,
    LoadChatFiles,
    LoadMemberAvatars,
    RedactMessage,
    UnredactMessage,
    DeleteFileMessage,
} from '../Redux/ChatListActions';
import {
    CHAT_PAGE_SIZE,
    loadChatrooms,
    loadMessages,
    setOffset,
    receiveChatroomInfo,
    receiveChatroomMembers,
    receiveBulkChatroomMembers,
    receiveBulkChatroomInfo,
    receiveChatroomMessages,
    bulkUpdateMessageStatus,
    setMessageStatus,
    updateChatroomInfo,
    updateDonorId,
    updateOrganId,
    receiveAcknowledge,
    closeChatroom,
    setSearchResults,
    loadChatMembers,
    receiveChatroomFiles,
    receiveMemberAvatars,
    loadMemberAvatars,
    resetChatList, removeChatroom,
} from '../Redux/ChatListActions';
import type {
    UploadXML,
} from '../Redux/ChatMessageActions';
import {
    setMessageText,
    setMessageMedia,
    mediaUploadSuccess,
    setMentionedUsers,
    setMentionedNames,
    triggerAskAlan,
    uploadXMLSuccess,
    uploadXMLFailed, setMessageReply,
} from '../Redux/ChatMessageActions';
import type {
    SaveChat,
} from '../Redux/ChatEditActions';
import {
    saveChatFailed,
    setInitialMessage,
    setMentionedPendingUsers,
    setMentionedPendingNames,
    receiveOrganizations,
    saveChatSuccess,
    resetChatEditState,
} from '../Redux/ChatEditActions';
import { addUsersToChatroom, addSingleUserToChatroom } from '../Redux/ChatroomActions';
import { getTeams } from '../Redux/TeamActions';
import api from '../Services/Api';
import { apiFetch } from './ApiSaga';
import { isObject, keys } from '../Utils/Object';
import createUuid from '../Utils/createUuid';
import { sendCommandAndGetResult, sendMessage, sendCommandNoResult } from './Socket';
import isString from '../Utils/isString';
import hasValue from '../Utils/hasValue';
import { singleErrorMessage } from '../Utils/flattenError';
import { parseMessageStatuses, parseEmptyMessageStatus } from '../Utils/parseMessageStatuses';
import type { GetApplicationData } from '../Redux/ApplicationActions';
import { selectOtherProfileImage } from '../Redux/ChatMediaActions';
import { getPermissionsSaga } from './PermissionSaga';
import { getResourcePermissions } from '../Redux/PermissionActions';
import { ENTITY_TYPE_CHATROOM } from '../Utils/hasPermissions';
import { receiveAvailableTags } from '../Redux/DonorActions';
import { convertToOrganization } from '../Utils/organizations';

type ChatroomMembersResArray = [number, ChatroomMemberMap, Array<number>, Array<number>];

export function* getApplicationDataSaga(action: GetApplicationData): Saga<void> {
    yield put(startLoading('applicationData'));

    yield call(getPermissionsSaga);
    yield put(getTeams());

    yield put(resetChatList());

    const requestData = {
        getOrganizations: action.getOrganizations,
        getChatrooms: action.getChatrooms,
        getChatroomMembers: action.getChatroomMembers,
        getInstantMessages: action.getInstantMessages,
        chatroomIds: action.chatroomIds,
    };
    const memberId = yield select((state) => selectUserId(state.auth));
    const ack = yield call(sendCommandAndGetResult, undefined, memberId, 'get-application-data', [requestData]);

    if (!ack) {
        yield put(pushError('Failed to refresh data', undefined));
    } else if (ack && ack.error) {
        yield put(pushError('Failed to refresh data', ack.error));
    } else if (ack && ack.result) {
        const applicationData = ack.result;

        const receivedRoomInfos = [];
        const chatroomMemberInfoArray = [];
        const chatroomsMessagesArray = [];

        // Push the organizations to state.
        if (Array.isArray(applicationData.organizations)) {
            const organizationsArray: Organization[] = [];

            const orgId = yield select((state) => selectProfileOrganizationId(state.auth));

            for (let i = 0; i < applicationData.organizations.length; i += 1) {
                const organization = convertToOrganization(applicationData.organizations[i]);
                organizationsArray.push(organization);

                if (organization.id === orgId) {
                    yield put(receiveAvailableTags(organization.availableTags));
                }
            }

            yield put(receiveOrganizations(organizationsArray));
        }

        // Iterate over all of the returned chatrooms, for each chatroom
        // Add the returned members and messages to the chatroom info object.
        if (Array.isArray(applicationData.chatrooms)) {
            applicationData.chatrooms.forEach((chatroom) => {
                const chatroomId = chatroom.chatroom_id;
                receivedRoomInfos.push({
                    id: chatroom.chatroom_id,
                    name: chatroom.chatroom_name,
                    description: chatroom.chatroom_description,
                    chatroomType: chatroom.chatroom_type,
                    managed: chatroom.managed || false,
                    creatorId: chatroom.creator_id,
                    doNotDisturb: chatroom.do_not_disturb,
                    pinNumber: chatroom.pin_number,
                    memberCount: chatroom.member_count,
                    totalMessages: chatroom.total_message_count,
                    sentCount: chatroom.sent_count || 0,
                    readCount: chatroom.read_count || 0,
                    clearedCount: chatroom.cleared_count || 0,
                    lastMessage: chatroom.last_instant_message_time ? {
                        textContent: chatroom.last_instant_message_content,
                        sentTime: chatroom.last_instant_message_time,
                    } : undefined,
                    lastUpdateTime: chatroom.last_update_time,
                    lastActiveTime: chatroom.last_active_time || null,
                    donorId: chatroom.donor_id,
                    organId: chatroom.organ_id,
                    organType: chatroom.organ_type,
                    targetOrgId: chatroom.transplant_center,
                    createDate: chatroom.create_date,
                    donorClosed: chatroom.donor_closed,
                    followerId: chatroom.follower_id,
                    caseName: chatroom.case_name,
                    followerName: chatroom.follower_name,
                });

                const memberMap = {};
                const memberOrder = [];
                // Add chatroom members to the member map.
                if (applicationData.chatroom_members && Array.isArray(applicationData.chatroom_members[chatroomId])) {
                    applicationData.chatroom_members[chatroomId].forEach((member) => {
                        const profileData = parseMemberProfile(member.user_information);

                        memberMap[`${member.user_information.user_id}`] = {
                            profile: profileData,
                            membershipStatus: member.membership_status,
                            doNotDisturb: hasValue(member.do_not_disturb) ? !!member.do_not_disturb : undefined,
                            notificationSound: hasValue(member.alert_sound) ? member.alert_sound : undefined,
                            endDate: member.end_date,
                            startDate: member.start_date,
                        };

                        memberOrder.push(member.user_information.user_id);
                    });
                }

                if (memberOrder.length > 0) {
                    chatroomMemberInfoArray.push({
                        id: chatroomId,
                        members: memberMap,
                        memberOrder,
                    });
                }

                // Iterate over the instant messages to create the instant message map for the chatroom
                if (applicationData.instant_messages && Array.isArray(applicationData.instant_messages[chatroomId])) {
                    const messages: { [string]: ChatroomMessage } = {};
                    const messageOrder = [];

                    applicationData.instant_messages[chatroomId].forEach((message) => {
                        const instantMessageId = message.instant_message_id;

                        // Iterate over array of acknowledgements now to create the acknowledgment map.
                        const ackMap = {};
                        if (applicationData.acknowledgements && applicationData.acknowledgements[chatroomId]
                            && Array.isArray(applicationData.acknowledgements[chatroomId][instantMessageId])) {
                            applicationData.acknowledgements[chatroomId][instantMessageId].forEach((acknowledgement) => {
                                ackMap[acknowledgement.chatroom_member_id] = {
                                    chatId: acknowledgement.chatroom_id,
                                    memberId: acknowledgement.chatroom_member_id,
                                    messageId: acknowledgement.instant_message_id,
                                    // Convert the symbol into hex by removing 'U+' and adding '0x'
                                    symbol: `0x${acknowledgement.symbol.substring(2)}`,
                                };
                            });
                        }
                        messageOrder.unshift(instantMessageId);
                        messages[instantMessageId] = {
                            id: instantMessageId,
                            senderId: message.sender_id,
                            senderName: message.sender_name,
                            sentTime: message.sent_time,
                            messageType: message.chat_type_id,
                            textContent: message.text_content,
                            dataContent: message.data_content,
                            fileName: message.file_name,
                            status: undefined,
                            ack: ackMap,
                            seenByCount: message.read_count || 0,
                            seenByCurrentUser: message.is_seen_by_current_user || false,
                            redactTime: message.redact_time,
                            replyingMessageId: message.replying_to,
                            replyingTextContent: message.replying_to_text_content,
                            replyingSenderId: message.replying_to_sender_id,
                            parentMessageId: message.parent_message_id,
                        };
                    });

                    if (messageOrder.length > 0) {
                        chatroomsMessagesArray.push({
                            chatroomId,
                            memberId,
                            messages,
                            messageOrder,
                            hint: 'replace',
                            lastMessage: {
                                textContent: messages[`${messageOrder[messageOrder.length - 1]}`].textContent,
                                sentTime: messages[`${messageOrder[messageOrder.length - 1]}`].sentTime,
                            },
                            lastActiveTime: chatroom.last_active_time || null,
                            isOldPage: false,
                        });
                    }
                }
            });
        }

        if (receivedRoomInfos.length > 0) {
            // Store room info and then store the chat room order.
            yield put(receiveBulkChatroomInfo(receivedRoomInfos));
        }

        // Dispatch the array of actions
        if (chatroomMemberInfoArray.length > 0) {
            yield put(receiveBulkChatroomMembers(memberId, chatroomMemberInfoArray));
        }

        for (let i = 0; i < chatroomsMessagesArray.length; i += 1) {
            const chatroomMessagesInfo = chatroomsMessagesArray[i];
            yield put(startLoadingEntity('chatMessages', chatroomMessagesInfo.chatroomId));
            yield put(receiveChatroomMessages(
                chatroomMessagesInfo.chatroomId,
                chatroomMessagesInfo.memberId,
                chatroomMessagesInfo.messages,
                chatroomMessagesInfo.messageOrder,
                chatroomMessagesInfo.hint,
                chatroomMessagesInfo.lastMessage,
                chatroomMessagesInfo.lastActiveTime,
                chatroomMessagesInfo.isOldPage
            ));
            yield put(finishLoadingEntity('chatMessages', chatroomMessagesInfo.chatroomId));
        }
    }

    yield put(finishLoading('applicationData'));
}

export function* openChatroomSaga(action: OpenChatroom): Saga<void> {
    const {
        chatId,
    } = action;

    const memberId = yield select((state) => selectUserId(state.auth));
    const selectedResult = yield select((state) => state.chatList.selectedResult);
    const loadedMessages = yield select((state) => ((state.chatList.chats[`${chatId}`] || {}).messageOrder || []).length);

    // Only load members if we don't have them yet
    const hadNoMembers = yield select((state) => {
        const chat = state.chatList.chats[chatId];
        return !chat || !chat.memberOrder || chat.memberOrder.length === 0;
    });

    if (hadNoMembers) {
        yield put(loadChatMembers(chatId));
    }

    const messagesToLoad = yield select((state) => {
        const chat = state.chatList.chats[`${chatId}`];

        if (selectedResult && selectedResult.messagePosition) {
            return chat.totalMessages - selectedResult.messagePosition + 1;
        }

        return 0;
    });

    // If we have no messages and messagesToLoad < 50 then we load the first 50 messages.
    // If we have no messages and messagesToLoad > 50 then we load up to messagesToLoad (i.e., messagesToLoad === 63 we load 63 messages).
    if (loadedMessages === 0) {
        yield put(loadMessages(chatId, memberId, true, messagesToLoad));
    } else {
        // If we already have messages and we need to load more then load the next 50.
        const newOffset = loadedMessages;
        const messageCountNeeded = (messagesToLoad > newOffset) ? messagesToLoad - newOffset : 0;
        yield put(setOffset(chatId, memberId, newOffset, messageCountNeeded));
    }

    yield put(getResourcePermissions(ENTITY_TYPE_CHATROOM, chatId));
}

export function* searchChatSaga(action: Search): Saga<void> {
    const {
        searchText,
    } = action;

    yield put(startLoading('search'));

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { result, error, } = yield apiFetch(api.txp.search, {}, {
        text: searchText,
    });

    if (error) {
        yield put(finishLoading('search'));
    } else {
        const matchedMessages = result.matched_messages;

        const searchResults = {};

        const matchedMessagesKeys = keys(matchedMessages);

        for (let i = 0; i < matchedMessagesKeys.length; i += 1) {
            const messageInfoArray = [];
            const currentMessages = matchedMessages[matchedMessagesKeys[i]];

            for (let j = 0; j < currentMessages.length; j += 1) {
                const message = currentMessages[j].instant_message;
                messageInfoArray.push({
                    instantMessage: {
                        id: message.instant_message_id,
                        senderId: message.sender_id,
                        sentTime: message.sent_time,
                        messageType: message.chat_type_id,
                        textContent: message.text_content,
                        dataContent: message.data_content,
                        displayName: message.display_name,
                        fileName: message.file_name,
                        localPath: null,
                        seenByCount: message.read_count || 0,
                        seenByCurrentUser: message.is_seen_by_current_user || false,
                        status: null,
                        ack: {},
                    },
                    messagePosition: currentMessages[j].message_position,
                });
            }
            searchResults[matchedMessagesKeys[i]] = messageInfoArray;
        }

        if (keys(searchResults).length === 0) {
            // I needed a way to differentiate between the initial state of searchResults, which is [] and having no
            // search results, which is null.
            yield put(setSearchResults(null));
        } else {
            yield put(setSearchResults(searchResults));
        }

        yield put(finishLoading('search'));
    }
}

export function* downloadAvatarSaga(action: LoadMemberAvatars): Saga<void> {
    const {
        members,
        memberOrder,
    } = action;

    const accessToken = yield select((state) => state.auth.accessToken);
    const currentAvatars = yield select((state) => state.chatList.avatars);

    for (let i = 0; i < memberOrder.length; i += 1) {
        const memberId = memberOrder[i];
        const member = members[`${memberId}`];
        const profileImage = selectOtherProfileImage(member.profile, accessToken);

        if (typeof profileImage === 'string') {
            currentAvatars[memberId] = '';
        } else if (profileImage) {
            if (!currentAvatars[memberId]) {
                const { uri, headers, } = profileImage;
                const result = yield call(downloadAvatar, {
                    fromUrl: uri,
                    headers,
                    useLock: true,
                });

                if (result) {
                    currentAvatars[memberId] = result;
                } else {
                    currentAvatars[memberId] = '';
                }
            }
        }
    }
    const newAvatars = { ...currentAvatars, }; // ensure the reference changes so React re-renders
    yield put(receiveMemberAvatars(newAvatars));
}

/**
 * Load chat members from the api
 *
 * @param {number} chatId
 * @param {boolean} returnData if true will return the members instead of triggering the redux action
 */
function* loadChatroomMembersWorkerSaga(chatId: number, returnData: boolean = false): Saga<Error | ?ChatroomMembersResArray> {
    yield put(startLoadingEntity('chatMembers', chatId));

    const { result, error, } = yield apiFetch(api.txp.chatroomMembers, {
        chatId,
    });

    if (error) {
        yield put(finishLoadingEntity('chatMembers', chatId));

        if (!returnData) {
            yield pushError('Failed to load room members', error);
            return null;
        }

        return error;
    }

    const memberMap = {};
    const memberOrder = [];

    const resultArr = result.chatroom_members || [];

    for (let i = 0; i < resultArr.length; i += 1) {
        const member: {
            membership_status: string,
            start_date: string,
            end_date: string,
            do_not_disturb?: boolean,
            alert_sound?: string,
            user_information: RemoteMemberProfile,
        } = resultArr[i];

        const profileData = parseMemberProfile(member.user_information);

        const memberData = {
            profile: profileData,
            membershipStatus: member.membership_status,
            startDate: member.start_date,
            endDate: member.end_date,
            doNotDisturb: member.do_not_disturb,
            notificationSound: member.alert_sound || NotificationSoundMap.default,
        };

        memberOrder.push(member.user_information.user_id);
        memberMap[member.user_information.user_id] = memberData;
    }

    if (!returnData) {
        const memberId = yield select((state) => selectUserId(state.auth));
        yield put(receiveChatroomMembers(chatId, memberId, memberMap, memberOrder, 'replace'));
        yield put(finishLoadingEntity('chatMembers', chatId));

        yield put(loadMemberAvatars(memberMap, memberOrder));
        return null;
    }

    yield put(finishLoadingEntity('chatMembers', chatId));
    return [chatId, memberMap, memberOrder];
}

export function* loadChatMembersSaga(action: LoadChatMembers): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(66);

    yield call(loadChatroomMembersWorkerSaga, action.chatId, false);
}

export function* loadChatFilesSaga(action: LoadChatFiles): Saga<void> {
    const resArr: Array<ChatroomFile> = [];

    const { chatId, memberId, } = action;

    yield put(startLoadingEntity('chatFiles', chatId));

    const { result, error, } = yield apiFetch(api.txp.chatroomMedia, {
        chatId,
    });

    if (error || !result || !result.media_content || !Array.isArray(result.media_content)) {
        yield put(setSagaMessage('Loading room files failed, try again later', error, ''));
        return;
    }
    const messages = yield select((state) => state.chatList.chats[chatId].messages);

    result.media_content.forEach((fileData) => {
        // If we have a localPath already for the message with this messageId then we'll
        // set the localPath for the file with the same messageId
        const localPath = messages && messages[`${fileData.instant_message_id}`]
            ? messages[`${fileData.instant_message_id}`].localPath : null;

        resArr.push({
            id: fileData.instant_message_id,
            fileName: fileData.file_name,
            displayName: fileData.display_name || fileData.file_name,
            localPath,
            messageType: fileData.chat_type_id,
            memberId: fileData.user_id,
            sentTime: fileData.date_uploaded,
            size: fileData.file_size,
        });
    });

    const fileMap: ChatroomFileMap = {};
    const allFiles: Array<MessageId> = [];
    const myFiles: Array<MessageId> = [];

    (resArr || []).forEach((file) => {
        fileMap[`${file.id}`] = file;
        allFiles.push(file.id);

        if (file.memberId === memberId) {
            myFiles.push(file.id);
        }
    });

    // simulating network latency
    yield delay(700);

    yield put(receiveChatroomFiles(chatId, fileMap, allFiles, myFiles));

    yield put(finishLoadingEntity('chatFiles', chatId));
}

export function* loadChatroomsSaga(): Saga<void> {
    yield put(startLoading('chatrooms'));

    const { result, error, } = yield apiFetch(api.txp.chatrooms);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Loading rooms failed, are you online?', error));
        } else {
            yield put(pushError('Loading rooms failed, try again later', error));
        }
        yield put(finishLoading('chatrooms'));
    } else {
        const remoteRooms = (isObject(result) ? result.chatrooms : result) || [];
        const roomIds = [];

        let receivedRooms = [];

        let commitChunkLimit = 50;

        if (remoteRooms.length > 1000 && remoteRooms.length < 4000) {
            commitChunkLimit = 100;
        } else if (remoteRooms.length >= 4000 && remoteRooms.length < 8000) {
            commitChunkLimit = 150;
        } else if (remoteRooms.length >= 8000) {
            commitChunkLimit = 250;
        }

        for (let i = 0; i < remoteRooms.length; i += 1) {
            const theRoom = remoteRooms[i]; // Oh, Hi Mark
            roomIds.push(theRoom.chatroom_id);

            receivedRooms.push({
                id: theRoom.chatroom_id,
                name: theRoom.chatroom_name,
                description: theRoom.chatroom_description,
                chatroomType: theRoom.chatroom_type,
                managed: theRoom.managed || false,
                creatorId: theRoom.creator_id,
                memberCount: theRoom.member_count,
                doNotDisturb: theRoom.do_not_disturb,
                pinNumber: theRoom.pin_number,
                totalMessages: theRoom.total_message_count || 0,
                sentCount: theRoom.sent_count || 0,
                readCount: theRoom.read_count || 0,
                clearedCount: theRoom.cleared_count || 0,
                lastMessage: theRoom.last_instant_message_time ? {
                    textContent: theRoom.last_instant_message_content ? theRoom.last_instant_message_content : 'File uploaded',
                    sentTime: theRoom.last_instant_message_time,
                } : undefined,
                lastUpdateTime: theRoom.last_update_time,
                lastActiveTime: theRoom.last_active_time || null,
                donorId: theRoom.donor_id,
                organId: theRoom.organ_id,
                followerId: theRoom.follower_id || null,
                organType: theRoom.organ_type,
                targetOrgId: theRoom.transplant_center,
                createDate: theRoom.create_date,
                donorClosed: theRoom.donor_closed,
                caseName: theRoom.case_name,
                followerName: theRoom.follower_name,
            });

            if (i % 14) {
                // allows app to maintain 60 fps
                yield delay(10);
            }
            // Commit commitChunkLimit rooms at a time
            if (i % commitChunkLimit === 0 && receivedRooms.length > 1) {
                yield put(receiveBulkChatroomInfo(receivedRooms));
                receivedRooms = [];

                // Throttle a bit to avoid ui lockup
                yield delay(10);
            }
        }

        // commit the remainder
        if (receivedRooms.length > 0) {
            yield put(receiveBulkChatroomInfo(receivedRooms));
        }

        yield put(finishLoading('chatrooms'));
    }
}

export function* clearChatroomNotificationsSaga(action: ClearChatroomNotifications): Saga<void> {
    const { chatId, } = action;

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'clear-notifications', [chatId]);
}

export function* handleAddedToChatroomSaga(action: AddedToChatroom): Saga<void> {
    const memberId = yield select((state) => selectUserId(state.auth));
    const { roomId, } = action;

    yield put(startLoading('chatrooms'));

    // sent_count, read_count, and cleared_count are 0, since user has just been added to chatroom
    yield put(receiveChatroomInfo(
        action.roomId,
        action.name || 'a room',
        action.description || '',
        action.chatroomType,
        action.managed,
        action.creatorId,
        action.memberCount,
        null,
        action.totalMessages || 0,
        0,
        0,
        0,
        action.lastMessage,
        action.lastUpdateTime,
        action.lastActiveTime,
        action.donorId,
        action.organId,
        null,
        action.followerId,
        action.createDate,
        action.caseName,
        action.followerName
    ));

    // Load messages for the new chatroom
    yield put(loadMessages(roomId, memberId, false, 0));

    const roomName = yield select((state) => state.chatList.chats[`${roomId}`] && state.chatList.chats[`${roomId}`].name);
    yield put(setSagaMessage('Added to room', roomName, ''));

    // Load messages for the room
    yield put(loadMessages(roomId, memberId, true, 0));
    yield put(finishLoading('chatrooms'));
}

export function* handleRemovedFromChatroomSaga(action: RemovedFromChatroom): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(400);

    const { roomId, } = action;

    // Notify the user about the removal
    const roomName = yield select((state) => state.chatList.chats[`${roomId}`] && state.chatList.chats[`${roomId}`].name);
    yield put(pushError(`You've been removed from room ${roomName ? ' ' : ''}${roomName}`, undefined, ''));
    yield put(setSagaMessage('Removed from room', roomName, ''));

    yield put(setMessageText(roomId, ''));
    yield put(setMessageMedia(roomId, []));
    yield put(setMentionedUsers(roomId, []));
    yield put(setMentionedNames(roomId, []));
    yield put(closeChatroom(roomId));

    // Remove chatroom from redux
    yield put(removeChatroom(roomId));
}

function* loadMessageStatusesWorkerSaga(chatId: number, memberId: number, messageIds: Array<number>): Saga<void> {
    const messageIdList = JSON.stringify(messageIds);

    const { result, error, } = yield apiFetch(api.txp.chatroomMessageStatuses, {
        chatId,
        messageIdList,
    });

    if (error) {
        yield pushError('Failed to load message statuses for room', error);
    }

    let statusesCommit = null;

    if (result.instant_message_status_list.length === 0) { // no one has read the message yet
        statusesCommit = parseEmptyMessageStatus(memberId, chatId, messageIds[0], true);
    } else {
        statusesCommit = parseMessageStatuses(memberId, chatId, result, true);
    }

    if (statusesCommit) {
        yield put(statusesCommit);
    }
}

export function* loadMessageStatusesSaga(action: LoadMessageStatuses): Saga<void> {
    const {
        chatId,
        memberId,
        messageIds,
    } = action;

    // No need to try to load if no ids
    if (messageIds.length === 0) {
        return;
    }

    for (let i = 0; i < messageIds.length; i += 1) {
        yield put(startLoadingEntity('chatMessageStatuses', `${chatId}-${messageIds[i]}`));
    }

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);
    yield call(loadMessageStatusesWorkerSaga, chatId, memberId, messageIds);

    for (let i = 0; i < messageIds.length; i += 1) {
        yield put(finishLoadingEntity('chatMessageStatuses', `${chatId}-${messageIds[i]}`));
    }
}

export function* loadChatroomMessages(
    chatId: number,
    memberId: number,
    limit: number,
    offset: number = 0,
    resetMessages: boolean
): Saga<void> {
    yield put(startLoadingEntity('chatMessages', chatId));

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    let theLimit = limit;
    let theOffset = offset;

    // If we are loading messages in a room that has more unread messages than the limit
    //  lets load all of them at once
    if (offset !== 0 && resetMessages) {
        theLimit += theOffset;
        theOffset = 0;
    }

    const { result, error, } = yield apiFetch(api.txp.chatroomMessages, {
        chatId,
        limit: theLimit,
        offset: theOffset,
    }, {
        sort_order: 'Descending',
    });

    if (error) {
        yield put(pushError('Loading room messages failed, try again later', error));
    } else {
        const messages = {};
        const messageOrder = [];
        const rMsg: any = result.messages || {};

        // $FlowFixMe: https://github.com/facebook/flow/issues/4328
        const resultArr: Array<*> = rMsg.instant_messages || rMsg;

        const acknowledgeObj: any = rMsg.acknowledgements;

        const files = yield select((state) => state.chatList.chats[chatId].files);

        // The messages come in in reverse order (newest first). This is good for pagination
        //  since we can only load the newest messages first. However we need to store the items in the order
        //  we display them (hence the reverse iteration)
        for (let i = resultArr.length - 1; i >= 0; i -= 1) {
            const message = resultArr[i];

            const messageAcks = {};
            const acknowledgeObjKeys = keys(acknowledgeObj);
            for (let j = 0; j < acknowledgeObjKeys.length; j += 1) {
                const currentAckKey = acknowledgeObjKeys[j];
                if (parseInt(currentAckKey, 10) === message.instant_message_id) {
                    for (let k = 0; k < acknowledgeObj[currentAckKey].length; k += 1) {
                        messageAcks[acknowledgeObj[currentAckKey][k].chatroom_member_id] = {
                            chatId: acknowledgeObj[currentAckKey][k].chatroom_id,
                            messageId: acknowledgeObj[currentAckKey][k].instant_message_id,
                            memberId: acknowledgeObj[currentAckKey][k].chatroom_member_id,
                            // remove U+ and replace with 0x to display emoji
                            symbol: `0x${acknowledgeObj[currentAckKey][k].symbol.substring(2)}`,
                        };
                    }
                }
            }

            // If we have a localPath already for the file with this messageId then we'll
            // set the localPath for the message with the same messageId
            const fileLocalPath = files && files[`${message.instant_message_id}`] ? files[`${message.instant_message_id}`].localPath : null;

            messageOrder.push(message.instant_message_id);
            messages[message.instant_message_id] = {
                id: message.instant_message_id,
                senderId: message.sender_id,
                senderName: message.sender_name,
                sentTime: message.sent_time,
                messageType: message.chat_type_id,
                textContent: message.text_content,
                dataContent: message.data_content ? message.data_content : null,
                displayName: message.display_name,
                fileName: message.file_name,
                localPath: fileLocalPath,
                status: undefined,
                seenByCount: message.read_count || 0,
                seenByCurrentUser: message.is_seen_by_current_user || false,
                ack: messageAcks,
                redactTime: message.redact_time,
                replyingMessageId: message.replying_to,
                replyingTextContent: message.replying_to_text_content,
                replyingMessageType: message.replying_to_chat_type_id,
                replyingDataContent: message.replying_to_data_content,
                replyingFilePath: message.replying_to_file_path,
                replyingSenderId: message.replying_to_sender_id,
                parentMessageId: message.parent_message_id,
            };
        }

        let hint = 'end';

        if (resetMessages) {
            hint = 'replace';
        } else if (offset !== 0) {
            // Older messages should be pushed to the beginning of the array
            hint = 'start';
        }

        const lastActiveTime = yield select((state) => state.chatList.chats[`${chatId}`].lastActiveTime || null) || null;

        yield put(receiveChatroomMessages(chatId, memberId, messages, messageOrder, hint, null, lastActiveTime, offset !== 0));
    }

    yield put(finishLoadingEntity('chatMessages', chatId));
}

export function* loadChatroomMessagesManager(): Saga<void> {
    const tasks = {};

    while (true) {
        const action = yield take([
            'ChatList/LOAD_MESSAGES',
            'ChatList/SET_PAGE_OFFSET'
        ]);

        const { chatId, memberId, } = action;
        const resetMessages = action.resetMessages || false;
        let offset = action.offset || 0;
        const loadAllMessages = action.loadAllMessages || false;
        const limit = (action.numberMessages && (action.numberMessages > CHAT_PAGE_SIZE)) ? action.numberMessages : CHAT_PAGE_SIZE;

        let shouldLoad = true;

        if (action.type === 'ChatList/LOAD_MESSAGES') {
            offset = yield select((state) => (state.chatList.chats[`${chatId}`] || {}).offset || 0);
        } else if (action.type === 'ChatList/SET_PAGE_OFFSET') {
            // Get current amount of messages we have locally
            const messagesCount = yield select((state) => ((state.chatList.chats[`${chatId}`] || {}).messageOrder || []).length);

            // If we already have enough messages locally, skip loading more of them
            if (offset + limit < messagesCount) {
                shouldLoad = false;
            }
        }
        if (shouldLoad) {
            if (tasks[chatId]) {
                yield cancel(tasks[chatId]);

                delete tasks[chatId];
            }

            tasks[chatId] = yield fork(loadChatroomMessages, chatId, memberId, limit, offset, resetMessages, loadAllMessages);
        }
    }
}

function createTemporaryMessage(chatId: number, memberId: number, textContent: string, attachment: AttachmentData) {
    // Use a temporary id for the message and update it with response from server
    const uuid = createUuid(memberId);
    let messageType = MessageTypes.text;

    if (attachment) {
        switch (attachment.mime) {
            case 'image/jpeg':
                messageType = MessageTypes.jpeg;
                break;

            case 'image/jpg':
                messageType = MessageTypes.jpg;
                break;

            case 'image/png':
                messageType = MessageTypes.png;
                break;

            case 'image/gif':
                messageType = MessageTypes.gif;
                break;

            case 'video/mp4':
                messageType = MessageTypes.mp4;
                break;

            case 'video/quicktime':
                messageType = MessageTypes.mov;
                break;

            case 'application/pdf':
                messageType = MessageTypes.pdf;
                break;

            default:
                // Uploading file with unknown mime will fail, so set message type to 'unknown' so message sending will also fail
                messageType = MessageTypes.unknown;
        }
    }

    return {
        messageType,
        uuid,
    };
}

function* addChatMessage(
    chatId: number,
    memberId: number,
    textContent: string,
    attachments: Array<AttachmentData>,
    mentionedUsers: Array<number>,
    replyMessageId: ?number
): Saga<void> {
    if (attachments && attachments.length > 0) {
        const token = yield select((state) => state.auth.accessToken);
        const postAttachment: Array<PostAttachmentData> = [];
        let tmpData = { messageType: -1, tmpId: -1, };
        try {
            for (let i = 0; i < attachments.length; i += 1) {
                yield put(startLoading('media'));
                tmpData = yield call(createTemporaryMessage, chatId, memberId, textContent, attachments[i]);

                const root = (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, '');

                const encodedName = encodeURIComponent(attachments[i].fileName);
                const apiPath = `${root}/chatrooms/${chatId}/messages/media/url/${encodedName}/${attachments[i].mime}`;

                const form = new FormData();
                form.append('message', attachments[i].file);
                const headers = {
                    Apikey: process.env.REACT_APP_API_KEY || '',
                    Authorization: `Bearer ${token}`,
                };

                // Upload attachment
                const result = yield fetch(apiPath, {
                    method: 'POST',
                    headers,
                    body: form,
                }).then((response) => response.json());

                // Create post attachment data using response from server
                postAttachment.push({
                    file_name: result.media_content_path,
                    display_name: attachments[i].displayName,
                    file_size: attachments[i].size,
                    chat_type_id: tmpData.messageType,
                });
            }

            // Send the message via socket
            yield fork(
                sendMessage,
                chatId,
                memberId,
                textContent,
                MessageTypes.multipartMedia,
                tmpData.tmpId,
                postAttachment,
                mentionedUsers,
                replyMessageId
            );
            yield put(finishLoading('media'));
        } catch (error) {
            yield put(pushError('Failed to upload media', error));
            yield put(setMessageStatus(chatId, tmpData.tmpId, 'failed'));
            yield put(finishLoading('media'));
        }
    } else {
        const tmpData = yield call(createTemporaryMessage, chatId, memberId, textContent, null);

        // Send message via socket
        yield fork(sendMessage, chatId, memberId, textContent, tmpData.messageType, tmpData.tmpId, [], mentionedUsers, replyMessageId);
    }
    // Clear the ChatInput
    yield put(mediaUploadSuccess(chatId));
    yield put(setMessageText(chatId, ''));
    yield put(setMessageMedia(chatId, []));
    yield put(setMessageReply(chatId, null));
    yield put(setMentionedUsers(chatId, []));
    yield put(setMentionedNames(chatId, []));
    yield put(setInitialMessage('', []));
    yield put(setMentionedPendingUsers([]));
    yield put(setMentionedPendingNames([]));
}

export function* addChatMessageWatcher(): Saga<void> {
    while (true) {
        const { chatId, } = yield take('ChatMessage/SUBMIT');
        const memberId = yield select((state) => selectUserId(state.auth));
        const message = yield select((state) => state.chatMessage[`${chatId}`]);

        // Ignore empty messages
        if (message && (message.text || (message.attachments && message.attachments.length > 0))) {
            const mentionedUsers = message.mentionedUsers ? message.mentionedUsers : [];
            yield call(addChatMessage, chatId, memberId, message.text, message.attachments, mentionedUsers, message.replyMessageId);
        }
    }
}

export function* uploadXMLSaga(action: UploadXML): Saga<void> {
    const { chatId, xml, } = action;
    if (xml) {
        const token = yield select((state) => state.auth.accessToken);

        try {
            const root = (process.env.REACT_APP_API_ROOT || '').replace(/\/$/, '');

            const apiPath = `${root}/offer/donor/upload`;

            const orgId = yield select((state) => state.auth.profile.organizationId);

            const form = new FormData();
            form.append('message', xml.file);
            form.append('organization_id', orgId);
            form.append('chatroom_id', chatId.toString());
            const headers = {
                Apikey: process.env.REACT_APP_API_KEY || '',
                Authorization: `Bearer ${token}`,
            };

            // Upload xml
            const result = yield fetch(apiPath, {
                method: 'POST',
                headers,
                body: form,
            }).then((response) => response.json());

            if (result.error) {
                yield put(uploadXMLFailed(chatId, result.error));
            } else {
                // store donorId and organId in chatMessage state
                const donorId = result.donor_id;
                const organId = result.organ_id;
                yield put(uploadXMLSuccess(chatId, donorId, organId));
                yield put(updateDonorId(chatId, donorId));
                yield put(updateOrganId(chatId, organId));

                // call triggerAskAlan with donorId and organId
                const userId = yield select((state) => selectUserId(state.auth));
                const targetOrgId = yield select((state) => state.chatList.chats[`${chatId}`].targetOrgId);
                yield put(triggerAskAlan(chatId, orgId, targetOrgId || 0, userId, donorId, organId));
            }
        } catch (error) {
            yield put(pushError('Failed to upload xml', error));
            yield put(uploadXMLFailed(chatId, 'Failed to upload xml'));
        }
    }
}

export function* markMessagesReadSaga(action: MarkMessagesRead): Saga<void> {
    const chatInfo: ChatroomInfo = yield select((state) => state.chatList.chats[action.chatId]);

    yield delay(1000);

    const isValidMessageToRead = (messageId: MessageId) => {
        // Do not mark messages that do not exist locally (or are temporary)
        if (!messageId || !chatInfo.messages[`${messageId}`] || isString(messageId)) {
            return false;
        }

        // Do not mark own messages
        if (chatInfo.messages[`${messageId}`].senderId === action.memberId) {
            return false;
        }

        // Only mark messages not already marked
        return !chatInfo.messages[`${messageId}`].seenByCurrentUser;
    };

    const { untilId, } = action;
    const items = [];

    // Mark all previous messages as well
    for (let i = chatInfo.messageOrder.indexOf(untilId); i >= 0; i -= 1) {
        const messageId = chatInfo.messageOrder[i];

        if (isValidMessageToRead(messageId)) {
            items.push(messageId);
        }
    }

    // Send the commands if needed
    if (items && items.length > 0) {
        // Update local read statuses for all
        const memberId = yield select((state) => selectUserId(state.auth));
        const ack = yield call(sendCommandAndGetResult, action.chatId, memberId, 'read-messages', [items]);

        if (ack && ack.result) {
            const statuses = { [`${action.chatId}`]: {}, };

            for (let i = 0; i < ack.result.instant_message_status_list.length; i += 1) {
                statuses[`${action.chatId}`][`${ack.result.instant_message_status_list[i].instant_message_id}`] = {
                    [action.memberId]: isoNow(),
                };
            }

            yield put(bulkUpdateMessageStatus(action.chatId, action.memberId, statuses, 'extend',
                ack.result.new_status_count, ack.result.updated_status_count));
        }
    }
}

export function* acknowledgeMessageSaga(action: AcknowledgeMessage): Saga<void> {
    const {
        chatId,
        messageId,
        symbol,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    // https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const memberId = yield select((state) => selectUserId(state.auth));
    const ack = yield call(sendCommandAndGetResult, chatId, memberId, 'ack-message', [messageId, symbol]);

    if (!ack || (ack && ack.error)) {
        yield put(pushError('Failed to send acknowledgment', ack.error));
    } else {
        yield put(receiveAcknowledge(chatId, messageId, memberId, `0x${symbol.substring(2)}`));
    }
}

export function* redactMessageSaga(action: RedactMessage): Saga<void> {
    const {
        chatId,
        messageId,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    // https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'redact-message', [messageId]);
}

export function* unredactMessageSaga(action: UnredactMessage): Saga<void> {
    const {
        chatId,
        messageId,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    // https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'unredact-message', [messageId]);
}

export function* deleteFileMessageSaga(action: DeleteFileMessage): Saga<void> {
    const {
        chatId,
        messageId,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    yield delay(50);

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'delete-file-message', [messageId]);
}

export function* startTypingSaga(action: StartTyping): Saga<void> {
    const {
        chatId,
    } = action;

    const memberId = yield select((state) => selectUserId(state.auth));
    const ack = yield call(sendCommandAndGetResult, chatId, memberId, 'start-typing', [chatId]);

    if (!ack || (ack && ack.error)) {
        yield put(pushError('Failed to send start-typing signal', ack.error));
    }
}

export function* stopTypingSaga(action: StopTyping): Saga<void> {
    const {
        chatId,
    } = action;

    const memberId = yield select((state) => selectUserId(state.auth));
    const ack = yield call(sendCommandAndGetResult, chatId, memberId, 'stop-typing', [chatId]);

    if (!ack || (ack && ack.error)) {
        yield put(pushError('Failed to send stop-typing signal', ack.error));
    }
}

export function* pinChatroomSaga(action: PinChatroom): Saga<void> {
    const {
        chatId,
    } = action;

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'pin-chatroom', [chatId]);
}

export function* unpinChatroomSaga(action: UnpinChatroom): Saga<void> {
    const {
        chatId,
    } = action;

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'unpin-chatroom', [chatId]);
}

export function* updateChatroomData(
    chatId: number,
    description: string,
    name: string,
    managed: boolean
): Saga<void> {
    const oldRoomData = yield select((state) => state.chatList.chats[`${chatId}`] || {});

    if (name.length === 0) {
        const errors = {};

        errors.name = 'Room name cannot be empty.';

        yield put(saveChatFailed(errors));

        return;
    }

    yield put(updateChatroomInfo(
        chatId,
        name,
        description,
        managed,
        isoNow()
    ));

    const memberId = yield select((state) => selectUserId(state.auth));
    const ack = yield call(sendCommandAndGetResult, chatId, memberId, 'update-chatroom', [description, name, managed]);

    if (!ack || (ack && ack.error)) {
        // revert the name locally if it failed
        yield put(updateChatroomInfo(
            chatId,
            oldRoomData.name || name,
            oldRoomData.description || description,
            oldRoomData.managed || managed,
            oldRoomData.lastUpdateTime
        ));

        yield put(pushError('Failed to update room', ack.error));
    } else {
        // FIXME show toast?

        yield put(loadChatrooms());
    }
}

export function* saveChatSaga(action: SaveChat): Saga<void> {
    const {
        id,
        name,
        people,
        managed,
        message,
        mentioned,
        description,
        attachments,
        donorId,
        organId,
        transplantCenter,
        surgeon,
        chatroomType,
        restrictions,
    } = action;

    // Ensure takeLatest has time to handle doubleclicks
    // https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const isCreating = id === null;

    if (!isCreating) {
        yield call(
            updateChatroomData,
            id,
            (description || '').trim() || 'No description for room.',
            name,
            managed
        );
        return;
    }

    if (!name && !donorId) {
        const errors = {};

        if (!name) {
            errors.name = 'Room name is required';
        }

        yield put(saveChatFailed(errors));

        return;
    }

    yield put(startLoading('saveChat'));

    const memberId = yield select((state) => selectUserId(state.auth));

    const ack = yield call(
        sendCommandAndGetResult,
        -1,
        memberId,
        'create-chatroom',
        [name, description, donorId, organId, transplantCenter, chatroomType, managed || false, restrictions]
    );

    if (!ack || !ack.result) {
        const error = ack ? ack.error : {
            error: 'Something went wrong',
        };

        yield put(saveChatFailed({
            nonFieldError: singleErrorMessage(error),
        }));

        yield put(finishLoading('saveChat'));

        return;
    }

    const { result, } = ack;
    const chatId = result.chatroom_id;

    if (chatId) {
        // Update chatlist items
        yield put(receiveChatroomInfo(
            chatId,
            name,
            description,
            chatroomType,
            managed,
            memberId,
            1,
            null,
            1,
            0,
            0,
            0,
            message ? {
                textContent: message,
                sentTime: isoNow(),
            } : null,
            result.last_update_time || isoNow(),
            result.last_active_time || isoNow(),
            donorId,
            organId,
            null,
            null,
            result.create_date
        ));

        // check if surgeon is different than initial state
        if (surgeon && surgeon.userId !== 0) {
            yield put(addSingleUserToChatroom(chatId, memberId, surgeon.email, surgeon.phone));
        } else {
            yield put(addUsersToChatroom(chatId, memberId, people));
        }

        if (message || (attachments && attachments.length > 0)) {
            // Send initial message
            yield call(addChatMessage, chatId, memberId, message, attachments, mentioned);
        }

        // Trigger a chatroom reload to get the server side information of the room
        // Future optimisation: only load the new room
        yield put(loadChatrooms());
    }

    yield put(resetChatEditState());
    yield put(saveChatSuccess(chatId));
    yield put(finishLoading('saveChat'));
}

export function* syncProfileInChatroomsSaga(action: SyncProfileInChatrooms): Saga<void> {
    const [memberId, chatRooms] = yield select((state) => ([
        selectUserId(state.auth),
        state.chatList.chats
    ]));
    const roomIds = keys(chatRooms);

    for (let i = 0; i < roomIds.length; i += 1) {
        const chatId = roomIds[i];
        const chat = chatRooms[`${chatId}`];

        if (chat) {
            const myMemberObject = chat.members[`${memberId}`];

            if (myMemberObject) {
                yield put(receiveChatroomMembers(
                    chat.id,
                    memberId,
                    {
                        [`${memberId}`]: {
                            ...myMemberObject,

                            profile: {
                                ...(myMemberObject || {}).profile,
                                ...action.profile,
                            },
                        },
                    },
                    [memberId],
                    'extend'
                ));
            }
        }
    }
}
