// @flow
import type {
    DonorFilter, RemoteMemberProfile, RemoteUserProfile, UserProfile, RemoteExternalUser, ExternalUser,
} from './types';

const getDonorFilter = (donorFilter: any): DonorFilter => {
    const endResult = {
        status: 'All',
        donorType: 'All',
    };

    if (donorFilter && donorFilter.status) {
        endResult.status = donorFilter.status;
    }

    if (donorFilter && donorFilter.donor_type) {
        endResult.donorType = donorFilter.donor_type;
    }

    return endResult;
};

const parseRawProfile = (
    profile: RemoteUserProfile | RemoteMemberProfile,
    verifiedOrgMember: boolean,
    emailUpdate: ?string
): UserProfile => ({
    userId: profile.user_id,
    accessLevel: profile.access_level,
    license: profile.license || 'Standard',
    firstName: profile.first_name,
    lastName: profile.last_name,
    profilePicture: profile.profile_picture,
    organizationalRole: profile.organizational_role,
    department: profile.department,
    email: profile.email,
    emailUpdate,
    isEmailVerified: profile.is_email_verified,
    phone: profile.phone,
    organizationId: profile.organization_id || null,
    organizationName: profile.organization_name || '',
    verifiedOrgMember,
    templatePreferences: profile.template_preferences || null,
    clientPermissions: profile.client_permissions || null,
    communicationPreferences: profile.communication_preferences,
    chatroomSort: profile.chatroom_sort || 'DEFAULT',
    chatroomFilter: { status: 'All', chatType: 'All', },
    donorSort: profile.donor_sort || 'DEFAULT',
    donorFilter: getDonorFilter(profile.donor_filter),
    globalDoNotDisturbEnd: profile.global_do_not_disturb_end || '',
    emailNotification: profile.email_notification || false,
    adminNotification: profile.admin_notification || false,
    sentCount: profile.sent_count || 0,
    readCount: profile.read_count || 0,
});

const parseProfile = (profile: RemoteUserProfile): UserProfile => parseRawProfile(
    profile,
    profile.verified_organization_member,
    profile.email_update
);

export const parseExternalUser = (profile: RemoteExternalUser): ExternalUser => ({
    userId: profile.external_user_id,
    fullName: profile.full_name,
    phone: profile.phone,
    email: profile.email,
    details: profile.details,
    acinData: profile.acin_data,
});

// Members only have organization_id if they are verified
export const parseMemberProfile = (profile: RemoteMemberProfile): UserProfile => parseRawProfile(profile, !!profile.organization_id);

export default parseProfile;
