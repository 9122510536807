// @flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'react-toolbox/lib/button/Button';
import FontIcon from 'react-toolbox/lib/font_icon';
import RadioButton from 'react-toolbox/lib/radio/RadioButton';
import LoadingIndicator from 'react-loading-indicator';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import sift from 'sift';
import ReactTooltip from 'react-tooltip';
import type { SMSError } from 'txp-core';
import {
    updateProfile as _updateProfile,
    updateProfileFailed as _updateProfileFailed,
    setProfilePic as _setProfilePic,
    emailActivationDialog as _emailActivationDialog,
    startEmailUpdate as _startEmailUpdate,
    resendEmailUpdate as _resendEmailUpdate,
    cancelEmailUpdate as _cancelEmailUpdate,
    updatePhone as _updatePhone,
    verifyUpdatePhoneOTP as _verifyUpdatePhoneOTP,
    updatePhoneFailed as _updatePhoneFailed,
    joinOrganization as _joinOrganization,
    getMessageCounts as _getMessageCounts,
    selectProfileFirstName,
    selectProfileLastName,
    selectProfileEmail,
    selectProfilePhone,
    selectProfileOrganizationName,
    selectProfileOrganizationId,
    selectProfileOrganizationRole,
    selectProfileCommunicationPref,
    selectProfileDepartment,
} from 'txp-core';

import { setSagaMessage as _setSagaMessage } from '../Redux/ApplicationActions';
import { getOrganizations as _getOrganizations } from '../Redux/ChatEditActions';
import {
    filterOrganizations as _filterOrganizations,
    resetFilters as _resetFilters,
    loadMemberAvatars as _loadMemberAvatars,
    removeMemberAvatar as _removeMemberAvatar,
} from '../Redux/ChatListActions';
import type {
    Organization, UserProfile, ChatroomMemberMap, AvatarMap,
} from '../Utils/types';
import Images from '../Themes/Images';
import BackButton from './BackButton';
import Search from './Search';
import OrganizationItem from './OrganizationItem';
import ProfileStyles from './Styles/ProfileStyles';
import PhoneInputTextField from './PhoneInputTextField';
import truncate from '../Utils/text';
import VerificationCodeInput from './VerificationCodeInput';
import { formatPhone } from '../Utils/serializePhone';
import AuthStyles from '../Containers/Styles/AuthStyles';
import isString from '../Utils/isString';
import roles from '../Utils/roles';
import departments from '../Utils/departments';
import ChatroomDetailsStyles from './Styles/ChatroomDetailsStyles';

type Role = {
    value: string,
    label: string,
};

type Props = {
    profile: UserProfile,
    avatars: AvatarMap,

    // start selected profile values
    firstNameProfile: string,
    lastNameProfile: string,
    emailProfile: string,
    phoneProfile: string,
    roleProfile: string,
    departmentProfile: string,
    orgProfileName: string,
    orgProfileId: ?number,
    verifiedOrgMember: boolean,
    commPrefProfile: string,
    emailNotification: boolean,
    adminNotification: boolean,
    // end selected profile values

    profileError: string,
    smsError: ?SMSError,
    emailUpdate: ?string,
    isEmailVerified: boolean,
    isLoadingMessageCounts: boolean,
    uploadingProfilePicture: boolean,
    verifyUpdatedPhoneActive: boolean,
    activated: boolean,
    editingPhone: boolean,
    organizations: any,
    organizationFilter: string,

    updateProfile: (profile: UserProfile) => *,
    updateProfileFailed: (error: string) => *,
    setProfilePic: (photo: any) => *,
    closeProfile: () => *,
    emailActivationDialog: (visible: boolean) => *,
    startEmailUpdate: (email: string) => *,
    resendEmailUpdate: () => *,
    cancelEmailUpdate: () => *,
    updatePhone: (email: string, phone: string) => *,
    verifyUpdatePhoneOTP: (phone: string, passcode: string) => *,
    updatePhoneFailed: (smsError: ?string) => *,
    getOrganizations: () => *,
    filterOrganizations: (filter: string) => *,
    resetFilters: () => *,
    joinOrganization: (organizationId: number, organizationName: string, organizationRole: string) => *,
    getMessageCounts: () => *,
    setSagaMessage: (header: string, message: string, label: string, isDialog?: boolean) => *,
    loadMemberAvatars: (members: ChatroomMemberMap, memberOrder: Array<number>) => *,
    removeMemberAvatar: (member: number) => *,
};

type State = {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    role: string,
    department: string,
    commPref: string,
    emailNotification: boolean,
    adminNotification: boolean,

    renderEmail: boolean,

    renderPhone: boolean,
    verifyPhone: boolean,

    renderRole: boolean,

    renderOrganization: boolean,
    renderDepartment: boolean,

    editing: boolean,
    doneClicked: boolean,
};

class Profile extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        this.state = {
            firstName: this.props.firstNameProfile,
            lastName: this.props.lastNameProfile,
            email: this.props.emailProfile,
            phone: this.props.phoneProfile,
            role: this.props.roleProfile,
            department: this.props.departmentProfile,
            commPref: this.props.commPrefProfile,
            emailNotification: this.props.emailNotification,
            adminNotification: this.props.adminNotification,
            renderEmail: false,
            renderPhone: false,
            verifyPhone: false,
            renderRole: false,
            renderOrganization: false,
            renderDepartment: false,
            editing: false,
            doneClicked: false,
        };
    }

    componentDidMount() {
        const { getMessageCounts, } = this.props;
        this.loadMemberAvatar();
        getMessageCounts();
    }

    async componentDidUpdate(prevProps: Props) {
        const {
            activated,
            roleProfile,
            editingPhone,
            uploadingProfilePicture,
        } = this.props;

        if (editingPhone) {
            this.toggleVerifyPhone(true);
        } else {
            this.toggleVerifyPhone(false);
        }

        if (prevProps.editingPhone && !editingPhone && activated) {
            this.togglePhoneView(false);
        }

        if (prevProps.roleProfile !== roleProfile) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                role: roleProfile,
            });
        }
        if (prevProps.uploadingProfilePicture && !uploadingProfilePicture) {
            this.loadMemberAvatar();
        }
    }

    onClose = () => {
        const {
            firstNameProfile,
            lastNameProfile,
            commPrefProfile,
            roleProfile,
            closeProfile,
        } = this.props;

        const {
            firstName,
            lastName,
            commPref,
            role,
        } = this.state;

        if (
            firstName !== firstNameProfile
            || lastName !== lastNameProfile
            || commPref !== commPrefProfile
            || role !== roleProfile
        ) {
            const response = window.confirm('Are you sure you want to continue? Any unsaved changes will be lost.');

            if (response) {
                closeProfile();
            }
        } else {
            closeProfile();
        }
    };

    onBack = () => {
        const {
            emailProfile,
            phoneProfile,
            updatePhoneFailed,
        } = this.props;

        const {
            renderEmail,
            renderPhone,
            renderRole,
            renderOrganization,
            renderDepartment,
            email,
            phone,
            verifyPhone,
        } = this.state;

        if (renderEmail) {
            if (email === emailProfile) {
                this.setState({
                    renderEmail: false,
                });
            } else {
                const response = window.confirm(
                    'Are you sure you want to go back? Your email will not be updated until you click \'Update email\'.'
                );

                if (response) {
                    this.setState({
                        renderEmail: false,
                        email: emailProfile,
                    });
                }
            }
        } else if (renderPhone) {
            if (phone === phoneProfile) {
                this.togglePhoneView();
            } else if (verifyPhone) {
                const response = window.confirm('Are you sure you want to go back? Your phone will not be updated until you verify.');

                if (response) {
                    this.setState({
                        verifyPhone: false,
                        renderPhone: false,
                        phone: phoneProfile,
                    });
                }
            } else {
                const response = window.confirm(
                    'Are you sure you want to go back? Your phone will not be updated until you click \'Update phone\'.'
                );

                if (response) {
                    this.setState({
                        renderPhone: false,
                        phone: phoneProfile,
                    });
                }
            }
            updatePhoneFailed(null);
        } else if (renderRole) {
            this.toggleRoleView();
        } else if (renderOrganization) {
            this.toggleOrganizationView();
        } else if (renderDepartment) {
            this.toggleDepartmentView();
        }
    };

    onCancelEmailUpdate = () => {
        const {
            cancelEmailUpdate,
        } = this.props;

        this.setState({
            renderEmail: false,
        });

        cancelEmailUpdate();
    };

    onResendEmailUpdate = () => {
        const {
            resendEmailUpdate,
        } = this.props;

        this.setState({
            renderEmail: false,
        });

        resendEmailUpdate();
    };

    onVerifyUpdatedPhone = (passcode: string) => {
        const {
            verifyUpdatePhoneOTP,
        } = this.props;

        const {
            phone,
        } = this.state;

        verifyUpdatePhoneOTP(phone, passcode);
    };

    onSearchOrganizations = (input: string) => {
        const {
            filterOrganizations,
        } = this.props;

        filterOrganizations(input);
    };

    getDepartmentRadioValue = (value: ?string) => {
        // If the department isn't set, we'll keep it null
        if (value === null) {
            return null;
        }

        const departmentValues = departments.map((d) => d.value);
        if (departmentValues.includes(value)) {
            return value;
        }
        return 'Other';
    };

    getRoleValue = (value: ?string) => {
        const roleValues = roles.map((r) => r.value);
        return roleValues.includes(value) ? value : 'Other';
    };

    loadMemberAvatar() {
        // Load this users avatar, if not already loaded
        const { profile, loadMemberAvatars, } = this.props;
        const contactsMap = {};
        contactsMap[profile.userId] = {
            profile,
            membershipStatus: null,
        };
        loadMemberAvatars(contactsMap, [profile.userId]);
    }

    toggleEmailView = () => {
        const {
            emailProfile,
        } = this.props;

        const {
            renderEmail,
        } = this.state;

        this.setState({
            renderEmail: !renderEmail,
            email: emailProfile,
        });
    };

    togglePhoneView = (value?: boolean) => {
        const {
            renderPhone,
        } = this.state;

        if (value) {
            this.setState({
                renderPhone: value,
            });
        } else {
            this.setState({
                renderPhone: !renderPhone,
            });
        }
    };

    toggleVerifyPhone = (value: boolean) => {
        this.setState({
            verifyPhone: value,
        });
    };

    toggleRoleView = () => {
        const {
            renderRole,
        } = this.state;

        this.setState({
            renderRole: !renderRole,
        });
    };

    toggleDepartmentView = () => {
        const {
            renderDepartment,
        } = this.state;

        this.setState({
            renderDepartment: !renderDepartment,
        });
    };

    toggleOrganizationView = () => {
        const {
            getOrganizations,
            resetFilters,
        } = this.props;

        const {
            renderOrganization,
        } = this.state;

        if (!renderOrganization) {
            getOrganizations();
        } else {
            resetFilters();
        }

        this.setState({
            renderOrganization: !renderOrganization,
        });
    };

    editFields = (key: 'firstName' | 'lastName' | 'email' | 'phone' | 'commPref' | 'role' | 'emailNotification' | 'department', input: any) => {
        let value;
        if (isString(input)) {
            value = input;
        } else if (input) {
            ({ value, } = input.target);
        }

        this.setState((prevState: State) => ({
            ...prevState,
            // $FlowFixMe
            [key]: value,
        }));
    };

    editDepartmentRadio = (value: string) => {
        let newValue: string;
        if (this.getDepartmentRadioValue(value) === 'Other') {
            newValue = '';
        } else {
            newValue = value;
        }
        this.setState((prevState: State) => ({
            ...prevState,
            department: newValue,
        }));
    };

    editDepartmentText = (value: string) => {
        this.setState((prevState: State) => ({
            ...prevState,
            department: value,
        }));
    };

    editRoleRadio = (value: string) => {
        let newValue: string;
        if (this.getRoleValue(value) === 'Other') {
            newValue = '';
        } else {
            newValue = value;
        }
        this.setState((prevState: State) => ({
            ...prevState,
            role: newValue,
        }));
    };

    editRoleText = (value: string) => {
        this.setState((prevState: State) => ({
            ...prevState,
            role: value,
        }));
    };

    editEmailNotification = (checked: boolean) => {
        this.setState((prevState: State) => ({
            ...prevState,
            emailNotification: checked,
        }));
    };

    editAdminNotification = (checked: boolean) => {
        this.setState((prevState: State) => ({
            ...prevState,
            adminNotification: checked,
        }));
    };

    selectOrganization = (organization: Organization) => {
        const {
            orgProfileId,
            roleProfile,
            joinOrganization,
        } = this.props;

        const message = orgProfileId === null
            ? 'Would you like to request joining the organization?'
            : 'This action will remove you from your current organization. Are you sure you want to continue?';

        const response = window.confirm(message);

        if (response) {
            const organizationRole = roleProfile !== 'No role' ? roleProfile : 'Other';
            joinOrganization(organization.id, organization.name, organizationRole);
            this.toggleOrganizationView();
        }
    };

    fileUploadRef: {| current: null | HTMLInputElement |} = React.createRef();

handlePhoto = (event: any) => {
    event.preventDefault();

    const {
        setProfilePic,
        profile,
        removeMemberAvatar,
    } = this.props;

    if (this.fileUploadRef.current && this.fileUploadRef.current.files && this.fileUploadRef.current.files.length > 0) {
        const { files, } = this.fileUploadRef.current;
        setProfilePic(files[0]);
    }
    removeMemberAvatar(profile.userId);
};

rightButtonClick = () => {
    const {
        profile,
        updateProfile,
        updateProfileFailed,
        startEmailUpdate,
        updatePhone,
    } = this.props;

    const {
        editing,
        firstName,
        lastName,
        email,
        phone,
        role,
        department,
        commPref,
        emailNotification,
        adminNotification,
        renderEmail,
        renderPhone,
    } = this.state;

    if (editing) {
        if (renderEmail) {
            startEmailUpdate(email);
            this.toggleEmailView();
        } else if (renderPhone) {
            updatePhone(selectProfileEmail(profile), phone);
        } else {
            if (role === 'No organizationalRole') {
                updateProfileFailed('Update failed, please select a organizationalRole');
            } else {
                const newProfile = profile;
                newProfile.firstName = firstName !== '' ? firstName : null;
                newProfile.lastName = lastName !== '' ? lastName : null;
                newProfile.organizationalRole = role;
                newProfile.department = department;
                newProfile.communicationPreferences = commPref;
                newProfile.emailNotification = emailNotification;
                newProfile.adminNotification = adminNotification;
                updateProfile(newProfile);
            }

            this.setState({
                editing: false,
                doneClicked: true,
            });
        }
    } else {
        this.setState({
            editing: true,
            doneClicked: false,
        });
    }
};

showEmailActivationDialog = () => {
    const {
        emailActivationDialog,
    } = this.props;

    emailActivationDialog(true);
};

showPendingOrgDialog = () => {
    const {
        setSagaMessage,
    } = this.props;

    setSagaMessage(
        'Organization membership pending',
        'You have not yet been verified as a member of this organization. Others can\'t yet see you as a member, '
        + 'but you can still send messages.',
        'OK',
        true
    );
};

hasFieldError(fieldName: string, allowNonField: boolean): boolean {
    const {
        smsError,
    } = this.props;

    if (smsError) {
        return !!smsError[fieldName] || !!(allowNonField && smsError.nonFieldError);
    }
    return false;
}

renderFieldError(fieldName: string, allowNonField: boolean) {
    const {
        smsError,
    } = this.props;

    let error = null;
    if (smsError) {
        error = smsError[fieldName];

        if (!error && allowNonField) {
            error = smsError.nonFieldError;
        }

        if (!error) {
            return null;
        }
    }

    return (
        <div style={{ textAlign: 'center', }}>
            <span
                style={AuthStyles.formError}
            >
                {error}
            </span>
        </div>
    );
}

renderProfileError() {
    const {
        profileError,
    } = this.props;

    return (
        <div style={{ ...ProfileStyles.center, ...{ marginTop: 20, }, }}>
            <span style={ProfileStyles.error}>{profileError}</span>
        </div>
    );
}

renderProfileSaved = () => (
    <div style={{ ...ProfileStyles.center, ...{ marginTop: 20, }, }}>
        <span style={ProfileStyles.saved}>Saved!</span>
    </div>
);

renderEmail() {
    const {
        emailProfile,
        emailUpdate,
        isEmailVerified,
    } = this.props;

    const {
        email,
    } = this.state;
    const updateEmailDisabled = email === emailProfile;

    return (
        <div style={ProfileStyles.marginTop10}>
            <BackButton
                label="Back"
                goBack={this.onBack}
            />
            {emailUpdate === null
                ? (
                    <Button
                        style={updateEmailDisabled ? ProfileStyles.rightButtonDisabled : ProfileStyles.rightButton}
                        label="Update email"
                        flat
                        disabled={updateEmailDisabled}
                        ripple={false}
                        onClick={this.rightButtonClick}
                    />
                ) : <div />}
            {emailUpdate === null
                ? (
                    <div style={ProfileStyles.infoWrapper}>
                        <div style={ProfileStyles.fieldContainerNoButton}>
                            <span style={ProfileStyles.label}>Email</span>
                            <input
                                style={ProfileStyles.nameInputEdit}
                                type="text"
                                maxLength={255}
                                value={email}
                                onChange={(text) => this.editFields('email', text)}
                            />
                        </div>
                        <div style={{ ...ProfileStyles.center, ...{ marginTop: 20, }, }}>
                            {isEmailVerified && emailUpdate === null
                                ? (
                                    <div>
                                        <span style={ProfileStyles.smallLabel}>
                                            Your email is verified
                                        </span>
                                        <FontIcon
                                            className="emailVerified"
                                            value="check_circle"
                                            alt=""
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <span style={ProfileStyles.smallLabel}>
                                            Your email is not verified
                                        </span>
                                        <button
                                            className="arrowButton"
                                            type="button"
                                            tabIndex="0"
                                            onClick={this.showEmailActivationDialog}
                                        >
                                            <FontIcon
                                                className="emailError"
                                                value="error_outline"
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>
                )
                : (
                    <div style={ProfileStyles.infoWrapper}>
                        <span>
                            {'If you have not received a verification email, try resending. '
                                + 'Otherwise, cancel your request to continue sending messages with your previous email.'}
                        </span>
                        <div style={{ ...ProfileStyles.centerContainer, ...{ marginTop: 20, }, }}>
                            <Button
                                style={ProfileStyles.resendButton}
                                label="Resend"
                                flat
                                ripple={false}
                                onClick={this.onResendEmailUpdate}
                            />
                            <Button
                                style={ProfileStyles.cancelButton}
                                label="Cancel update"
                                flat
                                ripple={false}
                                onClick={this.onCancelEmailUpdate}
                            />
                        </div>
                    </div>
                )}
        </div>
    );
}

renderPhone() {
    const {
        phoneProfile,
        verifyUpdatedPhoneActive,
        smsError,
    } = this.props;

    const {
        phone,
        verifyPhone,
    } = this.state;

    const errors = {
        phone: this.hasFieldError('phone', true),
        passcode: this.hasFieldError('passcode', true),
    };

    const passcodeError = smsError && smsError.passcode ? smsError.passcode : '';
    const nonFieldError = smsError && smsError.nonFieldError ? smsError.nonFieldError : '';
    const verificationCodeError = errors.passcode ? (passcodeError !== '' ? passcodeError : nonFieldError) : '';

    const updatePhoneDisabled = phone === phoneProfile;

    return (
        <div style={ProfileStyles.marginTop10}>
            <BackButton
                label="Back"
                goBack={this.onBack}
            />
            {!verifyPhone
                ? (
                    <Button
                        style={updatePhoneDisabled ? ProfileStyles.rightButtonDisabled : ProfileStyles.rightButton}
                        label="Update phone"
                        flat
                        disabled={updatePhoneDisabled}
                        ripple={false}
                        onClick={this.rightButtonClick}
                    />
                ) : <div />}
            {verifyPhone
                ? (
                    <div style={ProfileStyles.infoWrapper}>
                        <VerificationCodeInput
                            errorMessage={verificationCodeError}
                            submitPasscode={this.onVerifyUpdatedPhone}
                            verifying={verifyUpdatedPhoneActive}
                            showHeading={false}
                            canResend={false}
                            canGoBack={false}
                        />
                    </div>
                )
                : (
                    <div style={ProfileStyles.infoWrapper}>
                        <div style={ProfileStyles.fieldContainerNoButton}>
                            <span style={ProfileStyles.label}>Phone</span>
                            <PhoneInput
                                value={phone}
                                onChange={(text) => this.editFields('phone', text)}
                                style={ProfileStyles.nameInputEdit}
                                name="phoneInput"
                                international
                                withCountryCallingCode
                                inputComponent={PhoneInputTextField}
                            />
                        </div>
                        {this.renderFieldError('phone', true)}
                    </div>
                )}
        </div>
    );
}

renderRole() {
    const {
        role,
    } = this.state;

    return (
        <div style={ProfileStyles.marginTop10}>
            <BackButton
                label="Back"
                goBack={this.onBack}
            />
            <div style={ProfileStyles.infoWrapper}>
                {
                    roles.map((singleRole: Role) => (
                        <div
                            key={singleRole.value}
                            style={ProfileStyles.radioButtonContainer}
                        >
                            <RadioButton
                                label={singleRole.label}
                                value={singleRole.value}
                                checked={this.getRoleValue(role) === singleRole.value}
                                onChange={() => this.editRoleRadio(singleRole.value)}
                            />
                            {singleRole.value === 'Other' && this.getRoleValue(role) === 'Other' ? (
                                <input
                                    style={ProfileStyles.textInput}
                                    type="text"
                                    maxLength={255}
                                    value={role}
                                    onChange={(event) => this.editRoleText(event.target.value)}
                                    placeholder="Specify Role"
                                />
                            ) : null}
                            <div style={ChatroomDetailsStyles.border} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

renderDepartment() {
    const {
        department,
    } = this.state;

    return (
        <div style={ProfileStyles.marginTopBottom}>
            <BackButton
                label="Back"
                goBack={this.onBack}
            />
            <div style={ProfileStyles.infoWrapper}>
                {
                    departments.map((singleDepartment: Role) => (
                        <div
                            key={singleDepartment.value}
                            style={ProfileStyles.radioButtonContainer}
                        >
                            <RadioButton
                                label={singleDepartment.label}
                                value={singleDepartment.value}
                                checked={this.getDepartmentRadioValue(department) === singleDepartment.value}
                                onChange={() => this.editDepartmentRadio(singleDepartment.value)}
                            />
                            <div style={ChatroomDetailsStyles.border} />
                        </div>
                    ))
                }
                {this.getDepartmentRadioValue(department) === 'Other'
                    ? (
                        <>
                            <div style={{ ...ProfileStyles.marginTop10, ...ProfileStyles.label, }}>
                                Department, if Other
                            </div>
                            <input
                                style={ProfileStyles.textInput}
                                type="text"
                                maxLength={255}
                                value={department}
                                onChange={(event) => this.editDepartmentText(event.target.value)}
                            />
                        </>
                    ) : null}
            </div>
        </div>
    );
}

renderOrganization() {
    const {
        organizations,
        organizationFilter,
    } = this.props;

    const hasQuery = organizationFilter && organizationFilter.length > 2;

    const items = hasQuery ? organizations.filter(
        sift({
            $where() {
                return this.name.toUpperCase().includes(organizationFilter.toUpperCase())
                    || this.code.toUpperCase().includes(organizationFilter.toUpperCase());
            },
        })
    ) : [];

    const blankMsg = hasQuery
        ? 'No organizations found.\nTry searching for something else.'
        : 'Please enter at least 3 characters to choose an organization';

    return (
        <div style={ProfileStyles.marginTop10}>
            <BackButton
                label="Back"
                goBack={this.onBack}
            />
            <div style={ProfileStyles.infoWrapper}>
                <Search
                    placeholder="Search organizations..."
                    value={organizationFilter}
                    filterContent={this.onSearchOrganizations}
                />
                {items.length > 0 ? items.map((organization: Organization) => (
                    <OrganizationItem
                        key={organization.id}
                        organization={organization}
                        selectOrganization={this.selectOrganization}
                        getSurgeons={() => { }}
                    />
                ))
                    : (
                        <div style={ProfileStyles.blankMessage}>
                            {blankMsg}
                        </div>
                    )}
            </div>
        </div>
    );
}

renderProfile() {
    const {
        profile,
        emailProfile,
        phoneProfile,
        orgProfileName,
        orgProfileId,
        verifiedOrgMember,
        profileError,
        emailUpdate,
        isEmailVerified,
        uploadingProfilePicture,
        avatars,
        isLoadingMessageCounts,
    } = this.props;

    const {
        firstName,
        lastName,
        commPref,
        emailNotification,
        adminNotification,
        role,
        department,
        editing,
        doneClicked,
    } = this.state;

    const isOrganizationPending = orgProfileId !== null && !verifiedOrgMember;

    const userAvatar = avatars[profile.userId] ? avatars[profile.userId] : Images.circledDefaultProfile;
    const avatarLoading = (profile && (profile.profilePicture !== null) && !avatars[profile.userId]);

    return (
        <div style={ProfileStyles.marginTop10}>
            <ReactTooltip />
            <BackButton
                label="Close"
                goBack={this.onClose}
            />
            <Button
                style={ProfileStyles.rightButton}
                label={editing ? 'Done' : 'Edit'}
                flat
                ripple={false}
                onClick={this.rightButtonClick}
            />
            <div style={ProfileStyles.profileImageWrapper}>
                {uploadingProfilePicture || avatarLoading
                    ? (
                        <div style={ProfileStyles.loadingIndicator}>
                            <LoadingIndicator />
                        </div>
                    )
                    : (
                        <img
                            style={ProfileStyles.profileImage}
                            src={userAvatar}
                            alt=""
                        />
                    )}
            </div>
            <div style={{ ...ProfileStyles.centerContainer, ...{ height: '50px', paddingTop: 0, }, }}>
                <label
                    style={ProfileStyles.changePicButton}
                    htmlFor="files"
                    className="btn"
                >
                    {editing ? 'Change profile photo' : ''}
                    <input
                        style={{ display: 'none', }}
                        id="files"
                        type="file"
                        accept=".png, .jpg, .jpeg,"
                        disabled={!editing}
                        ref={this.fileUploadRef}
                        onChange={this.handlePhoto}
                    />
                </label>
            </div>
            <div style={ProfileStyles.infoWrapper}>
                <div style={ProfileStyles.fieldContainerNoButton}>
                    <span style={ProfileStyles.label}>First name</span>
                    <input
                        style={editing ? ProfileStyles.nameInputEdit : ProfileStyles.nameInput}
                        type="text"
                        maxLength={255}
                        disabled={!editing}
                        value={firstName}
                        onChange={(text) => this.editFields('firstName', text)}
                    />
                </div>
                <div style={ProfileStyles.fieldContainerNoButton}>
                    <span style={ProfileStyles.label}>Last name</span>
                    <input
                        style={editing ? ProfileStyles.nameInputEdit : ProfileStyles.nameInput}
                        type="text"
                        maxLength={255}
                        disabled={!editing}
                        value={lastName}
                        onChange={(text) => this.editFields('lastName', text)}
                    />
                </div>
                <div style={ProfileStyles.fieldContainerWithButton}>
                    <span style={ProfileStyles.label}>Email</span>
                    <span style={ProfileStyles.value}>
                        {!isEmailVerified && emailUpdate === null
                            ? (
                                <button
                                    className="arrowButton"
                                    type="button"
                                    tabIndex="0"
                                    onClick={this.showEmailActivationDialog}
                                >
                                    <FontIcon
                                        className="emailError"
                                        value="error_outline"
                                        alt=""
                                    />
                                </button>
                            ) : null}
                        {truncate(emailProfile, emailUpdate !== null ? 22 : 30)}
                        {emailUpdate !== null
                            ? (
                                <Button
                                    style={ProfileStyles.updatePending}
                                    label="Update pending"
                                    flat
                                    ripple={false}
                                    onClick={this.toggleEmailView}
                                />
                            )
                            : editing
                                ? (
                                    <button
                                        className="arrowButton"
                                        type="button"
                                        tabIndex="0"
                                        onClick={this.toggleEmailView}
                                    >
                                        <FontIcon
                                            className="emailArrow"
                                            value="arrow_forward_ios"
                                            alt=""
                                        />
                                    </button>
                                ) : null}
                    </span>
                </div>
                <div style={ProfileStyles.fieldContainerWithButton}>
                    <span style={ProfileStyles.label}>Phone</span>
                    <span style={ProfileStyles.value}>{formatPhone(phoneProfile)}</span>
                    {editing
                        ? (
                            <button
                                className="arrowButton"
                                type="button"
                                tabIndex="0"
                                onClick={() => this.togglePhoneView()}
                            >
                                <FontIcon
                                    className="emailArrow"
                                    value="arrow_forward_ios"
                                    alt=""
                                />
                            </button>
                        ) : null}
                </div>
                {orgProfileId !== null
                    ? (
                        <div style={ProfileStyles.fieldContainerWithButton}>
                            <span style={ProfileStyles.label}>Role</span>
                            <span style={ProfileStyles.value}>{role}</span>
                            {editing
                                ? (
                                    <button
                                        className="arrowButton"
                                        type="button"
                                        tabIndex="0"
                                        onClick={this.toggleRoleView}
                                    >
                                        <FontIcon
                                            className="emailArrow"
                                            value="arrow_forward_ios"
                                            alt=""
                                        />
                                    </button>
                                ) : null}
                        </div>
                    ) : <div />}
                <div style={ProfileStyles.fieldContainerWithButton}>
                    <span style={ProfileStyles.label}>Department</span>
                    <span style={ProfileStyles.value}>{department}</span>
                    {editing
                        ? (
                            <button
                                className="arrowButton"
                                type="button"
                                tabIndex="0"
                                onClick={this.toggleDepartmentView}
                            >
                                <FontIcon
                                    className="emailArrow"
                                    value="arrow_forward_ios"
                                    alt=""
                                />
                            </button>
                        ) : null}
                </div>
                <div style={ProfileStyles.fieldContainerWithButton}>
                    <span style={ProfileStyles.label}>Organization</span>
                    <span style={ProfileStyles.value}>
                        {isOrganizationPending
                            ? (
                                <button
                                    className="arrowButton"
                                    type="button"
                                    tabIndex="0"
                                    onClick={this.showPendingOrgDialog}
                                >
                                    <FontIcon
                                        className="emailError"
                                        value="error_outline"
                                        alt=""
                                    />
                                </button>
                            ) : null}
                        {truncate(orgProfileName, isOrganizationPending ? 15 : 33)}
                    </span>
                    {editing
                        ? (
                            <button
                                className="arrowButton"
                                type="button"
                                tabIndex="0"
                                onClick={this.toggleOrganizationView}
                            >
                                <FontIcon
                                    className="emailArrow"
                                    value="arrow_forward_ios"
                                    alt=""
                                />
                            </button>
                        ) : null}
                </div>
                <div style={ProfileStyles.fieldContainerNoButton}>
                    <span style={ProfileStyles.label}>Comm. Pref.</span>
                    <textarea
                        style={editing ? ProfileStyles.commPrefInputEdit : ProfileStyles.commPrefInput}
                        cols={25}
                        disabled={!editing}
                        value={commPref}
                        onChange={(text) => this.editFields('commPref', text)}
                    />
                </div>
                <div style={ProfileStyles.fieldContainerNoButton}>
                    <span style={ProfileStyles.label}>Email Notifications</span>
                    <FormGroup>
                        <FormControlLabel
                            labelPlacement="start"
                            label={emailNotification ? 'On' : 'Off'}
                            control={(
                                <Switch
                                    disabled={!editing}
                                    color="primary"
                                    checked={emailNotification}
                                    onChange={(event) => this.editEmailNotification(event.target.checked)}
                                />
                            )}
                        />
                    </FormGroup>
                </div>
                <div style={ProfileStyles.fieldContainerNoButton}>
                    <span style={ProfileStyles.label}>New Admin Notifications</span>
                    <FormGroup>
                        <FormControlLabel
                            labelPlacement="start"
                            label={adminNotification ? 'On' : 'Off'}
                            control={(
                                <Switch
                                    disabled={!editing}
                                    color="secondary"
                                    checked={adminNotification}
                                    onChange={(event) => this.editAdminNotification(event.target.checked)}
                                />
                            )}
                        />
                    </FormGroup>
                </div>
                {doneClicked ? (profileError ? this.renderProfileError() : this.renderProfileSaved()) : <div />}
            </div>
            {!editing
                ? (
                    <div style={ProfileStyles.messageCountWrapper}>
                        {isLoadingMessageCounts && (
                            <p style={ProfileStyles.loadingMessages}>
                                <LoadingIndicator />
                                Getting latest message counts...
                            </p>
                        )}
                        In the last 30 days, you have
                        <span style={ProfileStyles.sentCount}>
                            {` sent ${profile.sentCount || 0} ${profile.sentCount === 1 ? 'message ' : 'messages '}`}
                        </span>
                        and
                        <span style={ProfileStyles.readCount}>
                            {` read ${profile.readCount || 0} ${profile.readCount === 1 ? ' message' : ' messages '}`}
                        </span>
                        using OmniLife&#8482;.
                    </div>
                ) : null}
            <div style={{ ...ProfileStyles.centerContainer, ...{ height: '50px', paddingTop: 0, }, }} />
            <Button
                label="Request Account Deletion"
                style={ProfileStyles.accountDeletion}
                flat
                ripple={false}
                onClick={() => window.open(process.env.REACT_APP_LAUNCH_ACCOUNT_DELETION_URL, '_blank')}
            />
        </div>
    );
}

render() {
    const {
        renderEmail,
        renderPhone,
        renderRole,
        renderOrganization,
        renderDepartment,
    } = this.state;

    if (renderEmail) {
        return this.renderEmail();
    } if (renderPhone) {
        return this.renderPhone();
    } if (renderRole) {
        return this.renderRole();
    } if (renderOrganization) {
        return this.renderOrganization();
    } if (renderDepartment) {
        return this.renderDepartment();
    }
    return this.renderProfile();
}
}

const mapStateToProps = (state) => ({
    uploadingProfilePicture: state.loading.profilePicture,
    emailUpdate: (state.auth.profile || {}).emailUpdate || null,
    isEmailVerified: state.auth.profile.isEmailVerified,
    firstNameProfile: selectProfileFirstName(state.auth.profile),
    lastNameProfile: selectProfileLastName(state.auth.profile),
    emailProfile: selectProfileEmail(state.auth.profile),
    phoneProfile: selectProfilePhone(state.auth.profile),
    roleProfile: selectProfileOrganizationRole(state.auth.profile),
    departmentProfile: selectProfileDepartment(state.auth.profile),
    orgProfileName: selectProfileOrganizationName(state.auth.profile),
    orgProfileId: selectProfileOrganizationId(state.auth),
    verifiedOrgMember: state.auth.profile.verifiedOrgMember,
    commPrefProfile: selectProfileCommunicationPref(state.auth.profile),
    emailNotification: state.auth.profile.emailNotification,
    adminNotification: state.auth.profile.adminNotification,
    verifyUpdatedPhoneActive: state.loading.activateSMS,
    activated: state.profile.activated,
    editingPhone: state.profile.editingPhone,
    smsError: state.profile.smsError,
    organizations: state.chatEdit.organizations,
    organizationFilter: state.chatList.organizationFilter,
    profileError: state.profile.profileError,
    avatars: state.chatList.avatars,
    isLoadingMessageCounts: state.loading.messageCounts,
});

export default connect(mapStateToProps, {
    updateProfile: _updateProfile,
    updateProfileFailed: _updateProfileFailed,
    setProfilePic: _setProfilePic,
    emailActivationDialog: _emailActivationDialog,
    startEmailUpdate: _startEmailUpdate,
    resendEmailUpdate: _resendEmailUpdate,
    cancelEmailUpdate: _cancelEmailUpdate,
    updatePhone: _updatePhone,
    verifyUpdatePhoneOTP: _verifyUpdatePhoneOTP,
    updatePhoneFailed: _updatePhoneFailed,
    getOrganizations: _getOrganizations,
    filterOrganizations: _filterOrganizations,
    resetFilters: _resetFilters,
    joinOrganization: _joinOrganization,
    getMessageCounts: _getMessageCounts,
    setSagaMessage: _setSagaMessage,
    loadMemberAvatars: _loadMemberAvatars,
    removeMemberAvatar: _removeMemberAvatar,
})(Profile);
