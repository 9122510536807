// @flow
import Colors from '../../Themes/Colors';
import { headerHeight } from './HeaderStyles';

const ChatListStyles = {
    borderRight: {
        borderRightWidth: '2px',
        borderRightColor: Colors.chatListItemBorder,
        borderRightStyle: 'solid',
    },
    borderLeft: {
        borderLeftWidth: '2px',
        borderLeftColor: Colors.chatListItemBorder,
        borderLeftStyle: 'solid',
    },
    paddingTopBottom: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    flex: {
        display: 'flex',
    },
    wrapper: {
        backgroundColor: Colors.chatListItemBackground,
        height: `calc(100vh - ${headerHeight}px - 2px)`,
        overflowX: 'hidden',
        overflowY: 'auto',
        zIndex: '-5',
        borderTopWidth: '2px',
        borderTopColor: Colors.chatListItemBorder,
        borderTopStyle: 'solid',
    },
    highlightWrapper: {
        borderRadius: 4,
        padding: 2,
        backgroundColor: Colors.transparentBlue,
    },
    clearButton: {
        backgroundColor: Colors.transparentBlack,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'left',
        height: 'inherit',
    },
    clearButtonIconWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    clearButtonText: {
        color: Colors.white,
        fontSize: 14,
    },
    searchResultRow: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
    },
    row: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
    },
    donorRow: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.caseListItemBorder,
        borderBottomStyle: 'solid',
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
        maxHeight: '150px',
        minHeight: '60px',
    },
    rowSelected: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        backgroundColor: Colors.lighterBlue,
        cursor: 'pointer',
        outline: 'none',
        zIndex: '-10',
    },
    donorRowSelected: {
        borderBottomWidth: '2px',
        borderBottomColor: Colors.caseListItemBorder,
        borderBottomStyle: 'solid',
        backgroundColor: Colors.lighterGray,
        outline: 'none',
        cursor: 'pointer',
        zIndex: '-10',
        minHeight: '60px',
        maxHeight: '150',
    },
    searchResultPadding: {
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 10,
        outline: 'none',
    },
    padding: {
        paddingRight: 30,
        paddingBottom: 20,
        outline: 'none',
    },
    smallPadding: {
        paddingRight: 5,
        paddingBottom: 5,
        outline: 'none',
    },
    smallLeftMargin: {
        marginLeft: 10,
    },
    highRiskTag: {
        backgroundColor: Colors.lightRed,
        color: Colors.white,
    },
    highRiskAvatar: {
        backgroundColor: Colors.red,
    },
    organTagAvatar: {
        height: 40,
        fontSize: 20,
        stroke: 'currentColor',
        strokeWidth: 4,
    },
    organTagAvatarNoStroke: {
        height: 40,
        fontSize: 20,
    },
    innerRow: {
        flexDirection: 'row',
        paddingBottom: 2,
    },
    caseTagContainer: {
        paddingTop: 4,
        paddingBottom: 4,
        flexWrap: 'wrap',
        gap: 2.5,
        maxWidth: '100%',
        display: 'inline-flex',
    },
    caseTagItem: {
        height: 25,
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: 14,
        backgroundColor: '#BFBFBF',
        color: '#2F2F2F',
        border: '1px solid #8d8686',
    },
    caseTagLabel: {
        textOverflow: 'ellipsis',
        overflow: 'auto',
        textWrap: 'nowrap',
        lineHeight: '25px',
        textAlign: 'begin',
        width: '100%',
        padding: '0 5px',
        scrollbarWidth: 'none',
    },
    filterOption: {
        display: 'grid',
        paddingLeft: 14,
    },
    donorTopRow: {
        flexDirection: 'row',
        paddingTop: 15,
    },
    donorBottomRow: {
        flexDirection: 'row',
        paddingBottom: 15,
    },
    donorChatroomRow: {
        display: 'inline-flex',
        flexDirection: 'row',
        paddingBottom: 15,
        alignItems: 'center',
    },
    donorChatroomLink: {
        color: Colors.blue,
        textDecoration: 'underline',
        fontSize: 12,
    },
    boldLabel: {
        fontSize: 16,
        color: Colors.black,
        textAlign: 'left',
        fontWeight: 'bold',
    },
    rowTitle: {
        color: Colors.black,
        fontSize: 16,
        textAlign: 'left',
    },
    rowTitleGray: {
        color: Colors.formLabel,
        fontSize: 14,
        textAlign: 'left',
    },
    rowTitleBadge: {
        fontWeight: '600',
    },
    smallRoomTitle: {
        textTransform: 'uppercase',
        color: Colors.gray,
        fontSize: 12,
    },
    countBadge: {
        alignItems: 'center',
        backgroundColor: Colors.red,
        borderColor: Colors.red,
        borderRadius: 10,
        borderWidth: 0,
        height: 21,
        justifyContent: 'center',
        marginLeft: 4,
        width: 21,
        textAlign: 'center',
        display: 'inline-block',
    },
    countBadgeText: {
        color: Colors.white,
        fontSize: 11,
    },
    rowDescription: {
        color: Colors.lightGray,
        fontSize: 12,
        marginRight: 4,
    },
    organHasChat: {
        color: Colors.brandSecondary,
        fontSize: 12,
    },
    rowDescriptionBadge: {
        color: Colors.black,
        fontWeight: '500',
    },
    personCount: {
        color: Colors.lightGray,
        fontSize: 12,
        marginLeft: 4,
    },
    mute: {
        fontSize: 18,
    },
    timestamp: {
        color: Colors.gray,
        fontSize: 12,
        paddingBottom: 5,
    },
    timestampBadge: {
        color: Colors.black,
    },
    searchRow: {
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%',
        backgroundColor: Colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchRowWithSort: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    searchBlock: {
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        backgroundColor: Colors.white,
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    caseSearchBlock: {
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%',
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        backgroundColor: Colors.white,
        top: 0,
        zIndex: 10,
    },
    searchContainer: {
        backgroundColor: Colors.lighterGray,
        borderRadius: 6,
        padding: 7,
        marginLeft: 10,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
    },
    searchInput: {
        outline: 'none',
        width: '85%',
        flex: 1,
        backgroundColor: Colors.lighterGray,
        border: 0,
        fontSize: 14,
        paddingLeft: 7,
    },
    iconSearch: {
        width: 12,
        height: 12,
    },
    searchGuideText: {
        marginLeft: 12,
        fontSize: 12,
        color: Colors.formLabel,
    },
    clearSearchButton: {
        outline: 'none',
        border: 0,
        cursor: 'pointer',
        backgroundColor: Colors.lighterGray,
        display: 'inline-flex',
        alignItems: 'center',
    },
    sortContainer: {
        paddingRight: 15,
        display: 'flex',
    },
    selectedSort: {
        color: Colors.blue,
        backgroundColor: 'rgba(0, 105, 255, 0.1)',
    },
    border: {
        borderBottomStyle: 'solid',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomWidth: '2px',
    },
    pinButton: {
        paddingLeft: 10,
        paddingBottom: 0,
        paddingTop: 5,
        paddingRight: 3,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    loadingIndicator: {
        marginTop: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingIndicatorOverlay: {
        position: 'absolute',
        inset: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255,255,255,0.7)',
    },
    noChatroomsFound: {
        fontSize: 14,
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        color: Colors.lightGray,
    },
    resultItems: {
        backgroundColor: Colors.evenLighterGray,
        paddingLeft: 20,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        borderBottomWidth: '2px',
        borderBottomColor: Colors.chatListItemBorder,
        borderBottomStyle: 'solid',
        cursor: 'pointer',
    },
    resultItemsText: {
        color: Colors.black,
        fontSize: 12,
    },
    wrapText: {
        whiteSpace: 'wrap',
    },
    caseInfoColumn: {
        padding: '10px 0 10px 10px',
    },
    caseTitle: {
        margin: '0 0 5px',
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem',
    },
    caseInfo: {
        margin: '0.25rem 0',
        fontSize: '0.75rem',
        color: '#666',
    },
    pinColumn: {
        width: '10%',
    },
    chatListTitleContainer: {
        width: '70%',
        paddingRight: 10,
    },
    timestampContainer: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 5,
        display: 'flex',
        flexDirection: 'column',
    },
    caseTagWrapper: {
        paddingTop: 5,
    },
};

export default ChatListStyles;
