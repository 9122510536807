// @flow
import * as Sentry from '@sentry/browser';
import type { Saga } from 'redux-saga';
import {
    call, put, select, delay, all,
} from 'redux-saga/effects';
import { selectProfileName, selectProfileEmail, selectUserId } from 'txp-core';

import { sendCommandAndGetResult, sendCommandNoResult } from './Socket';
import {
    setContacts,
    inviteNewUserSuccess,
    inviteNewUserFailed,
    resendNotificationSuccess,
    resendNotificationFailed,
    addSingleUserToChatroomSuccess,
    addSingleUserToChatroomFailed,
    resetContactsStatuses,
} from '../Redux/ChatroomActions';
import {
    loadChatMembers,
    loadMessages,
    loadMemberAvatars,
    loadChatrooms,
    // removeChatroom,
    // closeChatroom,
} from '../Redux/ChatListActions';
import type {
    AddUsersToChatroom,
    AddSingleUserToChatroom,
    RemoveUserFromChatroom,
    InviteNewUser,
    ToggleMute,
    MuteCase,
    SetNotificationSound,
    ResendNotification,
    DeleteChatroom,
} from '../Redux/ChatroomActions';

import type { RemovedFromChatroom } from '../Redux/ChatListActions';

import api from '../Services/Api';
import { apiPost, apiPut } from './ApiSaga';
import { parseError, singleErrorMessage } from '../Utils/flattenError';
import { pushError, setSagaMessage } from '../Redux/ApplicationActions';
import { startLoading, finishLoading } from '../Redux/LoadingActions';
import { alphaSortMemberData } from '../Utils/sort';

export function* toggleChatroomMute(action: ToggleMute): Saga<void> {
    const {
        chatId,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const isMuted = yield select((state) => state.chatList.chats[`${chatId}`].doNotDisturb);

    const { error, result, } = yield apiPut(api.txp.updateChatroomMember, { chatId, }, { do_not_disturb: !isMuted, });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Failed to mute room, are you online?', error));
        } else {
            yield put(pushError('Muting room failed, try again later', error));
        }
    } else if (result.result === 'Success') {
        // Reload chatrooms to sync server and local data
        // Future optimization: only load the new room
        yield put(loadChatrooms());
    }
}

/**
 * Mute or unmute a case. Currently this means (un)muting all chatrooms associated with the case.
 */
export function* muteCase(action: MuteCase): Saga<void> {
    const {
        caseId,
        mute,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const chatroomEntries: [string, { id: number, donorId: number }][] = yield select((state) => Object.entries(state.chatList.chats));
    // eslint-disable-next-line no-unused-vars
    const caseChatroomIds: number[] = chatroomEntries.filter(([_, chat]) => chat.donorId === caseId).map(([_, chat]) => chat.id);

    const results = yield all(
        caseChatroomIds.map((chatId) => apiPut(api.txp.updateChatroomMember, { chatId, }, { do_not_disturb: mute, }))
    );

    const errors = results.filter((r) => r.error);

    if (errors.length > 0) {
        const [error] = errors;
        let errorMessage = `Something went wrong while ${mute ? '' : 'un'}muting case rooms`;

        if (error.isValidationError && error.errors) {
            errorMessage = `${error.errors}`;
        } else if (error.isNetworkError) {
            errorMessage = `Failed to ${mute ? '' : 'un'}mute case rooms, are you online?`;
        } else {
            errorMessage = `${mute ? 'Muting' : 'Unmuting'} case rooms failed, try again later`;
        }
        yield put(pushError(errorMessage, error.errors ?? error));
        yield put(setSagaMessage('', errorMessage, ''));
    }

    // Future optimization: only load the case rooms
    yield put(loadChatrooms());
}

export function* setNotificationSound(action: SetNotificationSound): Saga<void> {
    const {
        chatId,
        notificationSound,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const { error, result, } = yield apiPut(api.txp.updateChatroomMember,
        { chatId, },
        { alert_sound: notificationSound, });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Something went wrong';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            yield put(pushError('Failed to save notification sound, are you online?', error));
        } else {
            yield put(pushError('Saving notification sound failed, try again later', error));
        }
    } else if (result.result === 'Success') {
        // Reload chatrooms to sync server and local data
        // Future optimization: only load the new room
        yield put(loadChatMembers(chatId));
    }
}

export function* inviteNewUser(action: InviteNewUser): Saga<void> {
    const {
        chatId,
        memberId,
        email,
        phone,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    // use an unsealed object to prevent flow errors in adding either phone or both
    const payload = {};
    payload.sender_information = yield select((state) => selectProfileName(state.auth), (state) => selectProfileEmail(state.auth));
    payload.chatroom_id = chatId;

    if (action.email.length > 0) {
        payload.email = email;
    } else {
        payload.phone = phone;
    }
    const { error, } = yield apiPost(api.txp.inviteNewUser, null, payload);

    if (error) {
        if (error.isValidationError) {
            const errorMessage = parseError(error);
            yield put(inviteNewUserFailed(errorMessage));
        } else if (error.isNetworkError) {
            yield put(inviteNewUserFailed('Request failed - are you offline?'));
        } else {
            // Probably a malformed request in some way.
            yield put(inviteNewUserFailed('An unexpected error occurred.'));
        }
    } else {
        yield put(inviteNewUserSuccess());
        yield put(setSagaMessage('New user invite sent!', '', ''));
        yield put(loadMessages(chatId, memberId, true, 0));
    }
}

export function* getContactsSaga(): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    yield put(startLoading('contacts'));

    const { result, error, } = yield apiPost(api.txp.getContacts, null, {
        email_list: [],
    });

    if (error) {
        if (error.isValidationError) {
            const errorMessage = error && error.errors ? error.errors : error || 'Failed to get contacts';
            yield put(pushError(`${errorMessage}`));
        } else if (error.isNetworkError) {
            Sentry.captureException(`GetContactsSaga Failed - Network Error: ${JSON.stringify(error)}`);
        } else {
            Sentry.captureException(`GetContactsSaga Failed - Other Error: ${JSON.stringify(error)}`);
        }

        yield put(finishLoading('contacts'));
    } else {
        const contactsMap = {};
        const contactsOrder = [];

        // Make sure the arrays are defined.
        const chatroomContacts = result.chatroom_contacts || [];
        const organizationContacts = result.organization_contacts || [];
        const publicContacts = result.public_contacts || [];

        // Get chatroom array, org array, and public array and remove duplicates
        const allContacts = chatroomContacts.concat(organizationContacts, publicContacts);
        for (let i = 0; i < allContacts.length; i += 1) {
            for (let j = i + 1; j < allContacts.length; j += 1) {
                if (allContacts[i].email === allContacts[j].email) allContacts.splice(j -= 1, 1);
            }
        }

        const memberDataList = allContacts.map((contact) => ({
            profile: {
                firstName: contact.first_name,
                lastName: contact.last_name,
                email: contact.email,
                phone: contact.phone,
                profilePicture: contact.profile_picture_url,
                userId: contact.user_id,
                lastInteractionTime: contact.last_interaction_time,
                // explicitly setting this because otherwise 'No Organization' is displayed, which isn't always true
                organizationName: ' ',
            },
            membershipStatus: null,
        }));

        const sortedMemberData = alphaSortMemberData(memberDataList);
        sortedMemberData.forEach((memberData) => {
            contactsOrder.push(memberData.profile.userId);
            contactsMap[memberData.profile.userId] = memberData;
        });
        yield put(setContacts(contactsMap, contactsOrder));

        yield put(loadMemberAvatars(contactsMap, contactsOrder));

        yield put(finishLoading('contacts'));
    }
}

export function* addSingleUserToChatroom(action: AddSingleUserToChatroom): Saga<void> {
    const {
        chatId,
        memberId,
        email,
        phone,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    // Clear the status
    const ack = yield call(
        sendCommandAndGetResult,
        chatId,
        memberId,
        'add-user-v2',
        [email, phone]
    );

    if (ack && ack.result) {
        // The message worked. User will be added when member list is reloaded below
        yield put(addSingleUserToChatroomSuccess());
        // The chatroom membership has changed so reload the chat members - #DontTryToDelta
        yield put(loadChatMembers(chatId));
        // Messages will have been created by the process so reload them - #KIS
        yield put(loadMessages(chatId, memberId, true, 0));
    } else {
        if (ack && ack.error.error) {
            yield put(addSingleUserToChatroomFailed(ack.error.error));
        } else if (ack && ack.error.type === 'not-txp-user') {
            yield put(addSingleUserToChatroomFailed('Not a registered user'));
        } else {
            yield put(addSingleUserToChatroomFailed('Invalid phone or email.'));
        }
        Sentry.captureException(`addSingleUserToChatroom Failed: ${JSON.stringify(ack)}`);
    }
}

export function* addUsersToChatroom(action: AddUsersToChatroom): Saga<void> {
    const {
        chatId,
        memberId,
        users,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const usersAdded = [];
    users.forEach((user) => {
        // compose object with id, email and phone here
        const userInfo = {
            userId: user.userId,
            email: user.email,
            phone: user.phone,
        };

        usersAdded.push(userInfo);
    });

    const ack = yield call(
        sendCommandAndGetResult,
        chatId,
        memberId,
        'add-multiple-users',
        [usersAdded]
    );

    if (!ack || (ack && ack.error)) {
        Sentry.captureException(`addUsersToChatroom Failed: ${JSON.stringify(ack)}`);
    }

    // The chatroom membership has changed so reload the chat members - #DontTryToDelta
    yield put(loadChatMembers(chatId));
    // Messages will have been created by the process so reload them - #KIS
    yield put(loadMessages(chatId, memberId, true, 0));
    // reset org member statuses
    yield put(resetContactsStatuses());
}

export function* removeUserFromChatroom(action: RemoveUserFromChatroom): Saga<void> {
    const {
        chatId,
        email,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'remove-member-v2', [email]);
}

export function* deleteChatroom(action: DeleteChatroom): Saga<void> {
    const {
        chatId,
    } = action;

    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(50);

    const memberId = yield select((state) => selectUserId(state.auth));
    yield call(sendCommandNoResult, chatId, memberId, 'delete-chatroom', [chatId]);
}

export function* handleDeletedChatroom(action: RemovedFromChatroom): Saga<void> {
    // Debounce the task: https://github.com/redux-saga/redux-saga/blob/master/docs/recipes/README.md#debouncing
    yield delay(400);

    const { roomId, } = action;

    // Notify the user about the removal
    const roomName = yield select((state) => state.chatList.chats[`${roomId}`] && state.chatList.chats[`${roomId}`].name);
    yield put(setSagaMessage('Room:', roomName, 'has been archived.'));

    // yield put(setMessageText(roomId, ''));
    // yield put(setMessageMedia(roomId, []));
    // yield put(setMentionedUsers(roomId, []));
    // yield put(setMentionedNames(roomId, []));
    // yield put(closeChatroom(roomId));

    // Remove chatroom from redux
    // yield put(removeChatroom(roomId));
}

export function* resendNotification(action: ResendNotification): Saga<void> {
    const memberId = yield select((state) => selectUserId(state.auth));
    const {
        chatId,
        userId,
        messageId,
    } = action;
    yield delay(50);

    const ack = yield call(
        sendCommandAndGetResult,
        chatId,
        memberId,
        'resend-notification',
        [userId, messageId]
    );

    if (ack && ack.result) {
        yield put(resendNotificationSuccess(userId, messageId));
        yield put(setSagaMessage('Notification Sent', '', ''));
    } else {
        yield put(resendNotificationFailed(singleErrorMessage(ack.error)));
        yield put(setSagaMessage('Failed to resend notification', '', ''));
        Sentry.captureException(`Resend Notification Failed:${JSON.stringify(ack)}`);
    }
}
