// @flow
import React, { PureComponent } from 'react';
import * as firebase from 'firebase/app';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Measure from 'react-measure';
import Sidebar from 'react-sidebar';
import Button from 'react-toolbox/lib/button/Button';
import { Slide, toast, ToastContainer } from 'react-toastify';
import Dialog from 'react-toolbox/lib/dialog/Dialog';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import fuzzy from 'fuzzy';
import {
    verifyAccessToken as _verifyAccessToken,
    updateProfileData as _updateProfileData,
    resetProfileError as _resetProfileError,
    selectProfileName,
    selectProfileEmail,
} from 'txp-core';

import type {
    AuthorizedEntity,
    AvatarMap,
    ChatroomInfo,
    ChatroomType,
    MessageId,
    ChatSocketStatus,
    ChatroomMessage,
    ReadStatusMap,
    ChatroomMemberMap,
    UserPermission,
    UserProfile,
    AttachmentData,
    SearchResult,
    ChatroomMember,
    Donor,
    Organ,
    DonorRestrictions,
    Team,
} from '../Utils/types';
import {
    CHAT_TAB,
    CASE_TAB,
    TEAMS_TAB,
    setSagaMessage as _setSagaMessage,
    storeDeviceToken as _storeDeviceToken,
    selectTab as _selectTab,
} from '../Redux/ApplicationActions';
import {
    setOffset as _setOffset,
    closeChatroom as _closeChatroom,
    openChatroom as _openChatroom,
    setSelectedResult as _setSelectedResult,
    loadChatFiles as _loadChatFiles,
    markMessagesRead as _markMessagesRead,
    triggerChatSocket as _triggerChatSocket,
    clearChatroomNotifications as _clearChatroomNotifications,
    filterChatrooms as _filterChatrooms,
    filterPeople as _filterPeople,
    resetFilters as _resetFilters,
    acknowledgeMessage as _acknowledgeMessage,
    startTyping as _startTyping,
    stopTyping as _stopTyping,
} from '../Redux/ChatListActions';
import {
    saveChat as _saveChat,
    setName as _setName,
    setDescription as _setDescription,
    resetChatEditState as _resetChatEditState,
    setInitialMessage as _setInitialMessage,
    setFilteredPending as _setFilteredPending,
    setMentionedPendingUsers as _setMentionedPendingUsers,
    setMentionedPendingNames as _setMentionedPendingNames,
    createOffer as _createOffer,
    setDonorCreateChat as _setDonorCreateChat,
    setOPODonorId as _setOPODonorId,
    setChatroomType as _setChatroomType,
    setOrganType as _setOrganType,
    setUNOSId as _setChatEditUnosId,
    setDonorId as _setDonorId,
    setOrganId as _setOrganId,
} from '../Redux/ChatEditActions';
import type { ChatEditState, ResearchOffer } from '../Redux/ChatEditActions';
import type { FollowerEditState } from '../Redux/FollowerEditActions';
import {
    getContacts as _getContacts,
    resetContactsStatuses as _resetContactsStatuses,
    addUsersToChatroom as _addUsersToChatroom,
    addSingleUserToChatroom as _addSingleUserToChatroom,
    removeUserFromChatroom as _removeUserFromChatroom,
    deleteChatroom as _deleteChatroom,
    inviteNewUser as _inviteNewUser,
    resetChatroomDetails as _resetChatroomDetails,
    toggleMute as _toggleMute,
    setNotificationSound as _setNotificationSound,
    setSelectedChatroomMember as _setSelectedChatroomMember,
} from '../Redux/ChatroomActions';
import { removeUserFromFollowerGroup as _removeUserFromFollowerGroup } from '../Redux/FollowerEditActions';
import {
    getTeams as _getTeams,
    openTeamCreate as _openTeamCreate,
} from '../Redux/TeamActions';
import type { ChatroomState } from '../Redux/ChatroomActions';
import {
    filterDonors as _filterDonors,
    resetCreateDonorSuccess as _resetCreateDonorSuccess,
    setUnosId as _setUnosId,
    closeDonor as _closeDonor,
} from '../Redux/DonorActions';
import {
    setAddPeopleError as _setAddPeopleError,
} from '../Redux/AddPeopleActions';
import Header from '../Components/Header';
import ChatList from '../Components/ChatList';
import ChatroomDetails from '../Components/ChatroomDetails';
import DonorPanel from '../Components/DonorPanel';
import EmailNotVerified from '../Components/EmailNotVerified';
import SagaMessage from '../Components/SagaMessage';
import ChatInput from '../Components/ChatInput';
import ChatHistory from '../Components/ChatHistory';
import CreateDonor from '../Components/CreateDonor';
import Profile from '../Components/Profile';
import Help from '../Components/Help';
import CreateChatroom from '../Components/CreateChatroom';
import sortChatrooms, { getBadgeCount } from '../Utils/sortChatrooms';
import RTStateChecker from '../Utils/RTStateChecker';

import {
    setMessageText as _setMessageText,
    setMessageMedia as _setMessageMedia,
    setMentionedUsers as _setMentionedUsers,
    setMentionedNames as _setMentionedNames,
    setFilteredNames as _setFilteredNames,
    submitMessage as _submitMessage,
    triggerAskAlan as _triggerAskAlan,
    uploadXML as _uploadXML,
    resetXMLError as _resetXMLError,
} from '../Redux/ChatMessageActions';
import {
    addPermission as _addPermission,
    addPermissionToAuthorizedEntities as _addPermissionToAuthorizedEntities,
} from '../Redux/PermissionActions';
import ChatStyles from './Styles/ChatStyles';
import ChatListStyles from '../Components/Styles/ChatListStyles';
import { device } from '../Themes/Device';
import ChatMessageStyles from '../Components/Styles/ChatMessageStyles';
import { headerHeight } from '../Components/Styles/HeaderStyles';
import Images from '../Themes/Images';
import { ApplicationMessageStrings, ImageMessageStrings, VideoMessageStrings } from '../Utils/types';
import { getDeviceToken, presentPermissionPrompt, requestNotificationPermission } from '../Services/PushNotification';
import ApplicationStyles from '../Themes/ApplicationStyles';
import { sounds } from '../Themes/Sounds';
import hasValue from '../Utils/hasValue';
import Settings from '../Components/Settings';
import MemberProfile from '../Components/MemberProfile';
import { isFutureDatetime, millisecondsUntil } from '../Utils/time';
import hasPermissions, {
    ENTITY_TYPE_UI,
    DONOR_CREATE,
    DONOR_VIEW,
    NONE,
    READ,
} from '../Utils/hasPermissions';
import TeamsTab from '../Components/TeamsTab';
import MenuBar from '../Components/MenuBar';
import DonorList from '../Components/DonorList';
import CasePreferences from '../Components/CasePreferences';

type Props = {
    isAuthenticated: ?boolean,
    isConnected: boolean,
    rooms: Array<ChatroomInfo>,
    memberId: number,
    expirationInterval: number,
    license: string,
    accessToken: string,
    profile: UserProfile,
    mediaLoading: boolean,
    permissions: Array<UserPermission>,
    isLoading: boolean,

    // Chat is null if one leaves a chat via info screen (while navigation transition)
    currentChatId: number,
    currentChat: ChatroomInfo,
    currentDonorId: ?number,
    selectedTab: string,
    filter: string,
    peopleFilter: string,
    chatMessageText: string,
    chatMessageMedia: Array<AttachmentData>,
    mentionedUsers: Array<number>,
    mentionedNames: Array<string>,
    filteredNames: Array<string>,
    memberNames: Array<string>,
    avatars: AvatarMap,

    socketStatus: ChatSocketStatus,
    selectedResult: SearchResult,
    deepSearchFilter: string,
    messages: Array<ChatroomMessage>,
    messageOrder: Array<MessageId>,
    totalMessages: number,
    isTyping: boolean,
    donorCreatingChat: boolean,
    createDonorSuccess: boolean,
    casePreferences: FollowerEditState[],

    loadMoreMessages: boolean,
    isLoadingMessages: boolean,
    readStatus: ReadStatusMap,
    chatroomState: ChatroomState,
    teams: Array<Team>,
    teamsLoading: boolean,

    editData: ChatEditState,

    donorId: ?number,
    organId: ?number,
    targetOrgId: ?number,
    xmlError: ?string,

    openChatroom: (chatId: number) => *,
    closeChatroom: (chatId: number) => *,
    openTeamCreate: () => *,
    closeDonor: (donorId: number) => *,

    triggerChatSocket: () => *,
    resetCreateDonorSuccess: () => *,
    setSelectedResult: (selectedResult: SearchResult) => *,

    loadChatFiles: (chatId: number, memberId: number) => *,
    toggleMute: (chatId: number) => *,
    setNotificationSound: (chatId: number, notificationSound: string) => *,
    setMessageText: (chatId: number, message: string) => *,
    setMessageMedia: (chatId: number, attachments: Array<AttachmentData>) => *,
    setMentionedUsers: (chatId: number, mentionedUsers: Array<number>) => *,
    setMentionedNames: (chatId: number, mentionedNames: Array<string>) => *,
    setFilteredNames: (chatId: number, filteredNames: Array<string>) => *,
    setOPODonorId: (opoDonorId: string) => *,
    setDonorId: (donorId: number) => *,
    setOrganType: (organType: string) => *,
    setChatroomType: (chatroomType: string) => *,
    setChatEditUnosId: (unosId: string) => *,
    setDonorCreateChat: (donorCreating: boolean) => *,
    submitMessage: (chatId: number) => *,
    triggerAskAlan: (
        chatId: number,
        orgId: number,
        targetOrgId: number,
        userId: number,
        donorId: ?number,
        organId: ?number,
    ) => *,
    uploadXML: (chatId: number, xml: ?AttachmentData) => *,
    resetXMLError: (chatId: number) => *,
    markMessagesRead: (chatId: number, memberId: number, untilId: MessageId) => *,
    acknowledgeMessage: (chatId: number, messageId: number, symbol: string) => *,
    startTyping: (chatId: number) => *,
    stopTyping: (chatId: number) => *,

    clearChatroomNotifications: (chatId: number) => *,
    filterChatrooms: (filter: string) => *,
    filterDonors: (filter: string) => *,
    filterPeople: (filter: string) => *,
    resetFilters: () => *,

    saveChat: (
        id: ?number,
        name: string,
        people: Array<UserProfile>,
        managed: boolean,
        message: string,
        mentioned: Array<number>,
        description: ?string,
        attachments: ?Array<AttachmentData>,
        donorId: ?number,
        organId: ?number,
        transplantCenter: ?number,
        surgeon: ?UserProfile,
        chatroomType: ChatroomType,
        restrictions: ?DonorRestrictions,
    ) => *,
    setName: (name: string) => *,
    setDescription: (description: string) => *,
    setUnosId: (unosId: number) => *,
    setOrganId: (organId: number) => *,
    setInitialMessage: (message: string, attachments: Array<AttachmentData>) => *,
    setMentionedPendingUsers: (mentionedPendingUsers: Array<number>) => *,
    setMentionedPendingNames: (mentionedPendingNames: Array<string>) => *,
    setFilteredPending: (filteredPending: Array<string>) => *,
    createOffer: (unosId: string, matchId: string, organType: string) => *,
    resetChatEditState: () => *,

    verifyAccessToken: (application: string) => void,
    storeDeviceToken: (deviceToken: string) => *,
    setOffset: (chatId: number, memberId: number, offset: number) => *,
    selectTab: (tabName: string) => *,

    getContacts: () => *,
    getTeams: () => *,
    addUsersToChatroom: (chatId: number, memberId: number, users: Array<UserProfile>) => *,
    removeUserFromChatroom: (chatId: number, memberId: number, email: string, self: boolean) => *,
    removeUserFromFollowerGroup: (donorId: number, followerId: number, userId: number) => *,
    deleteChatroom: (chatId: number) => *,
    resetContactsStatuses: () => *,
    addSingleUserToChatroom: (chatId: number, memberId: number, email: string, phone: string) => *,
    inviteNewUser: (chatId: number, memberId: number, email: string, phone: string) => *,
    resetChatroomDetails: () => *,
    setAddPeopleError: () => *,
    setSelectedChatroomMember: (selectedChatroomMember: ChatroomMember) => *,
    resetProfileError: () => *,
    updateProfileData: (user: UserProfile) => *,
    setSagaMessage: (heading: string, message: string, label: string, isDialog?: boolean) => *,

    addPermission: (
        entityType: string,
        entityId: number,
        subEntityType: ?string,
        subEntityId: ?number,
        cascade: boolean,
        read: boolean,
        update: boolean,
        canDelete: boolean,
        authorizedType: string,
        authorizedId: number,
        displayName: string,
        displayToast: boolean
    ) => *,
    addPermissionToAuthorizedEntities: (
        authorizedEntities: Array<AuthorizedEntity>,
        entityType: string,
        entityId: number,
        subEntityType: ?string,
        subEntityId: ?number,
        cascade: boolean,
        read: boolean,
        update: boolean,
        canDelete: boolean,
        displayToast: boolean
    ) => *,
};

type State = {
    chatSelected: boolean,
    chatListWidth: number,
    chatDetailsWidth: number,
    clearButtonVisible: boolean,
    createChatroomOpen: boolean,
    createChatroom: boolean,
    createDonorOpen: boolean,
    createDonorChatroomOpen: boolean,
    createInProgress: boolean,
    isLeftSidebarOpen: boolean,
    isRightUniversalSidebarOpen: boolean,
    isFileViewVisible: boolean,
    isAddPeopleVisible: boolean,
    isNotificationSoundsVisible: boolean,
    isMessageDetailsOpen: boolean,
    deviceType: string,
    showUploadThumbnail: number,
    dragging: boolean,
    showMention: boolean,
    showChatroomDetails: boolean,
    showDonorPanel: boolean,
    showProfile: boolean,
    showMemberProfile: boolean,
    showHelp: boolean,
    showSettings: boolean,
    showCDSUpload: boolean,
    showCasePreferences: boolean,
    permissionsLoaded: boolean,
};

const mobileMq = window.matchMedia(device.mobile);
const tabletMq = window.matchMedia(device.tablet);
const laptopMq = window.matchMedia(device.laptop);

const premiumFeatureToast: number = 10001;
const fileDropErrorToast: number = 10003;
const pushNotificationToast: number = 10004;
const uploadXMLToast: number = 10005;
const noUploadXMLToast: number = 10006;
const genericSagaToast: number = 10007;

class ChatPage extends PureComponent<Props, State> {
    constructor(props: Props, context: *) {
        super(props, context);

        let initialDevice = '';

        if (laptopMq.matches) {
            initialDevice = 'laptop';
        } else if (tabletMq.matches) {
            initialDevice = 'tablet';
        } else if (mobileMq.matches) {
            initialDevice = 'mobile';
        }

        this.state = {
            chatSelected: false,
            chatListWidth: -1,
            chatDetailsWidth: -1,
            clearButtonVisible: false,
            createChatroomOpen: false,
            createChatroom: false,
            createDonorOpen: false,
            createDonorChatroomOpen: false,
            createInProgress: false,
            isLeftSidebarOpen: true,
            isRightUniversalSidebarOpen: false,
            isFileViewVisible: false,
            isAddPeopleVisible: false,
            isNotificationSoundsVisible: false,
            isMessageDetailsOpen: false,
            deviceType: initialDevice,
            showUploadThumbnail: 0,
            dragging: false,
            showMention: false,
            showChatroomDetails: false,
            showDonorPanel: false,
            showProfile: false,
            showMemberProfile: false,
            showHelp: false,
            showSettings: false,
            showCDSUpload: false,
            showCasePreferences: false,
            permissionsLoaded: false,
        };
    }

    // eslint-disable-next-line consistent-return
    componentDidMount() {
        const {
            socketStatus,
            triggerChatSocket,
            isAuthenticated,
            profile,
        } = this.props;

        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }

        // listen for screen size changes
        mobileMq.addListener(this.mediaQueryChangedMobile);
        tabletMq.addListener(this.mediaQueryChangedTablet);
        laptopMq.addListener(this.mediaQueryChangedLaptop);

        // Connect to socket if needed
        if (socketStatus !== 'connected') {
            triggerChatSocket();
        }

        // attempt to reconnect if the connectivity switches from offline to online
        window.addEventListener('online', this.onTriggerChatSocket);

        // Trigger the session refresh
        this.startRefreshInterval();

        if (profile.globalDoNotDisturbEnd !== '' && isFutureDatetime(profile.globalDoNotDisturbEnd)) {
            this.onSetGlobalDoNotDisturbInterval(millisecondsUntil(profile.globalDoNotDisturbEnd));
        }

        if (this.dropRef.current) {
            const dropDiv = this.dropRef.current;
            dropDiv.addEventListener('dragenter', this.handleDragIn);
            dropDiv.addEventListener('dragleave', this.handleDragOut);
            dropDiv.addEventListener('dragover', this.handleDrag);
            dropDiv.addEventListener('drop', this.handleDrop);
        }

        if (this.noDropRef.current) {
            const noDropDiv = this.noDropRef.current;
            noDropDiv.addEventListener('dragenter', this.preventDrop);
            noDropDiv.addEventListener('dragleave', this.preventDrop);
            noDropDiv.addEventListener('dragover', this.preventDrop);
            noDropDiv.addEventListener('drop', this.preventDrop);
        }

        this.dragCounter = 0;

        if (presentPermissionPrompt()) {
            if (!toast.isActive(pushNotificationToast)) {
                toast(
                    <div style={{
                        flexDirection: 'column', textAlign: 'center', ...ApplicationStyles.toastContainer,
                    }}
                    >
                        <img src={Images.logo} alt="OmniLife Logo" />
                        <p style={{ ...ApplicationStyles.toastText, }}>OmniLife Web would like to send you push notifications when you receive new messages.</p>
                        <div>
                            <Button
                                style={{ ...ApplicationStyles.button, ...{ margin: 10, }, }}
                                type="button"
                                label="Okay"
                                ripple={false}
                                flat
                                onClick={() => this.onSetPushNotificationPermission(true)}
                            />
                            <Button
                                style={{ ...ApplicationStyles.buttonInverse, ...{ margin: 10, }, }}
                                type="button"
                                label="Later"
                                ripple={false}
                                flat
                                onClick={() => this.onSetPushNotificationPermission(false)}
                            />
                        </div>
                    </div>,
                    { toastId: pushNotificationToast, autoClose: false, }
                );
            }
        } else {
            this.initializePushNotificationService(false);
        }
    }

    componentDidUpdate(prevProps: Props) {
        const {
            messageOrder,
            editData,
            currentChatId,
            rooms,
            createDonorSuccess,
            resetCreateDonorSuccess,
            casePreferences,
            isLoading,
            selectTab,
            permissions,
            expirationInterval,
        } = this.props;

        const {
            createChatroom,
            clearButtonVisible,
            permissionsLoaded,
        } = this.state;

        if (prevProps.isLoading && !isLoading) {
            // Permissions finished loading

            const canCreateDonors = hasPermissions(permissions, ENTITY_TYPE_UI, DONOR_CREATE, NONE, NONE, READ);
            const canViewDonors = hasPermissions(permissions, ENTITY_TYPE_UI, DONOR_VIEW, NONE, NONE, READ);
            const hasCasePermissions = canViewDonors || canCreateDonors;

            // If we have already loaded permissions once, do not change tabs on the user
            if (hasCasePermissions && !permissionsLoaded) selectTab(CASE_TAB);

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                permissionsLoaded: true,
            });
        }

        const oldId = prevProps.currentChatId ? prevProps.currentChatId : null;
        const newId = currentChatId || null;

        if (newId && oldId !== newId) {
            this.onOpenChatroomClick(newId);
        }

        const chatHistory = document.getElementById('chatHistory');
        if (chatHistory !== null) {
            const canScroll = chatHistory.scrollHeight > chatHistory.clientHeight;

            // A chat is selected and there are not enough messages to fire an onScroll event, so we
            // take all messages in the chatroom and pass them into onViewableItemsChanged.
            if (this.state.chatSelected && !canScroll) {
                const messagesInView = messageOrder || [];
                this.onViewableItemsChanged(messagesInView);
            }
        }

        if (createChatroom) {
            const { pendingUsers, } = editData;
            this.pendingMembers = {};
            this.pendingMembersIds = [];
            this.pendingMemberNames = [];

            for (let i = 0; i < pendingUsers.length; i += 1) {
                const pendingUser = pendingUsers[i];
                const pendingUserId = pendingUser.userId;

                this.pendingMembersIds.push(pendingUserId);
                this.pendingMemberNames.push(selectProfileName(pendingUser));
                this.pendingMembers[pendingUserId.toString()] = {
                    membershipStatus: 'Pending',
                    profile: pendingUser,
                    notificationSound: '',
                    startDate: '',
                    endDate: '',
                    doNotDisturb: false,
                };
            }
        }

        if (!prevProps.editData.createChatroomSuccess && editData.createChatroomSuccess) {
            this.onCreateChatroomCloseClick();
        }
        if (!prevProps.createDonorSuccess && createDonorSuccess) {
            this.onCreateDonorCloseClick();
            // Only show the case preferences page if we have any
            // Could be first case for a workflow or no follower groups created
            if (casePreferences.length > 0) {
                this.onShowCasePreferences();
            } else {
                this.onShowDonorPanel();
            }
            resetCreateDonorSuccess();
        }

        // If chatroom closes then disable chat input
        if (hasValue(prevProps.currentChatId) && !hasValue(currentChatId)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                chatSelected: false,
            });
        }

        if (clearButtonVisible) {
            let unreadCount = 0;
            for (let i = 0; i < rooms.length; i += 1) {
                const badgeCount = getBadgeCount(rooms[i]);

                if (badgeCount && badgeCount > 0) {
                    unreadCount += 1;
                }
            }

            if (unreadCount === 0) {
                this.onNotificationClick();
            }
        }

        if (this.dropRef.current) {
            const dropDiv = this.dropRef.current;
            dropDiv.addEventListener('dragenter', this.handleDragIn);
            dropDiv.addEventListener('dragleave', this.handleDragOut);
            dropDiv.addEventListener('dragover', this.handleDrag);
            dropDiv.addEventListener('drop', this.handleDrop);
        }

        if (this.noDropRef.current) {
            const noDropDiv = this.noDropRef.current;
            noDropDiv.addEventListener('dragenter', this.preventDrop);
            noDropDiv.addEventListener('dragleave', this.preventDrop);
            noDropDiv.addEventListener('dragover', this.preventDrop);
            noDropDiv.addEventListener('drop', this.preventDrop);
        }

        this.dragCounter = 0;

        if (prevProps.expirationInterval !== expirationInterval) {
            // This is unlikely, but if the expiration interval is changed in Okta, reset our timer
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }

            this.startRefreshInterval();
        }
    }

    componentWillUnmount() {
        const {
            currentChat,
            currentChatId,
            closeChatroom,
        } = this.props;

        mobileMq.removeListener(this.mediaQueryChangedMobile);
        tabletMq.removeListener(this.mediaQueryChangedTablet);
        laptopMq.removeListener(this.mediaQueryChangedLaptop);

        if (currentChat && currentChatId) {
            closeChatroom(currentChatId);
        }

        // Clear the interval function so that it no longer dispatches the verifyAccessToken action
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }

        this.onSetGlobalDoNotDisturbInterval(0);

        // Remove event listeners
        window.removeEventListener('online', this.onTriggerChatSocket);

        if (navigator && navigator.serviceWorker) {
            navigator.serviceWorker.removeEventListener('message', this.messageEventListener);
        }

        if (this.dropRef.current) {
            const dropDiv = this.dropRef.current;
            dropDiv.removeEventListener('dragenter', this.handleDragIn);
            dropDiv.removeEventListener('dragleave', this.handleDragOut);
            dropDiv.removeEventListener('dragover', this.handleDrag);
            dropDiv.removeEventListener('drop', this.handleDrop);
        }

        if (this.noDropRef.current) {
            const noDropDiv = this.noDropRef.current;
            noDropDiv.removeEventListener('dragenter', this.preventDrop);
            noDropDiv.removeEventListener('dragleave', this.preventDrop);
            noDropDiv.removeEventListener('dragover', this.preventDrop);
            noDropDiv.removeEventListener('drop', this.preventDrop);
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    onSetGlobalDoNotDisturbInterval = (ms: number) => {
        const {
            profile,
            updateProfileData,
        } = this.props;

        if (ms === 0) {
            // Clear the globalDoNotDisturbInterval function so that it no longer resets globalDoNotDisturb
            if (this.globalDoNotDisturbInterval) {
                clearInterval(this.globalDoNotDisturbInterval);
                this.globalDoNotDisturbInterval = null;
            }
        } else {
            this.globalDoNotDisturbInterval = setInterval(() => {
                updateProfileData({
                    ...profile,
                    globalDoNotDisturbEnd: '',
                });
            }, ms);
        }
    };

    onSetPushNotificationPermission = (accept: boolean) => {
        toast.dismiss(pushNotificationToast);
        if (accept && navigator && navigator.serviceWorker) {
            this.initializePushNotificationService(true);
        }
    };

    onSetLeftSidebarOpen = () => {
        this.setState({
            isLeftSidebarOpen: true,
        });
    };

    onSetLeftSidebarClose = () => {
        this.setState({
            isLeftSidebarOpen: false,
            chatListWidth: 0,
        });
    };

    onSetRightUniversalSidebarOpen = () => {
        this.setState({
            isRightUniversalSidebarOpen: true,
        });
    };

    onSetRightUniversalSidebarClose = () => {
        this.setState({
            isRightUniversalSidebarOpen: false,
        });
    };

    onSetRightSidebarClose = () => {
        this.setState({
            showChatroomDetails: false,
            showDonorPanel: false,
            showMemberProfile: false,
            chatDetailsWidth: 0,
        });
    };

    onShowFiles = () => {
        const {
            currentChatId,
            memberId,
            loadChatFiles,
        } = this.props;

        loadChatFiles(currentChatId, memberId);

        this.setState({
            isFileViewVisible: true,
        });
    };

    onHideFiles = () => {
        this.setState({
            isFileViewVisible: false,
        });
    };

    onShowDonorDetails = () => {
        const {
            selectTab,
        } = this.props;
        selectTab(CASE_TAB);
        this.onShowDonorPanel();
    };

    onShowAddPeople = () => {
        const {
            getContacts,
            getTeams,
        } = this.props;

        getContacts();
        getTeams();

        this.setState({
            isAddPeopleVisible: true,
        });
    };

    onHideAddPeople = () => {
        const {
            resetContactsStatuses,
        } = this.props;

        resetContactsStatuses();

        this.setState({
            isAddPeopleVisible: false,
        });
    };

    onShowNotificationSounds = () => {
        this.setState({
            isNotificationSoundsVisible: true,
        });
    };

    onHideNotificationSounds = () => {
        this.setState({
            isNotificationSoundsVisible: false,
        });
    };

    onSetNotificationSound = (chatId: number, notificationSound: string) => {
        const {
            setNotificationSound,
        } = this.props;

        this.onHideNotificationSounds();

        setNotificationSound(chatId, notificationSound);
    };

    onOpenChatroomClick = (newChatroomId: number): void => {
        const {
            openChatroom,
            editData,
            isTyping,
        } = this.props;

        const {
            deviceType,
            createChatroomOpen,
        } = this.state;

        let inProgress = false;

        if (!createChatroomOpen) {
            this.onHideAddPeople();
            this.onHideNotificationSounds();
            this.onHideFiles();
        } else if (
            editData.name !== ''
            || editData.description !== ''
            || editData.message !== ''
            || editData.attachments.length > 0
            || editData.unosId !== ''
            || editData.matchId !== ''
            || editData.organType !== 'Kidney'
        ) {
            inProgress = true;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (isTyping) {
            this.onStopTyping();
        }

        openChatroom(newChatroomId);

        let tempLeftSideBarOpen = true;
        if (deviceType === 'mobile') {
            // close chatlist sidebar after selecting chatroom on mobile device
            tempLeftSideBarOpen = false;
        }

        this.setState({
            chatSelected: true,
            dragging: false,
            showMention: false,
            isLeftSidebarOpen: tempLeftSideBarOpen,
            createChatroomOpen: false,
            createDonorOpen: false,
            createChatroom: false,
            createInProgress: inProgress,
            showCDSUpload: false,
        });
    };

    onNotificationClick = () => {
        const {
            clearButtonVisible,
        } = this.state;

        this.setState({
            clearButtonVisible: !clearButtonVisible,
        });
    };

    onCreateTeamClick = () => {
        const {
            chatroomState,
            getContacts,
            openTeamCreate,
        } = this.props;
        const {
            deviceType,
            isLeftSidebarOpen,
        } = this.state;

        if (chatroomState.contactsOrder.length === 0) {
            getContacts();
        }

        this.onSetRightSidebarClose();
        this.setState({
            isLeftSidebarOpen: (deviceType !== 'laptop') ? false : isLeftSidebarOpen,
        });

        openTeamCreate();
    };

    onCreateChatroomClick = () => {
        const {
            chatroomState,
            getContacts,
        } = this.props;
        const {
            deviceType,
            isLeftSidebarOpen,
        } = this.state;

        // This is now open to everyone to create a chatroom so that users
        //  who are BasicUsers or are still pending can create a chatroom.
        //  We may want to go back and rethink this but it does solve the
        //  problem where users can't create a chatroom until they are
        //  accepted to their organization.
        this.onSetRightSidebarClose();

        if (chatroomState.contactsOrder.length === 0) {
            getContacts();
        }

        this.setState({
            isLeftSidebarOpen: (deviceType !== 'laptop') ? false : isLeftSidebarOpen,
            createChatroomOpen: true,
            chatSelected: false,
            createChatroom: true,
            createInProgress: false,
            showCDSUpload: false,
        });
    };

    onCreateDonorClick = () => {
        const {
            currentDonorId,
            permissions,
            closeDonor,
            setSagaMessage,
        } = this.props;

        const canCreateDonors = hasPermissions(permissions, ENTITY_TYPE_UI, DONOR_CREATE, NONE, NONE, READ);
        if (!canCreateDonors) {
            setSagaMessage('', 'Only Premium members can create donors.', '');
            return;
        }

        this.onSetRightSidebarClose();
        if (currentDonorId) {
            closeDonor(currentDonorId);
        }

        this.setState({
            createChatroomOpen: false,
            createDonorOpen: true,
            chatSelected: false,
            createChatroom: false,
            createInProgress: false,
            showCDSUpload: false,
            createDonorChatroomOpen: false,
        });
    };

    onDonorCreateChatroom = (donor: Donor, organ: Organ) => {
        const {
            setDonorCreateChat,
            setOPODonorId,
            setChatroomType,
            setOrganType,
            setChatEditUnosId,
            setDonorId,
            setOrganId,
        } = this.props;

        setDonorCreateChat(true);

        setOPODonorId(donor.opoDonorId);
        setOrganType(organ.organType);
        setChatEditUnosId(donor.unosId);

        setOrganId(organ.organId);
        setDonorId(donor.donorId);
        // Use ChatroomType in state to ensure we start on the correct tab
        setChatroomType('Donor');

        this.onCreateChatroomClick();
    };

    onSelectV2Option = (option: string) => {
        if (option === 'chatroom') {
            this.setState({
                createChatroom: true,
            });
        } else if (option === 'offer') {
            this.setState({
                createChatroom: false,
                showUploadThumbnail: 0,
            });
        }
    };

    onCreateChatroomCloseClick = () => {
        const {
            selectedTab,
            resetChatEditState,
            resetFilters,
            resetContactsStatuses,
        } = this.props;

        const {
            deviceType,
            isLeftSidebarOpen,
        } = this.state;

        // reset name, description, and errors
        resetChatEditState();
        // reset any filters (offer, organization)
        resetFilters();
        // reset contact statuses
        resetContactsStatuses();

        // if in the 'Cases' tab, we'll want to pop the donor panel back out
        if (selectedTab === CASE_TAB) {
            this.onShowDonorPanel();
        }

        this.setState({
            isLeftSidebarOpen: (deviceType !== 'laptop') ? true : isLeftSidebarOpen,
            createChatroomOpen: false,
            createChatroom: false,
            createDonorChatroomOpen: false,
            showUploadThumbnail: 0,
        });
    };

    onCreateDonorCloseClick = () => {
        const {
            resetChatEditState,
        } = this.props;

        // reset name, description, and errors
        resetChatEditState();

        this.setState({
            createChatroomOpen: false,
            createChatroom: false,
            createDonorOpen: false,
            showUploadThumbnail: 0,
        });
    };

    onCreateChatroom = () => {
        const {
            editData,
            saveChat,
            createOffer,
            donorCreatingChat,
        } = this.props;

        this.pendingMembers = {};
        this.pendingMembersIds = [];
        this.pendingMemberNames = [];

        const {
            id,
            name,
            message,
            managed,
            mentionedPendingUsers,
            description,
            attachments,
            offer,
            donorId,
            organId,
            organization,
            surgeon,
            unosId,
            matchId,
            organType,
            chatroomType,
            restrictions,
            pendingUsers,
        } = editData;

        if (chatroomType === 'Templated Case') {
            saveChat(
                id,
                name,
                pendingUsers,
                managed,
                message,
                mentionedPendingUsers,
                description,
                attachments,
                offer.donorId,
                offer.organId,
                organization.id,
                surgeon,
                chatroomType,
                null
            );
        } else if (chatroomType === 'Case' && (!donorId && !organId)) {
            if (donorCreatingChat) {
                saveChat(
                    id,
                    name,
                    pendingUsers,
                    managed,
                    message,
                    mentionedPendingUsers,
                    description,
                    attachments,
                    donorId,
                    organId,
                    organization.id,
                    null,
                    'Donor',
                    null
                );
                return;
            }
            createOffer(unosId, matchId, organType);
        } else if (chatroomType === 'Research Offer') {
            // Combine the formatted message with the chat input string putting
            //  the chat input string after the donor/organ data
            let researchOffer = this.formatInitialMessage(editData.researchOffer);
            if (message) {
                researchOffer += `\n\n\n${message}`;
            }

            saveChat(
                id,
                name,
                pendingUsers,
                false,
                researchOffer,
                mentionedPendingUsers,
                description,
                attachments,
                donorId,
                organId,
                null,
                null,
                chatroomType,
                null
            );
        } else if (chatroomType === 'Donor Only') {
            saveChat(
                id,
                name,
                pendingUsers,
                managed,
                message,
                mentionedPendingUsers,
                description,
                attachments,
                donorId,
                null,
                null,
                null,
                chatroomType,
                restrictions
            );
        } else {
            saveChat(
                id,
                name,
                pendingUsers,
                managed,
                message,
                mentionedPendingUsers,
                description,
                attachments,
                donorId,
                organId,
                null,
                null,
                chatroomType,
                null
            );
        }
    };

    onEditChatroomMetadata = () => {
        const {
            editData,
            saveChat,
            resetChatEditState,
        } = this.props;

        const {
            id,
            name,
            description,
            managed,
            chatroomType,
        } = editData;

        saveChat(id, name, [], managed, '', [], description, null, null, null, null, null, chatroomType);

        resetChatEditState();
    };

    onSetName = (name: string) => {
        const { setName, } = this.props;

        setName(name);
    };

    onSetUnosId = (unosId: any) => {
        const { setUnosId, } = this.props;

        setUnosId(unosId.target.value);
    };

    onSetDescription = (description: any) => {
        const { setDescription, } = this.props;

        setDescription(description.target.value);
    };

    onToggleMute = (chatroomId: number) => {
        const { toggleMute, } = this.props;

        toggleMute(chatroomId);
    };

    onSend = () => {
        const {
            currentChatId,
            isTyping,
            submitMessage,
            setMentionedNames,
        } = this.props;

        const {
            createChatroom,
        } = this.state;

        if (createChatroom) {
            this.onCreateChatroom();
        } else {
            submitMessage(currentChatId);
        }

        this.focusChatInput();

        this.setState({
            showMention: false,
            showUploadThumbnail: 0,
        });

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        if (isTyping) {
            this.onStopTyping();
        }

        setMentionedNames(currentChatId, []);

        ReactTooltip.hide();
    };

    onTriggerAskAlan = () => {
        const {
            currentChatId,
            memberId,
            profile,
            editData,
            donorId,
            organId,
            targetOrgId,
            triggerAskAlan,
            license,
        } = this.props;

        if (license !== 'Premium') {
            this.displayPremiumToast();
            return;
        }

        const isTemplatedCase = editData.chatroomType === 'Templated Case';

        const orgId = profile.organizationId || 0;
        const targOrgId = (isTemplatedCase ? editData.organization.id : targetOrgId) || 0;

        if (isTemplatedCase) {
            triggerAskAlan(0, orgId, targOrgId, memberId, editData.donorId, editData.organId);
        } else {
            triggerAskAlan(currentChatId, orgId, targOrgId, memberId, donorId, organId);
        }
    };

    onStopTyping = () => {
        const {
            currentChatId,
            stopTyping,
        } = this.props;

        stopTyping(currentChatId);
    };

    onSetMessage = (text: any) => {
        const {
            currentChatId,
            chatMessageText,
            mentionedUsers,
            mentionedNames,
            memberNames,
            isTyping,
            editData,
            setMessageText,
            setInitialMessage,
            setMentionedUsers,
            setMentionedNames,
            setFilteredNames,
            setFilteredPending,
            startTyping,
        } = this.props;

        const {
            createChatroom,
        } = this.state;

        // don't send start and stop typing sockets if the user is typing a message for a new chatroom
        if (!createChatroom) {
            if (!isTyping) {
                startTyping(currentChatId);
            }
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.timeout = setTimeout(this.onStopTyping, 3000);
        }

        const message = createChatroom ? editData.message : chatMessageText;

        const names = createChatroom ? this.pendingMemberNames : memberNames;

        if (text.native) {
            this.setState({
                showMention: false,
            });

            const textAndEmoji = message ? message + text.native : text.native;

            if (createChatroom) {
                setInitialMessage(textAndEmoji, editData.attachments);
            } else {
                setMessageText(currentChatId, textAndEmoji);
            }
            this.focusChatInput();
        } else {
            if (text.target.value === '') {
                setMentionedUsers(currentChatId, []);
                setMentionedNames(currentChatId, []);
                setFilteredNames(currentChatId, names);
                this.setState({
                    showMention: false,
                });
            } else {
                if (/@(\S+)$/.test(text.target.value) || /[@]$/g.test(text.target.value)) {
                    this.setState({
                        showMention: true,
                    });

                    const typedName = text.target.value.match(/@(\S+)$/);
                    if (typedName) {
                        const results = fuzzy.filter(typedName[1], names).map((name) => name.string);
                        if (createChatroom) {
                            setFilteredPending(results);
                        } else {
                            setFilteredNames(currentChatId, results);
                        }
                        if (results.length === 0) {
                            this.setState({
                                showMention: false,
                            });
                        }
                    } else if (typedName === null) {
                        setFilteredNames(currentChatId, names);
                    }
                } else if (!/@(\S+)$/.test(text.target.value)) {
                    setFilteredNames(currentChatId, names);
                    this.setState({
                        showMention: false,
                    });
                }

                for (let i = 0; i < mentionedNames.length; i += 1) {
                    if (!text.target.value.includes(mentionedNames[i])) {
                        mentionedNames.splice(i, 1);
                        mentionedUsers.splice(i, 1);
                    }
                }

                setMentionedNames(currentChatId, mentionedNames);
                setMentionedUsers(currentChatId, mentionedUsers);
            }

            if (createChatroom) {
                setInitialMessage(text.target.value, editData.attachments);
            } else {
                setMessageText(currentChatId, text.target.value);
            }
        }
    };

    onSetMediaMessage = (attachments: Array<AttachmentData>) => {
        const {
            currentChatId,
            editData,
            setMessageMedia,
            setInitialMessage,
        } = this.props;

        const {
            createChatroom,
        } = this.state;

        if (createChatroom) {
            setInitialMessage(editData.message, attachments);
        } else {
            setMessageMedia(currentChatId, attachments);
        }

        this.setState({
            showUploadThumbnail: attachments.length,
        });
    };

    onOpenUploadMedia = () => {
        this.setState({
            dragging: true,
        });
    };

    onLoadMoreMessages = () => {
        const {
            currentChat,
            memberId,
            setOffset,
        } = this.props;

        if (setOffset && currentChat && currentChat.id) {
            // Set the new offset to the number of messages we currently have.
            const newOffset = (currentChat.messageOrder) ? currentChat.messageOrder.length : 0;
            setOffset(currentChat.id, memberId, newOffset);
        }
    };

    // The message details dialog is in ChatItem, but I need to keep track of
    // whether the it is opened or closed for the onScroll event.
    onOpenMessageDetails = () => {
        this.setState({
            isMessageDetailsOpen: true,
        });
    };

    onCloseMessageDetails = () => {
        this.setState({
            isMessageDetailsOpen: false,
        });
    };

    onScroll = (event: any) => {
        const { messageOrder, loadMoreMessages, } = this.props;
        const {
            dragging,
            chatSelected,
            isMessageDetailsOpen,
            createChatroomOpen,
        } = this.state;
        if (dragging) {
            return;
        }
        if (chatSelected && !isMessageDetailsOpen && !createChatroomOpen) {
            const chatHistoryDiv = event.nativeEvent.srcElement;
            if (chatHistoryDiv) {
                // get position of top and bottom of viewable chat history area
                const chatHistoryDivTop = chatHistoryDiv.scrollHeight - chatHistoryDiv.scrollTop;
                const chatHistoryDivBottom = chatHistoryDivTop - chatHistoryDiv.clientHeight;
                const messageIds = messageOrder || [];
                let first; // top most message in view
                let last; // bottom most message in view
                let offset = 0;
                // Instead of digging into chatHistoryDiv children,
                // directly select the messages container by its id.
                const messageContainer = document.getElementById('message');
                // If loadMoreMessages is true, use the container's offsetTop as the initial offset.
                if (loadMoreMessages && messageContainer) {
                    offset = messageContainer.offsetTop;
                }
                // Loop over the messages inside the message container.
                for (let i = 0; i < messageIds.length; i += 1) {
                    // Make sure the message element exists before accessing clientHeight.
                    const messageEl = messageContainer && messageContainer.children[i];
                    if (!messageEl) break;
                    const height = messageEl.clientHeight;
                    if (chatHistoryDiv.scrollHeight - offset - (height * (2 / 3)) < chatHistoryDivTop && first === undefined) {
                        first = i;
                    } else if (first !== undefined && chatHistoryDiv.scrollHeight - offset - (height * (13 / 10)) < chatHistoryDivBottom) {
                        last = i;
                        break;
                    }
                    offset += height;
                }
                if (!last && messageIds.length > 0) {
                    last = messageIds.length - 1;
                }
                if (first !== undefined && last !== undefined) {
                    const viewableItems = messageIds.slice(first, last + 1);
                    this.onViewableItemsChanged(viewableItems);
                }
            }
        }
    };

    onViewableItemsChanged = (viewableItems: Array<MessageId>) => {
        const { dragging, } = this.state;

        if (dragging) {
            return;
        }
        const { currentChat, messages, messageOrder, } = this.props;
        if (currentChat && messages && viewableItems && viewableItems.length > 0) {
            // Simplified. Previously tracked lastVisible in state, compared here and only updated if it had changed.
            //             That left a gap where new messages have been received this session before the chatroom was opened (not uncommon)
            //             componentDidUpdate would fire and trigger this method before the messages were retrieved into state
            //             In turn, the newest message was marked as both read and the last visible so nothing earlier was checked when
            //             the messages did finally load. The timing of async events made this unpredictable
            //             This simpler version always checks for unread messages at or before the current last read message
            //             This might mean traversing this array more often than scrictly necessary but that is probably a sub-ms operation
            //             The simplicity also means future errors will be easier to identify.
            const lastVisibleId = viewableItems[viewableItems.length - 1];
            const indexOfLast = messageOrder.indexOf(lastVisibleId);
            if (indexOfLast > 0) {
                for (let i = indexOfLast; i >= 0; i -= 1) {
                    const messageId = messageOrder[i];
                    if (!currentChat.messages[`${messageId}`] || !currentChat.messages[`${messageId}`].seenByCurrentUser) {
                        this.commitMessagesRead(messageId);
                        break;
                    }
                }
            }
        }
    };

    onTriggerChatSocket = () => {
        const {
            triggerChatSocket,
        } = this.props;

        triggerChatSocket();

        ReactTooltip.hide();
    };

    onSearchPeople = (filter: string) => {
        const {
            filterPeople,
        } = this.props;

        filterPeople(filter);
    };

    onSearchChatrooms = (filter: string) => {
        const {
            filterChatrooms,
        } = this.props;

        filterChatrooms(filter);
    };

    onSearchDonors = (filter: string) => {
        const {
            filterDonors,
        } = this.props;

        filterDonors(filter);
    };

    onShowChatroomDetails = () => {
        this.setState({
            showChatroomDetails: true,
            showProfile: false,
            showMemberProfile: false,
            showHelp: false,
            showSettings: false,
            isRightUniversalSidebarOpen: false,
        });
    };

    onShowDonorPanel = () => {
        const { deviceType, } = this.state;
        let tempLeftSideBarOpen = true;
        if (deviceType === 'mobile') {
            // close chatlist sidebar after selecting chatroom on mobile device
            tempLeftSideBarOpen = false;
        }

        this.setState({
            showDonorPanel: true,
            showChatroomDetails: false,
            showProfile: false,
            showMemberProfile: false,
            showHelp: false,
            showSettings: false,
            isLeftSidebarOpen: tempLeftSideBarOpen,
            isRightUniversalSidebarOpen: false,
        });
    };

    onShowCasePreferences = () => {
        this.setState({
            showDonorPanel: false,
            showChatroomDetails: false,
            showProfile: false,
            showMemberProfile: false,
            showHelp: false,
            showSettings: false,
            showCasePreferences: true,
            isLeftSidebarOpen: true,
            isRightUniversalSidebarOpen: false,
        });
    };

    onCloseCasePreferences = () => {
        this.setState({
            showDonorPanel: true,
            showCasePreferences: false,
        });
    }

    onShowProfile = () => {
        const {
            isLeftSidebarOpen,
            deviceType,
        } = this.state;

        this.setState({
            isLeftSidebarOpen: (deviceType === 'mobile') ? false : isLeftSidebarOpen,
            showChatroomDetails: false,
            showDonorPanel: false,
            showProfile: true,
            showMemberProfile: false,
            showHelp: false,
            showSettings: false,
            isRightUniversalSidebarOpen: true,
        });
    };

    onOpenMenu = () => {
        this.setState({
            showDonorPanel: false,
            isRightUniversalSidebarOpen: false,
            showChatroomDetails: false,
            showMemberProfile: false,
        });
    };

    onShowMemberProfile = (selectedChatroomMember: ChatroomMember) => {
        const {
            setSelectedChatroomMember,
        } = this.props;

        setSelectedChatroomMember(selectedChatroomMember);

        this.setState({
            showProfile: false,
            showMemberProfile: true,
            showHelp: false,
            showSettings: false,
            isRightUniversalSidebarOpen: false,
        });
    };

    onShowHelp = () => {
        const {
            isLeftSidebarOpen,
            deviceType,
        } = this.state;

        this.setState({
            isLeftSidebarOpen: (deviceType === 'mobile') ? false : isLeftSidebarOpen,
            showChatroomDetails: false,
            showDonorPanel: false,
            showHelp: true,
            showProfile: false,
            showMemberProfile: false,
            showSettings: false,
            isRightUniversalSidebarOpen: true,
        });
    };

    onShowSettings = () => {
        const {
            isLeftSidebarOpen,
            deviceType,
        } = this.state;

        this.setState({
            isLeftSidebarOpen: (deviceType === 'mobile') ? false : isLeftSidebarOpen,
            showChatroomDetails: false,
            showDonorPanel: false,
            showSettings: true,
            showHelp: false,
            showProfile: false,
            showMemberProfile: false,
            isRightUniversalSidebarOpen: true,
        });
    };

    onShowUpload = () => {
        this.setState({
            showChatroomDetails: false,
            showDonorPanel: false,
            showSettings: false,
            showHelp: false,
            showProfile: false,
            showMemberProfile: false,
            isRightUniversalSidebarOpen: false,
            createChatroomOpen: false,
            createDonorOpen: false,
            chatSelected: false,
            createChatroom: false,
            showCDSUpload: true,
        });
    };

    onCloseChatroomDetails = () => {
        this.setState({
            showChatroomDetails: false,
        });
    };

    onCloseDonorPanel = () => {
        this.setState({
            showDonorPanel: false,
        });
    };

    onCreateDonorChatroom = () => {
        this.setState({
            createDonorChatroomOpen: true,
            createChatroom: true,
            showDonorPanel: false,
            createDonorOpen: false,
        });
    };

    onMemberProfileCloseClick = () => {
        const {
            setSelectedChatroomMember,
        } = this.props;

        this.setState({
            showMemberProfile: false,
        });

        setSelectedChatroomMember({});
    };

    onProfileCloseClick = () => {
        const {
            resetProfileError,
        } = this.props;
        const {
            deviceType,
            isLeftSidebarOpen,
        } = this.state;

        this.setState({
            showProfile: false,
            isRightUniversalSidebarOpen: false,
            isLeftSidebarOpen: (deviceType === 'mobile') ? true : isLeftSidebarOpen,
        });

        resetProfileError();
    };

    onHelpCloseClick = () => {
        const {
            deviceType,
            isLeftSidebarOpen,
        } = this.state;

        this.setState({
            showHelp: false,
            isRightUniversalSidebarOpen: false,
            isLeftSidebarOpen: (deviceType === 'mobile') ? true : isLeftSidebarOpen,
        });
    };

    onSettingsCloseClick = () => {
        const {
            deviceType,
            isLeftSidebarOpen,
        } = this.state;

        this.setState({
            showHelp: false,
            isRightUniversalSidebarOpen: false,
            isLeftSidebarOpen: (deviceType === 'mobile') ? true : isLeftSidebarOpen,
        });
    };

    onCDSUploadClose = () => {
        this.setState({
            showCDSUpload: false,
        });
    };

    onCloseXMLToast = () => {
        const {
            currentChatId,
            resetXMLError,
        } = this.props;

        resetXMLError(currentChatId);
    };

    onGoToMessage = (chatId: number, selectedResult: SearchResult) => {
        const {
            openChatroom,
            setSelectedResult,
        } = this.props;

        this.setState({
            chatSelected: true,
        });

        setSelectedResult(selectedResult);

        openChatroom(chatId);
    };

    setTemplatedMessage = async (message: string) => {
        const {
            editData,
            setInitialMessage,
        } = this.props;

        setInitialMessage(message, editData.attachments);
    };

    setChatListWidth = (width: number) => {
        this.setState({
            chatListWidth: width,
        });
    };

    setChatDetailsWidth = (width: number) => {
        this.setState({
            chatDetailsWidth: width,
        });
    };

    formatInitialMessage = (message: ResearchOffer) => {
        const { editData, } = this.props;
        const {
            details,
            flush,
            serologies,
            heart,
            liver,
            kidney,
            liverVisual,
            organs,
            backupOrgans,
        } = message;

        // Details
        let formattedMessage = 'Case Details/History:\n';
        formattedMessage += (editData.unosId) ? `\tUNOS ID: ${editData.unosId}\n` : '';
        formattedMessage += (details.consent) ? `\tConsent for research: ${details.consent.value}\n` : '';
        formattedMessage += (details.age) ? `\t${details.age.displayName}: ${details.age.value} ${details.ageUnit.value}\n` : '';
        formattedMessage += (details.donorType) ? `\t${details.donorType.displayName}: ${details.donorType.value}\n` : '';
        formattedMessage += (details.wit) ? `\t${details.wit.displayName}: ${details.wit.value} minutes\n` : '';
        formattedMessage += (details.cit) ? `\t${details.cit.displayName}: ${details.cit.value} hours\n` : '';
        formattedMessage += (details.bmi) ? `\t${details.bmi.displayName}: ${details.bmi.value}\n` : '';
        formattedMessage += (details.chemo) ? `\t${details.chemo.displayName}: ${details.chemo.value}\n` : '';
        formattedMessage += (details.radiation) ? `\t${details.radiation.displayName}: ${details.radiation.value}\n` : '';

        // Flush
        formattedMessage += '\nFlush:\n';
        Object.keys(flush).forEach((key) => {
            formattedMessage += `\t${flush[key].displayName}: ${flush[key].value}\n`;
        });

        // Organs
        formattedMessage += '\nOrgans:\n';
        formattedMessage += '\tPrimary Offers:\n';
        formattedMessage += (organs.length > 0) ? `\t\t${organs.join('\n\t\t')}\n` : '\t\tNone Selected\n';
        formattedMessage += '\tBackup Offers:\n';
        formattedMessage += (backupOrgans.length > 0) ? `\t\t${backupOrgans.join('\n\t\t')}\n` : '\t\tNone Selected\n';

        // Serologies
        formattedMessage += '\nSerologies:\n';
        Object.keys(serologies).forEach((key) => {
            formattedMessage += `\t${serologies[key].displayName}: ${serologies[key].value}\n`;
        });

        // Kidney
        if (organs.includes('Kidney') || backupOrgans.includes('Kidney')) {
            formattedMessage += '\nKidney:\n';
            Object.keys(kidney).forEach((key) => {
                formattedMessage += `\t${kidney[key].displayName}: ${kidney[key].value}\n`;
            });
        }

        // Liver
        if (organs.includes('Liver') || backupOrgans.includes('Liver')) {
            formattedMessage += '\nLiver:\n';
            Object.keys(liver).forEach((key) => {
                formattedMessage += `\t${liver[key].displayName}: ${liver[key].value}\n`;
            });
            formattedMessage += `\tVisual: ${liverVisual.join(', ')}\n`;
        }

        // Heart
        if (organs.includes('Heart') || backupOrgans.includes('Heart')) {
            formattedMessage += '\nHeart:\n';
            Object.keys(heart).forEach((key) => {
                formattedMessage += `\t${heart[key].displayName}: ${heart[key].value}\n`;
            });
        }

        return formattedMessage;
    };

    timeout: ?TimeoutID = null;

    pendingMembers: ChatroomMemberMap = {};

    pendingMembersIds: Array<number> = [];

    pendingMemberNames: Array<string> = [];

    sendAck = (emoji: string, messageId: MessageId) => {
        const {
            currentChatId,
            acknowledgeMessage,
        } = this.props;

        const msgId = parseInt(messageId, 10);

        let comp;

        if (emoji.length === 1) {
            comp = emoji.charCodeAt(0);
        } else {
            comp = (
                (emoji.charCodeAt(0) - 0xD800) * 0x400
                + (emoji.charCodeAt(1) - 0xDC00) + 0x10000
            );

            if (comp < 0) {
                comp = emoji.charCodeAt(0);
            }
        }

        acknowledgeMessage(currentChatId, msgId, `U+${comp.toString(16).toUpperCase()}`);
    };

    initializePushNotificationService = (requestPermission: boolean) => {
        if (navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistration('').then((reg) => {
                try {
                    firebase.messaging().useServiceWorker(reg);
                } catch (error) {
                    // TODO do something with error
                }

                if (requestPermission) {
                    requestNotificationPermission()
                        .then((deviceToken) => {
                            const { storeDeviceToken, } = this.props;
                            if (deviceToken) {
                                storeDeviceToken(deviceToken);
                                this.handleUnload(deviceToken);
                            }
                        });
                } else {
                    getDeviceToken()
                        .then((deviceToken) => {
                            const { storeDeviceToken, } = this.props;
                            if (deviceToken) {
                                storeDeviceToken(deviceToken);
                                this.handleUnload(deviceToken);
                            }
                        });
                }
            });
        }
        if (navigator && navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', this.messageEventListener);
        }
    };

    closeMention = () => {
        this.setState({
            showMention: false,
        });
    };

    focusChatInput = () => {
        const chatInput = document.getElementById('chatInput');
        if (chatInput) {
            chatInput.focus();
        }
    };

    addMentionedUser = (memberId: number, members: ChatroomMemberMap) => {
        const {
            currentChatId,
            chatMessageText,
            editData,
            mentionedUsers,
            mentionedNames,
            memberNames,
            setMessageText,
            setMentionedUsers,
            setMentionedNames,
            setFilteredNames,
            setInitialMessage,
            setMentionedPendingUsers,
            setMentionedPendingNames,
            setFilteredPending,
        } = this.props;

        const {
            createChatroom,
        } = this.state;

        let newMessage = '';
        /* eslint-disable-next-line max-len */
        const mentioned = selectProfileName(members[memberId.toString()].profile, selectProfileEmail(members[memberId.toString()].profile));

        const messageText = createChatroom ? editData.message : chatMessageText;
        const mentionIds = createChatroom ? editData.mentionedPendingUsers : mentionedUsers;
        const mentionNames = createChatroom ? editData.mentionedPendingNames : mentionedNames;

        const result = messageText.match(/@(\S+)$/);

        if (result) {
            const { length, } = result[1];
            const index = result.index + 1;

            const beforeMention = messageText.slice(0, index);
            const afterMention = messageText.slice(index + length);

            newMessage = `${beforeMention
                + mentioned} ${
                afterMention}`;
        } else {
            newMessage = `${messageText
                + mentioned} `;
        }

        if (!mentionIds.includes(memberId) && !mentionIds.includes(`@${mentioned}`)) {
            mentionIds.push(memberId);
            mentionNames.push(`@${mentioned}`);
        }

        if (createChatroom) {
            setInitialMessage(newMessage, editData.attachments);
            setMentionedPendingUsers(mentionIds);
            setMentionedPendingNames(mentionNames);
            setFilteredPending(this.pendingMemberNames);
        } else {
            setMessageText(currentChatId, newMessage);
            setMentionedUsers(currentChatId, mentionIds);
            setMentionedNames(currentChatId, mentionNames);
            setFilteredNames(currentChatId, memberNames);
        }

        this.closeMention();
        this.focusChatInput();
    };

    handleUnload = (deviceToken: string) => {
        const { accessToken, } = this.props;
        window.onbeforeunload = () => {
            $.ajax({
                headers: {
                    Apikey: process.env.REACT_APP_API_KEY || '',
                    Authorization: `Bearer ${accessToken}`,
                },
                data: {
                    fcm_registration_token: deviceToken,
                },
                url: `${process.env.REACT_APP_API_ROOT || ''}user/devices/me`,
                method: 'DELETE',
                async: false,
            });
        };
    };

    mediaQueryChangedMobile = (event: any) => {
        if (!event.matches) return;
        this.setState({
            deviceType: 'mobile',
        });
    };

    mediaQueryChangedTablet = (event: any) => {
        if (!event.matches) return;
        this.setState({
            isLeftSidebarOpen: tabletMq.matches,
            deviceType: 'tablet',
        });
    };

    mediaQueryChangedLaptop = (event: any) => {
        if (!event.matches) return;
        this.setState({
            deviceType: 'laptop',
        });
    };

    addUsersToChatroom = (pendingUsers: Array<UserProfile>) => {
        const {
            currentChatId,
            memberId,
            addUsersToChatroom,
        } = this.props;

        this.setState({
            isAddPeopleVisible: false,
        });

        addUsersToChatroom(currentChatId, memberId, pendingUsers);
    };

    removeUserFromChatroom = (email: string, self: boolean) => {
        const {
            currentChatId,
            memberId,
            removeUserFromChatroom,
            closeChatroom,
            setMessageText,
            setMessageMedia,
            setMentionedUsers,
            setMentionedNames,
        } = this.props;

        if (self) {
            this.onSetRightSidebarClose();

            setMessageText(currentChatId, '');
            setMessageMedia(currentChatId, []);
            setMentionedUsers(currentChatId, []);
            setMentionedNames(currentChatId, []);

            closeChatroom(currentChatId);
        }

        removeUserFromChatroom(currentChatId, memberId, email, self);
    };

    removeUserFromFollowerGroup = (donorId: number, followerId: number) => {
        const {
            currentChatId,
            memberId,
            removeUserFromFollowerGroup,
            closeChatroom,
            setMessageText,
            setMessageMedia,
            setMentionedUsers,
            setMentionedNames,
        } = this.props;

        this.onSetRightSidebarClose();

        setMessageText(currentChatId, '');
        setMessageMedia(currentChatId, []);
        setMentionedUsers(currentChatId, []);
        setMentionedNames(currentChatId, []);

        closeChatroom(currentChatId);
        removeUserFromFollowerGroup(donorId, followerId, memberId);
    };

    deleteChatroom = () => {
        const {
            currentChatId,
            deleteChatroom,
            closeChatroom,
        } = this.props;

        this.onSetRightSidebarClose();
        closeChatroom(currentChatId);
        deleteChatroom(currentChatId);
    }

    displayPremiumToast = () => {
        if (!toast.isActive(premiumFeatureToast)) {
            const messageLine1 = 'This feature is for premium OmniLife users only.';
            const messageLine2 = 'Please contact your OmniLife account manager or visit omnilife.health';
            toast(
                <div>
                    <p>{messageLine1}</p>
                    <p>{messageLine2}</p>
                </div>,
                { toastId: premiumFeatureToast, }
            );
        }
    };

    inviteExistingUser = (email: string, phone: string) => {
        const {
            currentChatId,
            memberId,
            addSingleUserToChatroom,
        } = this.props;

        addSingleUserToChatroom(currentChatId, memberId, email, phone);
    };

    inviteNewUser = (email: string, phone: string) => {
        const {
            currentChatId,
            memberId,
            inviteNewUser,
        } = this.props;

        inviteNewUser(currentChatId, memberId, email, phone);
    };

    clearMessage = () => {
        const {
            resetChatroomDetails,
            setAddPeopleError,
        } = this.props;

        resetChatroomDetails();
        setAddPeopleError();
    };

    clearNotifications = (roomId: number) => {
        const {
            clearChatroomNotifications,
        } = this.props;

        clearChatroomNotifications(roomId);
    };

    commitMessagesRead = (lastVisibleId: MessageId) => {
        const {
            currentChat,
            markMessagesRead,
            memberId,
        } = this.props;

        if (currentChat && currentChat.messageOrder && lastVisibleId) {
            markMessagesRead(currentChat.id, memberId, lastVisibleId);
        }
    };

    interval = null;

    lastRefreshTime = null;

    globalDoNotDisturbInterval = null;

    uploadXMLFailure = () => {
        const {
            xmlError,
        } = this.props;

        if (xmlError) {
            if (!toast.isActive(uploadXMLToast)) {
                toast(
                    <div style={{ ...ApplicationStyles.toastContainer, }}>
                        <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                        <p style={{ ...ApplicationStyles.toastText, }}>{xmlError}</p>
                    </div>,
                    { toastId: uploadXMLToast, onClose: this.onCloseXMLToast, }
                );
            }
        }
    };

    noDropRef = React.createRef();

    dropRef = React.createRef();

    fileUploadRef: {| current: null | HTMLInputElement |} = React.createRef();

    dragCounter = 0;

    preventDrop = (event: any) => {
        event.preventDefault();
    };

    handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    handleDragIn = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            chatSelected,
            createChatroom,
        } = this.state;

        if (chatSelected || createChatroom) {
            this.dragCounter += 1;
            this.setState({ dragging: true, });
        }
    };

    handleDragOut = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            chatSelected,
            createChatroom,
        } = this.state;

        if (chatSelected || createChatroom) {
            this.dragCounter -= 1;
            if (this.dragCounter === 0) {
                this.setState({ dragging: false, });
            }
        }
    };

    // Event called when file is dropped in drag-and-drop zone
    handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            this.processMedia(event.dataTransfer.files);
        }

        event.dataTransfer.clearData();
    };

    // Event called when file is selected from file list
    handleSubmit = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        const {
            chatSelected,
            createChatroom,
        } = this.state;

        const canUpload = chatSelected || createChatroom;

        if (canUpload && this.fileUploadRef.current && this.fileUploadRef.current.files && this.fileUploadRef.current.files.length > 0) {
            const { files, } = this.fileUploadRef.current;
            for (let i = 0; i < files.length; i += 1) {
                this.handleFiles(files[i]);
            }
        } else {
            if (!toast.isActive(fileDropErrorToast)) {
                toast(
                    <div style={{ ...ApplicationStyles.toastContainer, }}>
                        <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                        <p style={{ ...ApplicationStyles.toastText, }}>Before uploading an image, please select a room.</p>
                    </div>,
                    { toastId: fileDropErrorToast, }
                );
            }
            this.setState({ dragging: false, });
        }
    };

    handleFiles = (file: any) => {
        const {
            currentChatId,
            donorId,
            organId,
            editData,
            chatMessageMedia,
            setMessageMedia,
            setInitialMessage,
            uploadXML,
        } = this.props;

        const {
            createChatroom,
        } = this.state;

        let mediaArray = [];
        if (createChatroom) {
            mediaArray = editData.attachments;
        } else {
            mediaArray = chatMessageMedia;
        }

        if (file.type === 'text/xml') {
            if (donorId === null && organId === null) {
                uploadXML(currentChatId, {
                    file,
                    fileName: file.name,
                    path: URL.createObjectURL(file),
                    mime: file.type,
                    size: file.size,
                    displayName: null,
                });
            } else if (!toast.isActive(fileDropErrorToast)) {
                toast(
                    <div style={{ ...ApplicationStyles.toastContainer, }}>
                        <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                        <p style={{ ...ApplicationStyles.toastText, }}>An XML file has already been uploaded to this room.</p>
                    </div>,
                    { toastId: noUploadXMLToast, }
                );
            }
        } else {
            // Store file object
            mediaArray.push({
                file,
                fileName: file.name,
                path: URL.createObjectURL(file),
                mime: file.type,
                size: file.size,
                displayName: null,
            });

            // Remove duplicate objects from attachments array before setting state
            // https://stackoverflow.com/questions/45439961/remove-duplicate-values-from-an-array-of-objects-in-javascript
            const result = mediaArray.reduce((unique, o) => {
                if (!unique.some((obj) => obj.fileName === o.fileName)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            if (result.length < mediaArray.length) {
                if (!toast.isActive(noUploadXMLToast)) {
                    toast(
                        <div style={{ ...ApplicationStyles.toastContainer, }}>
                            <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                            <p style={{ ...ApplicationStyles.toastText, }}>A file with the same name is already being uploaded.</p>
                        </div>,
                        { toastId: noUploadXMLToast, }
                    );
                }
            }

            if (createChatroom) {
                setInitialMessage(editData.message, result);
            } else {
                setMessageMedia(currentChatId, result);
            }
            this.focusChatInput();
        }
        this.setState({
            dragging: false,
            showUploadThumbnail: mediaArray.length,
        });
        this.dragCounter = 0;
    };

    exitMediaUpload = (e: { which: number }) => {
        if (e.which === 27) {
            this.setState({
                dragging: false,
            });
        }
    };

    processMedia = (files: FileList) => {
        const {
            chatSelected,
            createChatroom,
        } = this.state;

        const canUpload = chatSelected || createChatroom;

        if (canUpload && files.length > 0) {
            for (let i = 0; i < files.length; i += 1) {
                const { type, } = files[i];

                if (ImageMessageStrings.includes(type) || VideoMessageStrings.includes(type) || ApplicationMessageStrings.includes(type)) {
                    const file = files[i];
                    this.handleFiles(file);
                } else {
                    if (!toast.isActive(fileDropErrorToast)) {
                        toast(
                            <div style={{ ...ApplicationStyles.toastContainer, }}>
                                <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                                <p style={{ ...ApplicationStyles.toastText, }}>
                                    Invalid file type. Accepted files include .png, .jpeg, .jpg, .mp4, .mov, .pdf, and .xml.
                                </p>
                            </div>,
                            { toastId: fileDropErrorToast, }
                        );
                    }
                    this.setState({ dragging: false, });
                }
            }
        } else {
            if (!toast.isActive(fileDropErrorToast)) {
                toast(
                    <div style={{ ...ApplicationStyles.toastContainer, }}>
                        <img src={Images.logo} alt="HTS Logo" style={{ marginRight: 20, }} />
                        <p style={{ ...ApplicationStyles.toastText, }}>Before uploading a file, please select a chatroom.</p>
                    </div>,
                    { toastId: fileDropErrorToast, }
                );
            }
            this.setState({ dragging: false, });
        }
    };

    messageEventListener = (event: any) => {
        const {
            openChatroom,
        } = this.props;

        if (event.data.chatroomId) {
            openChatroom(parseInt(event.data.chatroomId, 10));
            this.setState({
                chatSelected: true,
            });
        }

        if (event.data.sound) {
            const audio = new Audio(sounds[`${event.data.sound}`]);
            audio.play()
                .catch(() => {
                    // Silently catch error
                });
        }
    };

    startRefreshInterval = () => {
        const {
            expirationInterval,
            verifyAccessToken,
        } = this.props;

        if (expirationInterval && expirationInterval > 60) {
            // Last refresh time
            this.lastRefreshTime = Date.now();

            // Do our check ever 1-second to avoid inactive tab issues
            this.interval = setInterval(() => {
                const now = Date.now();
                const elapsedTime = (now - (this.lastRefreshTime || now)) / 1000;

                if (elapsedTime >= (expirationInterval - 60)) {
                    verifyAccessToken('TXP_CHAT_WEB');
                    this.lastRefreshTime = now;
                }
            }, 1000);
        }
    };

    renderChatListPanel() {
        const {
            rooms,
            currentChatId,
            filter,
            deepSearchFilter,
        } = this.props;

        const {
            clearButtonVisible,
            chatSelected,
            deviceType,
        } = this.state;

        return (
            <div>
                <ChatList
                    rooms={rooms}
                    currentChatId={currentChatId}
                    chatSelected={chatSelected}
                    buttonVisible={clearButtonVisible}
                    filter={filter}
                    deepSearchFilter={deepSearchFilter}
                    filterChatrooms={this.onSearchChatrooms}
                    openChatroom={this.onOpenChatroomClick}
                    clearChatroomNotifications={this.clearNotifications}
                    goToMessage={this.onGoToMessage}
                />
                {(deviceType === 'mobile')
                    ? (
                        <div className="menuBarWrapper">
                            <MenuBar
                                footer
                                openMenu={this.onOpenMenu}
                                showProfile={this.onShowProfile}
                                showHelp={this.onShowHelp}
                                showSettings={this.onShowSettings}
                                showUpload={this.onShowUpload}
                            />
                        </div>
                    ) : <div />}
            </div>
        );
    }

    renderCaseListPanel() {
        const {
            deviceType,
        } = this.state;

        return (
            <div className="sidebar caseList">
                <DonorList
                    filterDonors={this.onSearchDonors}
                    onShowDonorPanel={this.onShowDonorPanel}
                />
                {(deviceType === 'mobile')
                    ? (
                        // TODO: Move this outside sidepanel if we can
                        <div className="menuBarWrapper">
                            <MenuBar
                                footer
                                openMenu={this.onOpenMenu}
                                showProfile={this.onShowProfile}
                                showHelp={this.onShowHelp}
                                showSettings={this.onShowSettings}
                                showUpload={this.onShowUpload}
                            />
                        </div>
                    ) : <div />}
            </div>
        );
    }

    renderDonorPanel() {
        const {
            chatroomState,
            peopleFilter,
            memberId,
            isConnected,
            rooms,
            inviteNewUser,
            addPermission,
            addPermissionToAuthorizedEntities,
            getTeams,
            teams,
            teamsLoading,
            profile,
            setSelectedChatroomMember,
        } = this.props;

        const {
            clearButtonVisible,
            showDonorPanel,
        } = this.state;

        if (!showDonorPanel) { return (''); }

        return (
            <DonorPanel
                onCloseDonorPanel={this.onCloseDonorPanel}
                onCreateDonorChatroom={this.onCreateDonorChatroom}
                onSetUnosId={this.onSetUnosId}
                toDonorChatCreate={this.onDonorCreateChatroom}
                openChatroom={this.onOpenChatroomClick}
                memberId={memberId}
                chatroomState={chatroomState}
                peopleFilter={peopleFilter}
                isConnected={isConnected}
                rooms={rooms}
                clearButtonVisible={clearButtonVisible}
                addPermissionToAuthorizedEntities={addPermissionToAuthorizedEntities}
                addPermission={addPermission}
                clearMessage={this.clearMessage}
                filterPeople={this.onSearchPeople}
                goToMessage={this.onGoToMessage}
                inviteNewUser={inviteNewUser}
                getTeams={getTeams}
                teams={teams}
                teamsLoading={teamsLoading}
                clearChatroomNotifications={this.clearNotifications}
                profile={profile}
                setSelectedChatroomMember={setSelectedChatroomMember}
                onOpenCasePreferences={() => this.onShowCasePreferences()}
            />
        );
    }

    renderChatroomDetails() {
        const {
            profile,
            currentChatId,
            currentChat,
            chatroomState,
            editData,
            peopleFilter,
            teams,
            teamsLoading,
        } = this.props;

        const {
            isFileViewVisible,
            isAddPeopleVisible,
            isNotificationSoundsVisible,
            showChatroomDetails,
        } = this.state;

        if (!showChatroomDetails) { return (''); }

        return (
            <ChatroomDetails
                currentChatId={currentChatId}
                user={profile}
                room={currentChat}
                editData={editData}
                isFileViewVisible={isFileViewVisible}
                isAddPeopleVisible={isAddPeopleVisible}
                isNotificationSoundsVisible={isNotificationSoundsVisible}
                chatroomState={chatroomState}
                teams={teams}
                teamsLoading={teamsLoading}
                peopleFilter={peopleFilter}
                filterPeople={this.onSearchPeople}
                onShowFiles={this.onShowFiles}
                onHideFiles={this.onHideFiles}
                onShowAddPeople={this.onShowAddPeople}
                onHideAddPeople={this.onHideAddPeople}
                onShowNotificationSounds={this.onShowNotificationSounds}
                onHideNotificationSounds={this.onHideNotificationSounds}
                onShowDonorDetails={this.onShowDonorDetails}
                addUsersToChatroom={this.addUsersToChatroom}
                removeUserFromChatroom={this.removeUserFromChatroom}
                removeSelfFromFollowerGroup={this.removeUserFromFollowerGroup}
                deleteChatroom={this.deleteChatroom}
                inviteExistingUser={this.inviteExistingUser}
                inviteNewUser={this.inviteNewUser}
                clearMessage={this.clearMessage}
                setDescription={this.onSetDescription}
                setName={this.onSetName}
                setNotificationSound={this.onSetNotificationSound}
                toggleMute={this.onToggleMute}
                onSubmitEdit={this.onEditChatroomMetadata}
                showMemberProfile={this.onShowMemberProfile}
                closeChatroomDetails={this.onCloseChatroomDetails}
            />
        );
    }

    renderGlobalRightPanel() {
        const {
            profile,
            memberId,
        } = this.props;

        const {
            isRightUniversalSidebarOpen,
            showProfile,
            showHelp,
            showSettings,
        } = this.state;

        if (!isRightUniversalSidebarOpen) return ('');

        if (showProfile) {
            return (
                <Profile
                    profile={profile}
                    userId={memberId}
                    closeProfile={this.onProfileCloseClick}
                />
            );
        }
        if (showHelp) {
            return (
                <Help
                    closeHelp={this.onHelpCloseClick}
                />
            );
        }
        if (showSettings) {
            return (
                <Settings
                    closeSettings={this.onSettingsCloseClick}
                    setGlobalDoNotDisturbInterval={this.onSetGlobalDoNotDisturbInterval}
                />
            );
        }

        return null;
    }

    renderMemberProfile(isNested: boolean) {
        return (
            <MemberProfile
                buttonLabel={isNested ? 'Back' : 'Close'}
                closeMemberProfile={this.onMemberProfileCloseClick}
            />
        );
    }

    renderMediaLoadingDialog() {
        const {
            mediaLoading,
        } = this.props;
        return (
            <Dialog
                active={mediaLoading}
                type="small"
            >
                <div style={ChatStyles.center}>
                    <span style={ChatStyles.titleText}>Media upload in progress...</span>
                    <ProgressBar mode="indeterminate" />
                </div>
            </Dialog>
        );
    }

    renderChatTab() {
        const {
            accessToken,
            memberId,
            profile,
            chatMessageText,
            chatMessageMedia,
            selectedResult,
            messages,
            totalMessages,
            currentChat,
            loadMoreMessages,
            isLoadingMessages,
            isConnected,
            readStatus,
            editData,
            chatroomState,
            xmlError,
            mentionedNames,
            filteredNames,
            memberNames,
            peopleFilter,
            deepSearchFilter,
        } = this.props;

        const {
            chatSelected,
            chatListWidth,
            showMemberProfile,
            chatDetailsWidth,
            createChatroomOpen,
            isLeftSidebarOpen,
            showChatroomDetails,
            deviceType,
            showUploadThumbnail,
            dragging,
            showMention,
            createChatroom,
            showCDSUpload,
        } = this.state;

        const rightSidebarOpen = showChatroomDetails || showMemberProfile;
        let chatListWidthFinal = 0;
        let chatDetailsWidthFinal = 0;
        if (isLeftSidebarOpen && deviceType !== 'mobile') {
            chatListWidthFinal = chatListWidth;
        }
        if (rightSidebarOpen && deviceType !== 'mobile') {
            chatDetailsWidthFinal = chatDetailsWidth;
        }
        const maxWidth = Math.floor(0.68 * (window.innerWidth - chatListWidthFinal - chatDetailsWidthFinal));

        let sidebarClassName = 'sidebar';
        if (deviceType === 'tablet') {
            sidebarClassName = 'sidebarTablet';
        }

        // hide the chat input if the device is mobile and either of the sidebars are open
        const hideChatInput = (deviceType === 'mobile') && (isLeftSidebarOpen || rightSidebarOpen);
        const members = currentChat && currentChat.members && currentChat.messages ? currentChat.members : {};
        const memberIds = currentChat && currentChat.memberOrder ? currentChat.memberOrder : [];
        const membersTyping = currentChat && currentChat.membersTyping ? currentChat.membersTyping : [];
        let namesForMention = [];
        if (createChatroom) {
            if (editData.filteredPending.length === 0) {
                namesForMention = this.pendingMemberNames;
            } else {
                namesForMention = editData.filteredPending;
            }
        } else if (filteredNames.length === 0) {
            namesForMention = memberNames;
        } else {
            namesForMention = filteredNames;
        }

        const opacityStyle = (dragging) ? { opacity: 0.3, } : { opacity: 1.0, };
        const fileDropStyle = (dragging) ? {
            ...ChatMessageStyles.fileDropWrapper,
            ...{
                width: window.innerWidth - chatListWidth - 20,
                height: window.innerHeight - headerHeight - 20,
            },
        } : {};

        return (
            <Sidebar
                sidebar={(
                    <div style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderLeft, }}>
                        <Measure
                            bounds
                            onResize={(contentRect) => this.setChatDetailsWidth(contentRect.bounds.width)}
                        >
                            {({ measureRef, }) => (
                                <div ref={measureRef}>
                                    {showMemberProfile
                                        ? (this.renderMemberProfile(showChatroomDetails))
                                        : this.renderChatroomDetails()}
                                </div>
                            )}
                        </Measure>
                    </div>

                )}
                shadow={false}
                open={rightSidebarOpen}
                docked={rightSidebarOpen}
                pullRight
                onSetOpen={this.onShowChatroomDetails}
                sidebarClassName={sidebarClassName}
            >
                <Sidebar
                    sidebar={(
                        <Measure
                            bounds
                            onResize={(contentRect) => this.setChatListWidth(contentRect.bounds.width)}
                        >
                            {({ measureRef, }) => (
                                <div style={opacityStyle} ref={measureRef}>
                                    {this.renderChatListPanel()}
                                </div>
                            )}
                        </Measure>
                    )}
                    shadow={false}
                    open={isLeftSidebarOpen}
                    docked={isLeftSidebarOpen}
                    onSetOpen={this.onSetLeftSidebarOpen}
                    sidebarClassName={sidebarClassName}
                >
                    <div ref={this.dropRef}>
                        <div style={fileDropStyle}>
                            {dragging
                                ? (
                                    <form style={ChatMessageStyles.fileDropForm}>
                                        <img
                                            style={ChatMessageStyles.uploadIcon}
                                            src={Images.fileUpload}
                                            alt="File Upload"
                                        />
                                        <label
                                            htmlFor="file"
                                            style={ChatMessageStyles.fileDropFormButton}
                                        >
                                            Choose file from computer
                                            <input
                                                type="file"
                                                name="message"
                                                id="file"
                                                accept=".png, .jpg, .jpeg, .mp4, .mov, .pdf, .xml"
                                                multiple
                                                ref={this.fileUploadRef}
                                                onChange={this.handleSubmit}
                                                style={{ display: 'none', }}
                                            />
                                        </label>
                                        or drag and drop to upload.
                                        <span style={ChatMessageStyles.fileEscapeMessage}>
                                            Press ESC to exit.
                                        </span>
                                    </form>
                                ) : null}
                        </div>
                        {this.renderMediaLoadingDialog()}
                        {xmlError ? this.uploadXMLFailure() : <div />}
                        <span style={opacityStyle}>
                            <EmailNotVerified />
                            <SagaMessage toastId={genericSagaToast} />
                            <ChatHistory
                                maxWidth={maxWidth}
                                accessToken={accessToken}
                                memberId={memberId}
                                selectedResult={selectedResult}
                                messages={messages}
                                isLoadingMessages={isLoadingMessages}
                                totalMessages={totalMessages}
                                members={members}
                                memberIds={memberIds}
                                selected={chatSelected}
                                loadMoreMessages={loadMoreMessages}
                                readStatus={readStatus}
                                user={profile}
                                createChatroomOpen={createChatroomOpen}
                                showCDSUpload={showCDSUpload}
                                editData={editData}
                                chatroomState={chatroomState}
                                deepSearchFilter={deepSearchFilter}
                                peopleFilter={peopleFilter}
                                isLeftSidebarOpen={isLeftSidebarOpen}
                                onTriggerAskAlan={this.onTriggerAskAlan}
                                paginate={this.onLoadMoreMessages}
                                onScroll={this.onScroll}
                                openMessageDetails={this.onOpenMessageDetails}
                                closeMessageDetails={this.onCloseMessageDetails}
                                sendAck={this.sendAck}
                                filterPeople={this.onSearchPeople}
                                onCreateChatroom={this.onCreateChatroom}
                                setName={this.onSetName}
                                setDescription={this.onSetDescription}
                                addUsersToChatroom={this.addUsersToChatroom}
                                onCancelButtonClick={this.onCreateChatroomCloseClick}
                                onCloseDonorClick={this.onCreateDonorCloseClick}
                                selectV2Option={this.onSelectV2Option}
                                showMemberProfile={this.onShowMemberProfile}
                                closeCDSUpload={this.onCDSUploadClose}
                                onShowDonor={this.onShowDonorDetails}
                                room={currentChat}
                            />
                            {!hideChatInput
                                ? (
                                    <ChatInput
                                        showMention={showMention}
                                        members={createChatroom ? this.pendingMembers : members}
                                        memberIds={createChatroom ? this.pendingMembersIds : memberIds}
                                        membersTyping={membersTyping}
                                        filteredNames={namesForMention}
                                        message={(createChatroom ? editData.message : chatMessageText) || ''}
                                        media={(createChatroom ? editData.attachments : chatMessageMedia) || []}
                                        mentionedNames={
                                            (createChatroom ? editData.mentionedPendingNames : mentionedNames) || []
                                        }
                                        showMedia={showUploadThumbnail}
                                        chatroomName={editData.name || ''}
                                        createChatroomOpen={createChatroomOpen}
                                        createChatroom={createChatroom}
                                        selected={chatSelected}
                                        deviceType={deviceType}
                                        isConnected={isConnected}
                                        isLeftSidebarOpen={isLeftSidebarOpen}
                                        isRightSidebarOpen={showChatroomDetails}
                                        setMessage={this.onSetMessage}
                                        onSend={this.onSend}
                                        onTriggerAskAlan={this.onTriggerAskAlan}
                                        setMessageMedia={this.onSetMediaMessage}
                                        onUploadMedia={this.onOpenUploadMedia}
                                        onPasteMedia={this.processMedia}
                                        closeMention={this.closeMention}
                                        addMentionedUser={this.addMentionedUser}
                                    />
                                ) : <div />}
                        </span>
                    </div>
                </Sidebar>
            </Sidebar>
        );
    }

    renderCaseTab() {
        const {
            memberId,
            editData,
            chatroomState,
        } = this.props;

        const {
            createDonorOpen,
            createDonorChatroomOpen,
            showDonorPanel,
            deviceType,
            showCasePreferences,
        } = this.state;

        let sidebarClassName = 'sidebar';
        if (deviceType === 'tablet') {
            sidebarClassName = 'sidebarTablet';
        }

        return (
            <Sidebar
                sidebar={(
                    <div style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderLeft, }}>
                        <div>
                            {this.renderDonorPanel()}
                        </div>
                    </div>

                )}
                shadow={false}
                open={showDonorPanel}
                docked={showDonorPanel}
                pullRight
                onSetOpen={this.onShowDonorPanel}
                sidebarClassName={sidebarClassName}
            >
                <SagaMessage toastId={genericSagaToast} />
                {this.renderMediaLoadingDialog()}
                {
                    createDonorOpen
                        ? (
                            <CreateDonor
                                memberId={memberId}
                                editData={editData}
                                chatroomState={chatroomState}
                                onClose={this.onCreateDonorCloseClick}
                            />
                        ) : createDonorChatroomOpen
                            ? this.renderCreateDonorChatroom()
                            : showCasePreferences
                                ? this.renderCasePreferences() : this.renderCaseListPanel()
                }
            </Sidebar>
        );
    }

    renderTeamsTab() {
        const {
            deviceType,
            isLeftSidebarOpen,
        } = this.state;

        return (
            <TeamsTab
                isTeamsListOpen={isLeftSidebarOpen}
                deviceType={deviceType}
                // MenuBar specific props
                openMenu={this.onOpenMenu}
                showProfile={this.onShowProfile}
                showHelp={this.onShowHelp}
                showSettings={this.onShowSettings}
                showUpload={this.onShowUpload}
                footer
            />
        );
    }

    renderCreateDonorChatroom() {
        const {
            avatars,
            memberId,
            currentChat,
            chatMessageText,
            chatMessageMedia,
            isConnected,
            mentionedNames,
            filteredNames,
            memberNames,
            editData,
            chatroomState,
            peopleFilter,
        } = this.props;

        const {
            chatSelected,
            createDonorChatroomOpen,
            createChatroom,
            showDonorPanel,
            deviceType,
            showMention,
            showUploadThumbnail,
        } = this.state;

        const members = currentChat && currentChat.members && currentChat.messages ? currentChat.members : {};
        const memberIds = currentChat && currentChat.memberOrder ? currentChat.memberOrder : [];
        const membersTyping = currentChat && currentChat.membersTyping ? currentChat.membersTyping : [];
        let namesForMention = [];
        if (createChatroom) {
            if (editData.filteredPending.length === 0) {
                namesForMention = this.pendingMemberNames;
            } else {
                namesForMention = editData.filteredPending;
            }
        } else if (filteredNames.length === 0) {
            namesForMention = memberNames;
        } else {
            namesForMention = filteredNames;
        }

        return (
            <>
                <CreateChatroom
                    chatInputHeight={0}
                    memberId={memberId}
                    editData={editData}
                    avatars={avatars}
                    chatroomState={chatroomState}
                    peopleFilter={peopleFilter}
                    filterPeople={this.onSearchPeople}
                    onCreateChatroom={this.onCreateChatroom}
                    setName={this.onSetName}
                    setDescription={this.onSetDescription}
                    addUsersToChatroom={this.addUsersToChatroom}
                    onCancelButtonClick={this.onCreateChatroomCloseClick}
                    onTriggerAskAlan={this.onTriggerAskAlan}
                    selectV2Option={this.onSelectV2Option}
                />
                <ChatInput
                    showMention={showMention}
                    members={createChatroom ? this.pendingMembers : members}
                    memberIds={createChatroom ? this.pendingMembersIds : memberIds}
                    membersTyping={membersTyping}
                    filteredNames={namesForMention}
                    message={(createChatroom ? editData.message : chatMessageText) || ''}
                    media={(createChatroom ? editData.attachments : chatMessageMedia) || []}
                    mentionedNames={
                        (createChatroom ? editData.mentionedPendingNames : mentionedNames) || []
                    }
                    showMedia={showUploadThumbnail}
                    chatroomName={editData.name || ''}
                    createChatroomOpen={createDonorChatroomOpen}
                    createChatroom={createChatroom}
                    selected={chatSelected}
                    deviceType={deviceType}
                    isConnected={isConnected}
                    isLeftSidebarOpen={false}
                    isRightSidebarOpen={showDonorPanel}
                    setMessage={this.onSetMessage}
                    onSend={this.onSend}
                    onTriggerAskAlan={this.onTriggerAskAlan}
                    setMessageMedia={this.onSetMediaMessage}
                    onUploadMedia={this.onOpenUploadMedia}
                    onPasteMedia={this.processMedia}
                    closeMention={this.closeMention}
                    addMentionedUser={this.addMentionedUser}
                />
            </>
        );
    }

    renderCasePreferences() {
        return (
            <CasePreferences
                onClose={() => this.onCloseCasePreferences()}
            />
        );
    }

    renderSelectedTab() {
        const {
            selectedTab,
        } = this.props;

        const {
            permissionsLoaded,
        } = this.state;

        if (!permissionsLoaded) {
            return this.renderMediaLoadingDialog();
        }

        switch (selectedTab) {
            case CHAT_TAB:
                return this.renderChatTab();
            case CASE_TAB:
                return this.renderCaseTab();
            case TEAMS_TAB:
                return this.renderTeamsTab();
            default:
                return this.renderChatTab();
        }
    }

    render() {
        const {
            selectedTab,
            currentChatId,
            profile,
            isAuthenticated,
            currentChat,
            isConnected,
            permissions,
        } = this.props;

        const {
            chatSelected,
            isLeftSidebarOpen,
            isRightUniversalSidebarOpen,
            deviceType,
            dragging,
            createInProgress,
            showChatroomDetails,
        } = this.state;

        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }

        let sidebarClassName = 'sidebar';
        if (deviceType === 'tablet') {
            sidebarClassName = 'sidebarTablet';
        }

        if (isAuthenticated) {
            const title = (selectedTab === CHAT_TAB && currentChat && chatSelected) ? currentChat.name : 'OmniLife Web';
            const opacityStyle = (dragging) ? { opacity: 0.3, } : { opacity: 1.0, };

            return (
                <div role="button" tabIndex={0} onKeyDown={this.exitMediaUpload} ref={this.noDropRef}>
                    {process.env.NODE_ENV === 'development' ? <RTStateChecker /> : null}
                    <div style={opacityStyle}>
                        <Header
                            deviceType={deviceType}
                            title={title}
                            currentChatId={currentChatId}
                            isChatSelected={(currentChat && chatSelected)}
                            isConnected={isConnected}
                            isLeftSidebarOpen={isLeftSidebarOpen}
                            isRightUniversalSidebarOpen={isRightUniversalSidebarOpen}
                            showChatDetails={showChatroomDetails}
                            createInProgress={createInProgress}
                            globalDoNotDisturbOn={profile.globalDoNotDisturbEnd !== '' && isFutureDatetime(profile.globalDoNotDisturbEnd)}
                            notificationChange={this.onNotificationClick}
                            createChatroomClick={this.onCreateChatroomClick}
                            createDonorClick={this.onCreateDonorClick}
                            createTeamClick={this.onCreateTeamClick}
                            onReconnect={this.onTriggerChatSocket}
                            onLeftSidebarClose={this.onSetLeftSidebarClose}
                            onLeftSidebarOpen={this.onSetLeftSidebarOpen}
                            onRightSidebarClose={this.onSetRightUniversalSidebarClose}
                            openMenu={this.onOpenMenu}
                            showProfile={this.onShowProfile}
                            showHelp={this.onShowHelp}
                            showSettings={this.onShowSettings}
                            showUpload={this.onShowUpload}
                            showChatroomDetails={this.onShowChatroomDetails}
                            closeChatroomDetails={this.onCloseChatroomDetails}
                            permissions={permissions}
                        />
                    </div>
                    {/* Since header is positioned absolutely the rest of the body needs to account for its style. chatContainerStyle
                         applies the height of the header as top padding. */}
                    <div style={ChatStyles.chatContainerStyle}>
                        <Sidebar
                            sidebar={(
                                <div style={{ ...ChatListStyles.wrapper, ...ChatListStyles.borderLeft, }}>
                                    <Measure
                                        bounds
                                        onResize={(contentRect) => this.setChatDetailsWidth(contentRect.bounds.width)}
                                    >
                                        {({ measureRef, }) => (
                                            <div ref={measureRef}>
                                                {this.renderGlobalRightPanel()}
                                            </div>
                                        )}
                                    </Measure>
                                </div>

                            )}
                            shadow={false}
                            open={isRightUniversalSidebarOpen}
                            docked={isRightUniversalSidebarOpen}
                            pullRight
                            onSetOpen={this.onSetRightUniversalSidebarOpen}
                            sidebarClassName={sidebarClassName}
                        >
                            {this.renderSelectedTab()}
                        </Sidebar>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={4000}
                        newestOnTop={false}
                        hideProgressBar
                        transition={Slide}
                    />
                </div>
            );
        }
        return <Redirect to="/login" />;
    }
}

const mapStateToProps = (state) => {
    const currentChatId = state.chatList.activeChatId;
    const currentChat = (currentChatId ? state.chatList.chats[currentChatId] : null) || null;
    const msgOrder = (currentChat && currentChat.messageOrder ? currentChat.messageOrder : []);

    const totalMessages = (currentChat ? currentChat.totalMessages : msgOrder.length) || 0;

    const loadMoreMessages = totalMessages > msgOrder.length;

    const curStart = 0;
    const curEnd = msgOrder.length;

    const messageOrder = msgOrder.slice(curStart, curEnd);
    const messages = messageOrder.map((msgKey) => currentChat && currentChat.messages[`${msgKey}`]);
    const isConnected = state.chatList.socketStatus === 'connected';
    const profile = state.auth && state.auth.profile ? state.auth.profile : {};

    return {
        license: state.auth.license,
        accessToken: state.auth.accessToken,
        memberId: (profile && profile.userId) ? profile.userId : -1,
        mediaLoading: state.loading.media,
        selectedTab: state.application.selectedTab,
        currentChatId,
        currentChat,
        currentDonorId: state.donor.openDonorId,
        loadMoreMessages,
        isLoadingMessages: state.loading.chatMessages[currentChatId] ? state.loading.chatMessages[currentChatId] : false,
        totalMessages,
        selectedResult: state.chatList.selectedResult || {},
        deepSearchFilter: state.chatList.deepSearchFilter || '',
        messageOrder,
        messages,
        isTyping: state.chatList.isTyping,
        isLoading: state.loading.permissions,
        isAuthenticated: state.auth.authenticated,
        isConnected,
        expirationInterval: state.auth.expiresIn,
        rooms: state.chatList.order.sort((a, b) => sortChatrooms(state.chatList.chats, a, b, profile.chatroomSort)).map(
            (chatId) => state.chatList.chats[chatId]
        ),
        teams: state.team.teams,
        teamsLoading: state.loading.teams,
        filter: state.chatList.filter,
        peopleFilter: state.chatList.peopleFilter,
        chatMessageText: state.chatMessage[currentChatId] ? state.chatMessage[currentChatId].text : '',
        chatMessageMedia: (state.chatMessage[currentChatId] ? state.chatMessage[currentChatId].attachments : []) || [],
        mentionedUsers: (state.chatMessage[currentChatId] ? state.chatMessage[currentChatId].mentionedUsers : []) || [],
        mentionedNames: (state.chatMessage[currentChatId] ? state.chatMessage[currentChatId].mentionedNames : []) || [],
        filteredNames: (state.chatMessage[currentChatId] ? state.chatMessage[currentChatId].filteredNames : []) || [],
        readStatus: currentChat ? currentChat.readStatus : {},
        memberNames: currentChat && currentChat.memberNames ? currentChat.memberNames : [],
        editData: state.chatEdit,
        profile,
        chatroomState: state.chatroom,
        donorId: state.chatList.chats[currentChatId] ? state.chatList.chats[currentChatId].donorId : null,
        organId: state.chatList.chats[currentChatId] ? state.chatList.chats[currentChatId].organId : null,
        targetOrgId: state.chatList.chats[currentChatId] ? state.chatList.chats[currentChatId].targetOrgId : null,
        xmlError: state.chatMessage[currentChatId] ? state.chatMessage[currentChatId].error : null,
        donorCreatingChat: state.chatEdit.donorCreatingChat,
        createDonorSuccess: state.donor.createDonorSuccess,
        permissions: state.permission.permissions || [],
        avatars: state.chatList.avatars || {},
        casePreferences: state.donor.casePreferences,
    };
};

export default connect(mapStateToProps, {
    resetCreateDonorSuccess: _resetCreateDonorSuccess,
    verifyAccessToken: _verifyAccessToken,
    storeDeviceToken: _storeDeviceToken,
    selectTab: _selectTab,
    openChatroom: _openChatroom,
    openTeamCreate: _openTeamCreate,
    setSelectedResult: _setSelectedResult,
    getContacts: _getContacts,
    getTeams: _getTeams,
    resetContactsStatuses: _resetContactsStatuses,
    addUsersToChatroom: _addUsersToChatroom,
    addSingleUserToChatroom: _addSingleUserToChatroom,
    setSelectedChatroomMember: _setSelectedChatroomMember,
    removeUserFromChatroom: _removeUserFromChatroom,
    removeUserFromFollowerGroup: _removeUserFromFollowerGroup,
    deleteChatroom: _deleteChatroom,
    inviteNewUser: _inviteNewUser,
    addPermission: _addPermission,
    addPermissionToAuthorizedEntities: _addPermissionToAuthorizedEntities,
    loadChatFiles: _loadChatFiles,
    resetChatroomDetails: _resetChatroomDetails,
    resetProfileError: _resetProfileError,
    closeChatroom: _closeChatroom,
    closeDonor: _closeDonor,
    setMessageText: _setMessageText,
    toggleMute: _toggleMute,
    setNotificationSound: _setNotificationSound,
    setMessageMedia: _setMessageMedia,
    uploadXML: _uploadXML,
    resetXMLError: _resetXMLError,
    setMentionedUsers: _setMentionedUsers,
    setMentionedNames: _setMentionedNames,
    setFilteredNames: _setFilteredNames,
    submitMessage: _submitMessage,
    triggerAskAlan: _triggerAskAlan,
    saveChat: _saveChat,
    setName: _setName,
    setDescription: _setDescription,
    setInitialMessage: _setInitialMessage,
    setUnosId: _setUnosId,
    setMentionedPendingUsers: _setMentionedPendingUsers,
    setMentionedPendingNames: _setMentionedPendingNames,
    setFilteredPending: _setFilteredPending,
    createOffer: _createOffer,
    resetChatEditState: _resetChatEditState,
    markMessagesRead: _markMessagesRead,
    acknowledgeMessage: _acknowledgeMessage,
    startTyping: _startTyping,
    stopTyping: _stopTyping,
    filterChatrooms: _filterChatrooms,
    filterPeople: _filterPeople,
    filterDonors: _filterDonors,
    resetFilters: _resetFilters,
    clearChatroomNotifications: _clearChatroomNotifications,
    setOffset: _setOffset,
    triggerChatSocket: _triggerChatSocket,
    updateProfileData: _updateProfileData,
    setDonorCreateChat: _setDonorCreateChat,
    setOPODonorId: _setOPODonorId,
    setChatroomType: _setChatroomType,
    setOrganType: _setOrganType,
    setChatEditUnosId: _setChatEditUnosId,
    setDonorId: _setDonorId,
    setOrganId: _setOrganId,
    setSagaMessage: _setSagaMessage,
    setAddPeopleError: _setAddPeopleError,
})(ChatPage);
