// @flow
/* eslint-disable max-len */
import { ICONS } from './tagIcons';

const TAG_ICONS: { [key: string]: { icon: React$Node, keepStroke?: boolean } } = {
    Adults: { icon: ICONS.iconAdult, },
    Pediatrics: { icon: ICONS.iconChild, },
    'Special Circumstance': { icon: ICONS.iconSpecialCircumstance, },
    DCD: { icon: ICONS.organHeart, },
    'Brain Death': { icon: ICONS.brainOrgan, },
    'HIV+': { icon: ICONS.bacterium, },
    'Hep C+': { icon: ICONS.bacterium, },
    'Hep B+': { icon: ICONS.bacterium, },
    'New Offer': { icon: ICONS.newOffer, },
    'PY Offer': { icon: ICONS.pyOffer, },
    Accepted: { icon: ICONS.accepted, },
    Review: { icon: ICONS.review, },
    TransMedics: { icon: ICONS.transmedics, },
    NRP: { icon: ICONS.nrp, },
    XVIVO: { icon: ICONS.xvivo, },
    OrganOx: { icon: ICONS.organox, keepStroke: true, },
    Completed: { icon: ICONS.completed, },
    Procured: { icon: ICONS.procured, keepStroke: true, },
    '34 Lives': { icon: ICONS.lives34, keepStroke: true, },
    'Open Offer': { icon: ICONS.openOffer, keepStroke: true, },
    Transplanted: { icon: ICONS.transplanted, keepStroke: true, },
    'Hope Act': { icon: ICONS.hopeAct, keepStroke: true, },
    Paragonix: { icon: ICONS.paragonix, keepStroke: true, },
    'Transmedics Full Service': { icon: ICONS.transmedics, keepStroke: true, },
    'Transmedics Specialist/Device': { icon: ICONS.transmedics, keepStroke: true, },
};
const ORGAN_ICONS: { [key: string]: { icon: React$Node, keepStroke?: boolean } } = {
    'En bloc Kidneys': { icon: ICONS.organKidney, },
    'Left Kidney': { icon: ICONS.organKidney, },
    'Right Kidney': { icon: ICONS.organKidney, },
    Pancreas: { icon: ICONS.organPancreas, },
    Liver: { icon: ICONS.organLiver, },
    Intestine: { icon: ICONS.organIntestine, },
    Heart: { icon: ICONS.organHeart, },
    'Left Lung': { icon: ICONS.organLungs, },
    'Right Lung': { icon: ICONS.organLungs, },
    'En bloc Lungs': { icon: ICONS.organLungs, },
    'Living Donor Organ': { icon: ICONS.organLivingDonor, },
    'Kidney: Laterality Pending': { icon: ICONS.organKidneyLateralityPending, },
    'Multi-Organ': { icon: ICONS.organMulti, },
    'Expedited Liver': { icon: ICONS.expeditedLiver, keepStroke: true, },
};

export const getTagIcon = (tagLabel: string) => TAG_ICONS[tagLabel] || { icon: ICONS.organLivingDonor, keepStroke: false, };

export const getOrganIcon = (organType: string) => ORGAN_ICONS[organType] || { icon: ICONS.organLivingDonor, keepStroke: false, };
